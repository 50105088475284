import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@b2c-frontend/http';
import { BaseHomeModule } from './lib/home/home.module';
import { BaseAccountModule } from './lib/account/account.module';
import { BaseSharedModule } from './lib/shared/shared.module';
import { BasePagesModule } from './lib/pages/pages.module';
import { BaseProductsModule } from './lib/products/products.module';
import { BaseProductModule } from './lib/product/product.module';
import { BaseBasketModule } from './lib/basket/basket.module';
import { BaseCheckoutModule } from './lib/checkout/checkout.module';
import { BaseCoreModule } from './lib/core/core.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpModule,
    BaseAccountModule,
    BaseBasketModule,
    BaseCheckoutModule,
    BaseHomeModule,
    BaseCoreModule,
    BaseSharedModule,
    BasePagesModule,
    BaseProductsModule,
    BaseProductModule
  ],
  exports: [
    BaseAccountModule,
    BaseBasketModule,
    BaseCheckoutModule,
    BaseHomeModule,
    BasePagesModule,
    BaseProductsModule,
    BaseProductModule,
    BaseCoreModule
  ],
})
export class UiTemplateModule {}
