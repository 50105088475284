import { NgModule } from '@angular/core';
import { BaseBasketComponent } from './basket.component';
import { BasketRoutingModule } from './basket-routing.module';
import { BaseBasketProductItemComponent } from './basket-product-item/basket-product-item.component';
import { BaseBasketSideViewComponent } from './basket-side-view/basket-side-view.component';
import { BaseBasketSummaryComponent } from './basket-summary/basket-summary.component';
import { BaseBasketTableComponent } from './basket-table/basket-table.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BaseBasketComponent,
    BaseBasketProductItemComponent,
    BaseBasketSideViewComponent,
    BaseBasketSummaryComponent,
    BaseBasketTableComponent
  ],
  imports: [
    CommonModule,
    BasketRoutingModule
  ],
  exports: [
    BaseBasketComponent,
    BaseBasketProductItemComponent,
    BaseBasketSideViewComponent,
    BaseBasketSummaryComponent,
    BaseBasketTableComponent
  ]
})
export class BaseBasketModule { }
