<lib-ui-template-nav-bar></lib-ui-template-nav-bar>
<lib-ui-template-section-header></lib-ui-template-section-header>
<div>
  <router-outlet></router-outlet>
  <div class="top-up-button-wrapper" *ngIf="showScrollToTopButton">
    <div class="top-up-button" (click)="onScrollToTopClick()">
      <img src="../assets/images/arrow-up.svg" alt="arrow-up">
    </div>
  </div>
</div>
<lib-ui-template-footer></lib-ui-template-footer>