import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-ui-template-html-sanitizer',
  templateUrl: './html-sanitizer.component.html',
  styleUrls: ['./html-sanitizer.component.scss']
})
export class BaseHtmlSanitizerComponent implements OnInit {
  @Input() htmlText?: string;
  sanitizedHtmlTaxt: any;
  showAllContent!: boolean;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.sanitizedHtmlTaxt = this.sanitizer.bypassSecurityTrustHtml(this.htmlText ?? "");
  }

  showMore() {
    this.showAllContent = !this.showAllContent;
  }
}