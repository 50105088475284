import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  BasketViewService,
  CompanyViewService,
  ProductsService,
  WishesViewService,
} from '@b2c-frontend/http';
import {
  ImageUrlViewModel,
  ProductDetailsViewModel,
  ProductListViewModel
} from '@b2c-frontend/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WindowService } from '@b2c-frontend/common';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MetaService, MetaTagComponent } from '@b2c-frontend/seo';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-ui-template-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class BaseProductDetailsComponent extends MetaTagComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private linkedProductId?: string;
  private product!: ProductDetailsViewModel;
  
  product$!: Observable<ProductDetailsViewModel | undefined>;
  similarProducts$!: Observable<ProductListViewModel[]>;
  identicalProducts$!: Observable<ProductListViewModel[]>;
  images?: ImageUrlViewModel[] = [];
  quantity = 1;
  openSpecifications = true;
  openDeclarations = true;
  openUrl = true;
  safeUrl: any;
  showLoader = true;
  isiPhoneDevice = false;

  limitedDescription = '';
  showReadMore = false;
  isDescriptionExpanded = false;
  breadcrumbName = '';

  sliderOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    margin: 1,
    startPosition: 0,
    nav: true,
    navText: [
      '<img src="../../assets/images/landing/left-arrow.svg"></img>',
      '<img src="../../assets/images/landing/right-arrow.svg"></img>',
    ],
    responsive: {
      0: { items: 2, margin: 5 },
      600: { items: 3 },
      800: { items: 4 },
      1000: { items: 5 },
      1200: { items: 5 },
    },
  };

  constructor(
    private router: Router,
    private productsService: ProductsService,
    private activedRoute: ActivatedRoute,
    private basketViewService: BasketViewService,
    private wishesViewService: WishesViewService,
    private breadcrumbService: BreadcrumbService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public companyViewService: CompanyViewService,
    metaService: MetaService,
    windowService: WindowService
  ) {
    super(metaService, windowService);
  }

  ngOnInit(): void {
    this.activedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.linkedProductId = params['linkedProductId'] ? params['linkedProductId']: '';
      },
    });
    this.isiPhoneDevice = /iphone/i.test(this.userAgent);

    this.product$ = this.activedRoute.params.pipe(
      switchMap(params => this.productsService.getProduct(params['id'])),
      map(product => {
        this.showLoader = false;
        this.product = product;

        if (!product) {
          return undefined;
        }

        const unknown = <any>product;
        if (unknown.Code && unknown.Message) {
          return undefined;
        }

        this.setMetaData(this.product.name, 'Product', this.product.mainPictureUrl,
          (this.product.description = 
          this.product.description?.replace(/<([a-z][a-z0-9]*)\b[^>]*>/gi, '<$1 style="font-size: 14px; font-family: Arial, sans-serif;">')) ?? 
          this.product.specificationsAsString?.replace(/<([a-z][a-z0-9]*)\b[^>]*>/gi, '<$1 style="font-size: 14px; font-family: Arial, sans-serif;">') ?? '');
        this.images = this.product.imageUrls?.filter(url => !!url);
        this.breadcrumbService.set('@productName', this.product.name);

        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(product.url ?? '');

        // Generate breadcrumb name based on categories
        this.setBreadcrumbName(this.product);

        return this.product;
      }),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe)
    );

    this.product$.subscribe({
      error: result => {
        console.log(result);
        this.showLoader = false;
      }
    })

    this.similarProducts$ = this.product$.pipe(
      switchMap(product => {
        if (!product || !product.categories || !product.categories.length) {
          return [];
        }

        const firstCategory = product.categories[0];
        return this.productsService.getProducts(
          1,
          10,
          '',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          [firstCategory.categoryId],
          [],
          []
        );
      }),
      shareReplay(1),
      map(result => {
        const items = result?.items?.filter(item => item.id !== this.product?.id) ?? [];
        return items;
      })
    );

    this.identicalProducts$ = this.product$.pipe(
      switchMap(product => this.productsService.getIdenticalProducts(product?.id ?? '')),
      shareReplay(1),
      map(products => products)
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Breadcrumb generation logic
  private setBreadcrumbName(product: ProductDetailsViewModel): void {
    if (!product.categories || !product.categories.length) return;

    const categories = product.categories;
    for (let i = categories.length - 1; i >= 0; i--) {
      const category = categories[i];
      this.breadcrumbName = `<a rel="noopener noreferrer"
                                style="color: inherit; text-decoration: none; transition: text-decoration .3s;"
                                onMouseOver="this.style.color='#0d6efd';this.style.textDecoration='underline'"
                                onMouseOut="this.style.color='inherit';this.style.textDecoration='none'"
                                href="/ps/c/${category.slug}">${category.name}</a>
                             <span style="-webkit-user-select: none;user-select: none;margin-left: 8px;margin-right: 8px;">/</span>`;
    }
    this.breadcrumbService.set('@productName', `${this.breadcrumbName}${product.name}`);
  }

  addItemToBasket(): void {
    if (!this.product) return;
    this.basketViewService.addItem({
      productId: this.product.id,
      productName: this.product.name,
      productUniqueId: this.product.productId,
      price: this.product.price,
      isPromoPriceActive: this.product.isPromoPriceActive,
      promoPrice: this.product.promoPrice,
      productPictureUrl: this.product.mainPictureUrl,
      availability: this.product.availability,
      quantity: this.quantity,
      linkedProductId: this.linkedProductId,
    });
  }

  finishOrder(): void {
    if (!this.product) return;
    this.addItemToBasket();
    this.router.navigate(['checkout/first']);
  }

  incrementQuantity(): void {
    this.quantity++;
  }

  decrementQuantity(): void {
    if (this.quantity > 1) this.quantity--;
  }

  addToWish(): void {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
      return;
    }
    if (!this.product) return;
    this.wishesViewService.addItem({
      productId: this.product.id,
      productName: this.product.name,
      price: this.product.price,
      isPromoPriceActive: this.product.isPromoPriceActive,
      promoPrice: this.product.promoPrice,
      productPictureUrl: this.product.mainPictureUrl,
    });
  }

  onOpenSpecifications(): void {
    this.openSpecifications = !this.openSpecifications;
  }

  onOpenDeclarations(): void {
    this.openDeclarations = !this.openDeclarations;
  }

  onOpenUrl(): void {
    this.openUrl = !this.openUrl;
  }
}