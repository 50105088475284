import { PaymentMethod, FrameType, OrderStatus, PersonType } from "./enums";
import { EmailService } from "./services";

export class BasketResponse {
  basketId!: string;
  total!: number;
  itemsCount!: number;
  transportCost!: number;
  totalSaving?: number;
  items!: BasketItemResponse[];
}

export class BasketItemResponse {
  productId!: string;
  productName!: string;
  productUniqueId!: string;
  price!: number;
  isPromoPriceActive!: boolean;
  promoPrice?: number;
  quantity!: number;
  sum!: number;
  linkedProductId?: string;
  productPictureUrl?: string;
}

export class CategoryListResponse {
  id!: string;
  name!: string;
  slug!: string;
  parentId?: string;
  parentName?: string;
  parentSlug?: string;
  imageUrl?: string;
  shortDescription?: string;
  urlQueryParameters?: string;
  priority!: number;
  hasProducts!: boolean;
  hasCategories!: boolean;
  children?: CategoryListResponse[];
}

export class CategoryGroupSelectResponse {
  id!: string;
  name!: string;
  priceMargin?: number;
}

export class CompanyResponse {
  name!: string;
  defaultCurrency!: string;
  taxNumber!: string;
  identificationNumber!: string;
  transportCostLimit!: number;
  fullAddress?: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  logo?: string;
  webSiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  currentBankAccounts?: string;
  onlinePaymentUrl?: string;
  onlinePaymentShopId?: string;
  payPalClientId?: string;
  payPalCurrency?: string;
  enableOnlinePaymentApi!: boolean;
  enablePayPalApi!: boolean;
}

export class FrameResponse {
  id!: string;
  title!: string;
  slug!: string;
  imageUrl?: string;
  frameType!: FrameType;
  shortDescription!: string;
  code?: string;
  description?: string;
}

export class OrderListResponse {
  id!: string;
  number!: string;
  fullName!: string;
  companyName?: string;
  status!: OrderStatus;
  paymentMethod!: PaymentMethod;
  totalPrice!: number;
}

export class ProductListResponse {
  id!: string;
  uniqueId!: string;
  name!: string;
  price!: number;
  slug!: string;
  transportCost!: number;
  isNew!: boolean;
  isPromoPriceActive!: boolean;
  isInWishList!: boolean;
  promoPrice?: number;
  recommendedPrice?: number;
  pictureUrls?: string[];
  mainPictureUrl?: string;
  discountInPercentage?: number;
  availability?: string;
  stickerFrames?: FrameResponse[];
  categories?: CategoryProductResponse[];
  insignificantSpecification?: InsignificantSpecificationResponse;
}

export class InsignificantSpecificationResponse {
  name?: string;
  value?: string;
}

export class PaginationResponse<TItem> {
  totalPages!: number;
  pageSize!: number;
  totalCount!: number;
  items!: TItem[];
}

export class ProductListPaginationResponse extends PaginationResponse<ProductListResponse> {
  productIds?: string[];
}

export class ProductDetailsResponse {
  id!: string;
  productId!: string;
  name!: string;
  code!: string;
  barCode!: string;
  slug!: string;
  supplierName!: string;
  price!: number;
  installmentsPrice!: number;
  transportCost!: number;
  isNew!: boolean;
  isPromoPriceActive!: boolean;
  promoPrice?: number;
  saving?: number;
  url?: string;
  availability?: string;
  currency?: string;
  mainPictureUrl?: string;
  description?: string;
  specificationsAsString?: string;
  promoPriceStartDate?: Date;
  promoPriceEndDate?: Date;
  discountInPercentage?: number;
  categories?: CategoryProductResponse[];
  specifications?: SpecificationProductResponse[];
  groupedSpecifications?: SpecificationGroupResponse[];
  stickerFrames?: FrameResponse[];
  imageUrls?: ImageUrlResponse[];
}

export class ImageUrlResponse {
  url!: string;
  isSelected!: boolean;
  is3D!: boolean;
  isVideo!: boolean;
}

export class SpecificationGroupResponse {
  name?: string;
  specifications?: SpecificationProductResponse[];
}

export class CategoryProductResponse {
  id!: string;
  parentId?: string;
  name!: string;
  slug!: string;
  imageUrl?: string;
  title?: string;
  shortDescription?: string;
  description?: string;
  hasChildren!: boolean;
  priority!: number;
  createdAt!: Date;
  updatedAt?: Date;
}

export class SpecificationProductResponse {
  name?: string;
  value?: string;
  group?: string;
}

export class WishResponse {
  wishId!: string;
  userId!: string;
  items!: WishItemResponse[];
}

export class WishItemResponse {
  productId!: string;
  productName!: string;
  price!: number;
  promoPrice?: number;
  productPictureUrl?: string;
}

export class CreateOrderRequest {

  constructor(basketId: string, totalAmount: number) {
    this.basketId = basketId;
    this.totalAmount = totalAmount;
    this.address = new AddressRequest();
    this.address.state = "Srbija";
    this.contact = new ContactRequest();
  }

  personType?: PersonType;
  basketId!: string;
  totalAmount!: number;
  paymentMethod?: PaymentMethod;
  address!: AddressRequest;
  contact!: ContactRequest;
  notes?: string;

  static isValid(order: CreateOrderRequest, emailService: EmailService): boolean {
    if (!order || !order.address || !order.contact) {
      return false;
    }

    if (order.personType === PersonType.Person) {
      return !!order.basketId &&
        !!order.address.city &&
        !!order.address.number &&
        !!order.address.postalCode &&
        !!order.address.state &&
        !!order.address.street &&
        !!order.contact.email &&
        !!emailService.isEmailValid(order.contact.email) &&
        !!order.contact.firstName &&
        !!order.contact.lastName;
    }

    if (order.personType === PersonType.Company) {
      return !!order.basketId &&
        !!order.address.city &&
        !!order.address.number &&
        !!order.address.postalCode &&
        !!order.address.state &&
        !!order.address.street &&
        !!order.contact.companyName &&
        !!order.contact.email &&
        !!emailService.isEmailValid(order.contact.email) &&
        !!order.contact.firstName &&
        !!order.contact.lastName;
    }

    return false;
  }
}

export class AddressRequest {
  street!: string;
  number!: string;
  city!: string;
  postalCode!: string;
  state!: string;
  floor?: string;
  apartmentNumber?: string;

  static streetAndNumber(address: AddressRequest) {
    if (!address) {
      return "";
    }
    return `${address.street} ${address.number}`;
  }
}

export class ContactRequest {
  firstName!: string;
  lastName!: string;
  companyName?: string;
  companyTaxNumber?: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
}

export class UserResponse {
  id!: string;
  username!: string;
  firstName!: string;
  lastName!: string;
  fullName!: string;
  street!: string;
  number!: string;
  city!: string;
  postalCode!: string;
  state!: string;
  floor?: string;
  apartmentNumber?: string;
  companyName?: string;
  companyTaxNumber?: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
}

export class SpecificationListResponse {
  name!: string;
  priority!: number;
  originalNames!: string;
  areValuesLoaded!: boolean;
  values!: SpecificationValueListResponse[];
}

export class SpecificationValueListResponse {
  mappingName!: string;
  originalNames!: string;
}

export class CategorySearchedListResponse {
  categoryId!: string;
  name!: string;
  slug!: string;
  priority!: number;
  products!: ProductListResponse[];
}

export class PlacesResponse {
  postalCode!: string;
  street!: string;
  city!: string;
  standardAvailable!: boolean;
  cargoAvailable!: boolean;
}

export class SlugResponse {
  slug!: string;
  lastModifiedAt!: Date;
}

export class BrandResponse {
  brand!: string;
  lastModifiedAt!: Date;
}
