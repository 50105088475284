import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmailService } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-text-input-checkout',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class BaseTextInputCheckoutComponent {

  touched = false;
  @Input() type = "text";
  @Input() validationMessage = "";
  @Input() placeholder = "";
  @Input() order!: any;
  @Input() param1!: string;
  @Input() param2!: string;
  @Input() disabled = false;
  @Output() modelChanged = new EventEmitter<any>();

  constructor(private emailService: EmailService) {}

  onInputFocused() {
    this.touched = true;
  }

  onInputChanged() {
    if (this.type === 'email' && !this.emailService.isEmailValid(this.order[this.param1][this.param2])) {
      this.validationMessage = /*$localize*/`Email nije validan`;
    } else {
      this.validationMessage = "";
    }
  }
}
