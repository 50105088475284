import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  BasketViewService,
  CompanyViewService,
  FramesService,
  WishesViewService,
  ProductListViewModel,
} from '@b2c-frontend/http';
import { WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'lib-ui-template-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class BaseProductCardComponent implements OnInit {
  private window: any;

  @Input() product!: ProductListViewModel;
  @Input() linkedProductId?: string;
  @Input() useCheckbox = false;
  @Input() showAddToBasket = true;
  @Output() checkboxChange = new EventEmitter<ProductListViewModel>();

  constructor(
    private router: Router,
    private basketViewService: BasketViewService,
    private wishesViewService: WishesViewService,
    private windowService: WindowService,
    private framesService: FramesService,
    public authService: AuthService,
    public companyViewService: CompanyViewService,
  ) {}

  ngOnInit(): void {
    this.window = this.windowService.getWindow();
  }

  getHref() {
    if (this.linkedProductId) {
      return `/p/${this.product.slug}?linkedProductId=${this.linkedProductId}`;
    }
    return `/p/${this.product.slug}`;
  }

  openProduct() {
    if (this.linkedProductId) {
      this.router.navigate([`/p/${this.product.slug}`], {
        queryParams: { linkedProductId: this.linkedProductId },
      });
    } else {
      this.router.navigate([`/p/${this.product.slug}`]);
    }
  }

  addToBasket() {
    this.basketViewService.addItem({
      productId: this.product.id,
      productName: this.product.name,
      productUniqueId: this.product.uniqueId,
      price: this.product.price,
      isPromoPriceActive: this.product.isPromoPriceActive,
      promoPrice: this.product.promoPrice,
      availability: this.product.availability,
      productPictureUrl: this.product.mainPictureUrl,
      quantity: 1,
      linkedProductId: this.linkedProductId,
    });
  }

  addToWish() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
      return;
    }

    if (this.product.isInWishList) {
      return;
    }

    this.wishesViewService.addItem({
      productId: this.product.id,
      productName: this.product.name,
      price: this.product.price,
      isPromoPriceActive: this.product.isPromoPriceActive,
      promoPrice: this.product.promoPrice,
      productPictureUrl: this.product.mainPictureUrl,
    });
  }

  onCheckboxChanged() {
    this.product.selected = !this.product.selected;
    this.checkboxChange.emit(this.product);
  }

  removeFromWishList() {
    this.wishesViewService.deleteItem(this.product.id);
  }

  getStickerFrameImageUrl(frameId: string) {
    return this.framesService.getImageUrl(frameId);
  }

  getRegularPriceStyles(): any {
    if (this.product.isPromoPriceActive) {
      if (this.window.innerWidth <= 800) {
        return {
          'font-size': '12px',
          'color': 'gray',
        };
      } else {
        return {
          'font-size': '14px',
          'color': '#454545',
        };
      }
    } else {
      if (this.window.innerWidth <= 800) {
        return {
          'font-size': '18px',
          'color': 'black',
          'margin-bottom': '12px',
          'font-weight': '700',
        };
      } else {
        return {
          'font-size': '20px',
          'font-weight': 'bold',
          'color': 'black',
          'margin-bottom': '18px',
        };
      }
    }
  }
}
