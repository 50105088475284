import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  BasketResponse,
  BasketViewService,
  CompanyService,
  CreateOrderRequest,
  EmailService,
  PersonType,
  UsersService,
  PlacesViewModel
} from '@b2c-frontend/http';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss'],
})
export class BaseFirstStepComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  places: PlacesViewModel[] = [];
  cities: string[] = [];
  streets: string[] = [];
  postalCodes: string[] = [];
  isPersonSelected = false;
  isCompanySelected = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private usersService: UsersService,
    private companyService: CompanyService,
    private emailService: EmailService,
    public basketViewService: BasketViewService,
  ) {}

  ngOnInit(): void {
    this.basketViewService.basketObservable
      .pipe(takeUntil(this.ngUnsubscribe), shareReplay(1))
      .subscribe({
        next: (basket) => {
          if (!basket || !basket.items) {
            this.router.navigate(['/basket']);
            return;
          }

          if (!this.basketViewService.orderRequest) {
            this.basketViewService.orderRequest = new CreateOrderRequest(
              basket.basketId ?? '',
              basket.total + basket.transportCost,
            );
          }

          if (this.authService.isAuthenticated()) {
            this.usersService
              .getUser()
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (user) => {
                  if (!user || !this.basketViewService.orderRequest) {
                    return;
                  }

                  this.basketViewService.setOrderDataFromUserData(user);

                  this.isPersonSelected =
                    this.basketViewService.orderRequest.personType ===
                    PersonType.Person;
                  this.isCompanySelected =
                    this.basketViewService.orderRequest.personType ===
                    PersonType.Company;
                  this.refreshPlaces();
                },
              });
          } else {
            this.initData(basket);
          }
        },
      });
    this.basketViewService.getBasket();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onPersonButtonClicked() {
    if (!this.basketViewService.orderRequest) {
      return;
    }

    this.basketViewService.orderRequest.personType = PersonType.Person;
    this.isPersonSelected = true;
    this.isCompanySelected = false;
    document.querySelector('.img-checkbox')?.classList.add('visible');
  }

  onCompanyButtonClicked() {
    if (!this.basketViewService.orderRequest) {
      return;
    }

    this.basketViewService.orderRequest.personType = PersonType.Company;
    this.isPersonSelected = false;
    this.isCompanySelected = true;
    document.querySelector('.img-checkbox')?.classList.remove('visible');
  }

  onNextClicked() {
    if (!this.basketViewService.orderRequest) {
      return;
    }

    if (
      !CreateOrderRequest.isValid(
        this.basketViewService.orderRequest,
        this.emailService,
      )
    ) {
      return;
    }

    this.router.navigate(['/checkout/second']);
  }

  get PersonType() {
    return PersonType;
  }

  onChanged(isTriggered: boolean) {
    if (!isTriggered) {
      return;
    }

    this.refreshPlaces();
  }

  onCleared(isTriggered: boolean) {
    if (!isTriggered) {
      return;
    }

    this.refreshPlaces();
  }

  private initData(basket: BasketResponse) {
    if (!this.basketViewService.orderRequest) {
      return;
    }

    this.basketViewService.setTotalAmount(
      basket.total + basket.transportCost,
    );
    this.isPersonSelected =
      this.basketViewService.orderRequest.personType === PersonType.Person;
    this.isCompanySelected =
      this.basketViewService.orderRequest.personType === PersonType.Company;
    this.refreshPlaces();
  }

  private refreshPlaces() {
    if (!this.basketViewService.orderRequest) {
      return;
    }

    this.companyService
      .getPlaces(
        this.basketViewService.orderRequest.address.postalCode,
        this.basketViewService.orderRequest.address.city,
        this.basketViewService.orderRequest.address.street,
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (places) => {
          this.places = places;
          this.cities = [...new Set(this.places.map((item) => item.city))];
          this.postalCodes = [
            ...new Set(this.places.map((item) => item.postalCode)),
          ];
          this.streets = [...new Set(this.places.map((item) => item.street))];
        },
      });
  }
}
