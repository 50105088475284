import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '@b2c-frontend/http';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class BaseContactComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  messageMaxLength = 1000;
  restLength!: number;

  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    subject: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    message: new FormControl('', [Validators.required, Validators.maxLength(this.messageMaxLength)]),
  });

  constructor(private companyService: CompanyService, private toastr: ToastrService) {

  }

  ngOnInit(): void {
      this.restLength = this.messageMaxLength;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    const isValid = this.emailForm.valid;
    if (!isValid) {
      return;
    }

    const values = this.emailForm.value;

    this.companyService.sendEmail({
      email: values.email,
      name: values.name,
      subject: values.subject,
      message: values.message
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: response => {
        if (!response) {
          return;
        }

        this.toastr.success(/*$localize*/`Poruka je uspešno poslata`, /*$localize*/`Notifikacija`);
        this.emailForm.reset();
      }
    });
  }

  onMessageKeyUp($event: any) {
    const value = $event.target.value;
    const length = value.length;

    this.restLength = this.messageMaxLength - length;
  }
}
