import { CategoryProductResponse, FrameResponse, ProductListResponse, SpecificationProductResponse } from "../http-models";
import { CategoryProductViewModel, FrameViewModel, ProductListViewModel, SpecificationProductViewModel } from "../view-models";

export class Mappings {
    static mapCategorProductResponseToCategoryProductViewModel(category: CategoryProductResponse): CategoryProductViewModel {
        const categoryVm = new CategoryProductViewModel();
        categoryVm.categoryId = category.id;
        categoryVm.parentId = category.parentId;
        categoryVm.createdAt = category.createdAt;
        categoryVm.description = category.description;
        categoryVm.hasChildren = category.hasChildren;
        categoryVm.imageUrl = category.imageUrl;
        categoryVm.name = category.name;
        categoryVm.priority = category.priority;
        categoryVm.shortDescription = category.shortDescription;
        categoryVm.slug = category.slug;
        categoryVm.title = category.title;
        categoryVm.updatedAt = category.updatedAt;
        return categoryVm;
    }

    static mapProductListToProductListViewModel(product: ProductListResponse): ProductListViewModel {
        const productVm = new ProductListViewModel();
        productVm.availability = product.availability;
        productVm.categories = product.categories?.map(category => Mappings.mapCategorProductResponseToCategoryProductViewModel(category));
        productVm.discountInPercentage = product.discountInPercentage;
        productVm.id = product.id;
        productVm.insignificantSpecification = product.insignificantSpecification;
        productVm.isInWishList = product.isInWishList;
        productVm.isNew = product.isNew;
        productVm.isPromoPriceActive = product.isPromoPriceActive;
        productVm.mainPictureUrl = product.mainPictureUrl;
        productVm.name = product.name;
        productVm.pictureUrls = product.pictureUrls;
        productVm.price = product.price;
        productVm.promoPrice = product.promoPrice;
        productVm.recommendedPrice = product.recommendedPrice;
        productVm.slug = product.slug;
        productVm.stickerFrames = product.stickerFrames?.map(frame => ({
            code: frame.code,
            description: frame.description,
            frameType: frame.frameType,
            id: frame.id,
            imageUrl: frame.imageUrl,
            shortDescription: frame.shortDescription,
            slug: frame.slug,
            title: frame.title,
        }));
        productVm.transportCost = product.transportCost;
        productVm.uniqueId = product.uniqueId;

        return productVm;
    }

    static mapSpecificationProductResponseToSpecificationProductViewModel(spec: SpecificationProductResponse): SpecificationProductViewModel {
        const specVm = new SpecificationProductViewModel();
        specVm.group = spec.group;
        specVm.name = spec.name;
        specVm.value = spec.value;

        return specVm;
    }

    static mapFrameResponseToFrameViewModel(frame: FrameResponse): FrameViewModel {
        const frameVm = new FrameViewModel();
        frameVm.code = frame.code;
        frameVm.description = frame.description;
        frameVm.frameType = frame.frameType;
        frameVm.id = frame.id;
        frameVm.imageUrl = frame.imageUrl;
        frameVm.shortDescription = frame.shortDescription;
        frameVm.slug = frame.slug;
        frameVm.title = frame.title;

        return frameVm;
    }
}