<div class="row mt-5 margin-top-reduce">
    <div class="col-6" *ngIf="stickerFrames && stickerFrames.length">
      <div class="row">
        <div class="gar col-4 mt-4" [style.z-index]="zIndex - i" *ngFor="let stickerFrame of stickerFrames; let i = index">
          <ng-template #popContent><div [innerHTML]="stickerFrame.description"></div></ng-template>
          <img
              width="100"
              height="100"
              [src]="getStickerFrameImageUrl(stickerFrame.id)"
              [alt]="stickerFrame.title"
              ngbPopover
              [popover]="popContent"
              triggers="mouseover"
              placement="right"/>
      </div>                
      </div>
    </div>
    <div class="col-6" *ngIf="!stickerFrames || !stickerFrames.length">
    </div>
  </div>