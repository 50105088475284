import {Component, EventEmitter, Input, Output} from '@angular/core';
import { BasketItemViewModel } from '@b2c-frontend/http';
import { BasketViewService } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-basket-product-item',
  templateUrl: './basket-product-item.component.html',
  styleUrls: ['./basket-product-item.component.scss']
})
export class BaseBasketProductItemComponent {
  @Input() product!: BasketItemViewModel;
  @Output() removeProduct = new EventEmitter<BasketItemViewModel>();

  constructor(private basketViewService: BasketViewService) { }
  
  increaseQuantity() {
    this.basketViewService.addItem({
      productId: this.product.productId,
      productName: this.product.productName,
      productUniqueId: this.product.productUniqueId,
      price: this.product.price,
      isPromoPriceActive: this.product.isPromoPriceActive,
      promoPrice: this.product.promoPrice,
      productPictureUrl: this.product.productPictureUrl,
      availability: "1",
      linkedProductId: this.product.linkedProductId,
      quantity: ++this.product.quantity
    });
  }

  decreaseQuantity() {
    if (this.product.quantity > 1) {
      this.product.quantity--;
      this.basketViewService.addItem({
        productId: this.product.productId,
        productName: this.product.productName,
        productUniqueId: this.product.productUniqueId,
        price: this.product.price,
        isPromoPriceActive: this.product.isPromoPriceActive,
        promoPrice: this.product.promoPrice,
        productPictureUrl: this.product.productPictureUrl,
        availability: "1",
        linkedProductId: this.product.linkedProductId,
        quantity: this.product.quantity
      });
    }
  }

  removeFromBasket() {
    this.removeProduct.emit(this.product);
  }

}
