<div class="container" *ngIf="basket$ | async as basket">
    <div class="row d-flex" *ngIf="basket && basket.items && basket.items.length">
        <div class="col-md-9 col-xs-12 col-sm-12">
            <div class="first-column">
                <lib-ui-template-basket-table [basket]="basket" [canDelete]="true"></lib-ui-template-basket-table>
                <button *ngIf="!!basket" routerLink="/checkout/first" class="next_button">Nastavi dalje</button>
            </div>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-12">
            <div class="second-column">
                <lib-ui-template-basket-summary [basket]="basket"></lib-ui-template-basket-summary>
            </div>
        </div>
    </div>
    <div class="row d-flex" *ngIf="!basket || !basket.items || !basket.items.length">
        <div class="col-md-9 col-xs-12 col-sm-12">
            Korpa je prazna
        </div>
    </div>
</div>
