import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-section-header',
  templateUrl: './section-header.component.html',
  host: { ngSkipHydration: 'true' }
})
export class BaseSectionHeaderComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  showBreadcrumbs = true;

  constructor(private router: Router, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe), shareReplay(1)).subscribe({
      next: event => {
        if (event instanceof NavigationEnd) {
          if (event.url === "/" || event.url === "/home") {
            this.showBreadcrumbs = false;
          } else {
            this.showBreadcrumbs = true;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }
}

