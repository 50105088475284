import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CategoriesService,
  CompanyViewService,
  CategoryListViewModel
} from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { WindowService } from '@b2c-frontend/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class BaseMenuDropdownComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private window: any;
  categories: CategoryListViewModel[] = [];
  isMobile = false;

  @Input() dropdownMenuOpened = false;
  @Input() positionLeft = '0';

  constructor(
    private windowService: WindowService,
    private categoriesService: CategoriesService,
    private router: Router,
    public companyViewService: CompanyViewService,
  ) {}

  ngOnInit(): void {
    this.categoriesService
      .getMainCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (categories) => {
          this.categories = categories;
          this.categories.filter(cat => cat.children && cat.children.length).forEach((category) => {
            this.categoriesService
              .getCategories(category.id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (children) => {
                  category.children = children;
                },
              });
          });
        },
      });

    this.window = this.windowService.getWindow();
    this.isMobile = this.window.innerWidth < 800;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDropdown() {
    this.categories.forEach((category) => {
      category.open = false;
      if (category.children && category.children.length) {
        category.children.forEach((c) => (c.open = false));
      }
    });
  }

  onMainCategoryMouseEnter(category: CategoryListViewModel) {
    this.openCategory(category);
  }

  onMainCategoryClicked(category: CategoryListViewModel) {
    if (this.isMobile) {
      this.onMainCategoryMouseEnter(category);
      return;
    }

    if (!category.open) {
      this.openCategory(category);
    } else {
      this.closeDropdown();
      this.router.navigate(
        [`/ps/c/${CategoryListViewModel.getSlug(category)}`],
        { queryParams: { categoryId: category.id } },
      );
    }
  }

  seeAllMainCategories(category: CategoryListViewModel) {
    this.closeDropdown();
    this.router.navigate(
      [`/ps/c/${CategoryListViewModel.getSlug(category)}`],
      { queryParams: { categoryId: category.id } },
    );
  }

  onSubCategoryClicked(category: CategoryListViewModel) {
    this.closeDropdown();
    this.router.navigate(
      [`/ps/c/${CategoryListViewModel.getSlug(category)}`],
      { queryParams: { categoryId: category.id } },
    );
  }

  onAllCategoriesClicked(category: CategoryListViewModel) {
    this.closeDropdown();
    this.router.navigate(
      [`/ps/c/${CategoryListViewModel.getSlug(category)}`],
      { queryParams: { categoryId: category.id } },
    );
  }

  getHref(category: CategoryListViewModel) {
    return `/ps/c/${CategoryListViewModel.getSlug(category)}`;
  }

  private openCategory(category: CategoryListViewModel) {
    this.categories.forEach((c) => (c.open = false));
    category.open = true;
  }
}
