import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseClient } from '../base-client';
import { map, Observable } from 'rxjs';
import { UserResponse } from '../http-models';
import { mapper } from '../mappings/mapper';
import { UserViewModel } from '../view-models';

@Injectable()
export class UsersService extends BaseClient {
  
  constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
    super(http, baseApiUrl);
  }

  login(request: any): Observable<string> {
    return this.post<string>(`users/login`, request);
  }

  register(request: any): Observable<string> {
    return this.post<string>(`users/register`, request);
  }

  addPromoEmail(request: any): Observable<string> {
    return this.post<string>(`users/add-promo-email`, request);
  }

  removePromoEmail(email: string): Observable<string> {
    return this.delete<string>(`users/${email}/remove-promo-email`);
  }

  changePassword(request: any): Observable<string> {
    return this.put<string>(`users/change-password?q=${Date.now()}`, request);
  }

  update(request: any): Observable<string> {
    return this.put<string>(`users/update?q=${Date.now()}`, request);
  }

  getUser(): Observable<UserViewModel> {
    return this.get<UserResponse>(`users/get?q=${Date.now()}`)
               .pipe(map(user => {
                  if (user) {
                    return mapper.map(user, UserResponse, UserViewModel);
                  }
                  return user;
                }));
  }
}
