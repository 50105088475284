import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import {
  BrandResponse,
  CategorySearchedListResponse,
  CategoryGroupSelectResponse,
  ProductDetailsResponse,
  ProductListPaginationResponse,
  ProductListResponse,
} from '../http-models';
import { BasketViewService } from './basket-view.service';
import {
  BrandViewModel,
  CategoryGroupSelectViewModel,
  CategorySearchedListViewModel,
  ProductDetailsViewModel,
  ProductListPaginationViewModel,
  ProductListViewModel,
} from '../view-models';
import { Mappings } from './common.mappings';

@Injectable()
export class ProductsService extends BaseClient {
  private pageSize = 10;

  constructor(
    http: HttpClient,
    @Inject('BASE_API_URL') baseApiUrl: string,
    private basketViewService: BasketViewService,
  ) {
    super(http, baseApiUrl);
  }

  getProducts(
    page = 1,
    pageSize = this.pageSize,
    searchTerm = '',
    withPromoPrice = false,
    includeNew = false,
    sorting = '',
    minPrice: number | undefined = undefined,
    maxPrice: number | undefined = undefined,
    categoryIds: string[] = [],
    specifications: string[] = [],
    brands: string[] = [],
    needProductIds = false,
  ): Observable<ProductListPaginationViewModel> {
    let queryParams = `?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&withPromoPrice=${withPromoPrice}&includeNew=${includeNew}&needProductIds=${needProductIds}`;

    if (categoryIds && categoryIds.length > 0) {
      categoryIds.forEach((id) => {
        queryParams += `&categoryIds=${id}`;
      });
    }

    if (specifications && specifications.length > 0) {
      specifications.forEach((value) => {
        queryParams += `&specifications=${value}`;
      });
    }

    if (brands && brands.length > 0) {
      brands.forEach((value) => {
        queryParams += `&brands=${value}`;
      });
    }

    if (sorting) {
      queryParams += `&sorting=${sorting}`;
    }

    if (minPrice && maxPrice) {
      queryParams += `&minPrice=${minPrice}&maxPrice=${maxPrice}`;
    }

    return this.get<ProductListPaginationResponse>(
      `products${queryParams}`,
    ).pipe(
      map((result) => {
        if (!result || !result.items || !result.items.length) {
          return new ProductListPaginationViewModel();
        }
        const pagination = new ProductListPaginationViewModel();
        pagination.items = result.items.map(item => Mappings.mapProductListToProductListViewModel(item));
        pagination.pageSize = result.pageSize;
        pagination.productIds = result.productIds;
        pagination.totalCount = result.totalCount;
        pagination.totalPages = result.totalPages;

        return pagination;
      }),
    );
  }

  getProductsWithQueryParams(
    queryParams: string,
  ): Observable<ProductListPaginationViewModel> {
    return this.get<ProductListPaginationResponse>(
      `products?${queryParams}`,
    ).pipe(
      map((result) => {
        if (!result || !result.items || !result.items.length) {
          return new ProductListPaginationViewModel();
        }
        const pagination = new ProductListPaginationViewModel();
        pagination.items = result.items.map(item => Mappings.mapProductListToProductListViewModel(item));
        pagination.pageSize = result.pageSize;
        pagination.productIds = result.productIds;
        pagination.totalCount = result.totalCount;
        pagination.totalPages = result.totalPages;

        return pagination;
      }),
    );
  }

  search(
    searchTerm = '',
    pageSize = this.pageSize,
  ): Observable<CategorySearchedListViewModel[]> {
    return this.get<CategorySearchedListResponse[]>(
      `products/search?searchTerm=${searchTerm}&pageSize=${pageSize}&q=${Date.now()}`,
    ).pipe(
      map((result) => {
        if (!result || !result.length) {
          return [];
        }
        return result.map(item => this.mapCategorySearchedListResponseToCategorySearchedListViewModel(item));
      }),
    );
  }

  getProduct(slug: string): Observable<ProductDetailsViewModel> {
    if (localStorage?.getItem(this.basketViewService.basketIdKey)) {
      return this.get<ProductDetailsResponse>(
        `products/${slug}/get?basketId=${localStorage?.getItem(this.basketViewService.basketIdKey)}&q=${Date.now()}`,
      ).pipe(
        map((product) => {
          if (product) {
            return this.mapProductDetailsResponseToProductDetailsViewModel(product);
          }
          return product;
        }),
      );
    }
    return this.get<ProductDetailsResponse>(
      `products/${slug}/get?q=${Date.now()}`,
    ).pipe(
      map((product) => {
        if (product) {
          return this.mapProductDetailsResponseToProductDetailsViewModel(product);
        }
        return product;
      }),
    );
  }

  getBrands(productIds?: string[]): Observable<BrandViewModel[]> {
    return this.post<BrandViewModel[]>(`products/get-brands`, productIds).pipe(
      map((brands) => {
        if (!brands || !brands.length) {
          return [];
        }
        return brands.map(brand => this.mapBrandResponseToBrandViewModel(brand));
      }),
    );
  }

  getMostSoldProducts(): Observable<ProductListViewModel[]> {
    return this.get<ProductListResponse[]>(`products/most-sold`).pipe(
      map((products) => {
        if (!products || !products.length) {
          return [];
        }
        return products.map(item => Mappings.mapProductListToProductListViewModel(item));
      }),
    );
  }

  getIdenticalProducts(productId: string): Observable<ProductListViewModel[]> {
    return this.get<ProductListResponse[]>(
      `products/${productId}/get-identical-products`,
    ).pipe(
      map((products) => {
        if (!products || !products.length) {
          return [];
        }
        return products.map(item => Mappings.mapProductListToProductListViewModel(item));
      }),
    );
  }

  getRecommendedProducts(
    primaryProductId: string,
    primaryCategoryGroupId?: string,
  ): Observable<ProductListViewModel[]> {
    let queryParams = '';
    if (primaryCategoryGroupId) {
      queryParams += `primaryCategoryGroupId=${primaryCategoryGroupId}&`;
    }

    //if (localStorage?.getItem(this.basketViewService.basketIdKey)) {
    //    queryParams += `basketId=${localStorage?.getItem(this.basketViewService.basketIdKey)}`;
    //}

    return this.get<ProductListResponse[]>(
      `groups/list/${primaryProductId}?${queryParams}`,
    ).pipe(
      map((products) => {
        if (!products || !products.length) {
          return [];
        }
        return products.map(item => Mappings.mapProductListToProductListViewModel(item));
      }),
    );
  }

  getSecondaryCategory(
    primaryCategoryId?: string,
  ): Observable<CategoryGroupSelectViewModel> {
    return this.get<CategoryGroupSelectResponse>(
      `groups/secondary-category/${primaryCategoryId}`,
    ).pipe(
      map((group) => {
        if (group) {
          return this.mapCategoryGroupSelectResponseToCategoryGroupSelectViewModel(group);
        }
        return group;
      }),
    );
  }

  private mapCategorySearchedListResponseToCategorySearchedListViewModel(category: CategorySearchedListResponse): CategorySearchedListViewModel {
    const categoryVm = new CategorySearchedListViewModel();
    categoryVm.categoryId = category.categoryId;
    categoryVm.name = category.name;
    categoryVm.priority = category.priority;
    categoryVm.products = category.products?.map(product => Mappings.mapProductListToProductListViewModel(product));
    categoryVm.slug = category.slug;

    return categoryVm;
  }

  private mapProductDetailsResponseToProductDetailsViewModel(product: ProductDetailsResponse): ProductDetailsViewModel {
    const productVm = new ProductDetailsViewModel();
    productVm.availability = product.availability;
    productVm.barCode = product.barCode;
    productVm.categories = product.categories?.map(category => Mappings.mapCategorProductResponseToCategoryProductViewModel(category));
    productVm.code = product.code;
    productVm.currency = product.currency;
    productVm.description = product.description;
    productVm.discountInPercentage = product.discountInPercentage;
    productVm.groupedSpecifications = product.groupedSpecifications?.map(spec => ({
      name: spec.name,
      specifications: spec.specifications?.map(specProduct => Mappings.mapSpecificationProductResponseToSpecificationProductViewModel(specProduct)),
    }));
    productVm.id = product.id;
    productVm.imageUrls = product.imageUrls?.map(image => ({
      is3D: image.is3D,
      isSelected: image.isSelected,
      isVideo: image.isVideo,
      url: image.url,
    }));
    productVm.installmentsPrice = product.installmentsPrice;
    productVm.isNew = product.isNew;
    productVm.isPromoPriceActive = product.isPromoPriceActive;
    productVm.mainPictureUrl = product.mainPictureUrl;
    productVm.name = product.name;
    productVm.price = product.price;
    productVm.productId = product.productId;
    productVm.promoPrice = product.promoPrice;
    productVm.promoPriceEndDate = product.promoPriceEndDate;
    productVm.promoPriceStartDate = product.promoPriceStartDate;
    productVm.saving = product.saving;
    productVm.slug = product.slug;
    productVm.specifications = product.specifications?.map(spec => Mappings.mapSpecificationProductResponseToSpecificationProductViewModel(spec));
    productVm.specificationsAsString = product.specificationsAsString;
    productVm.stickerFrames = product.stickerFrames?.map(frame => Mappings.mapFrameResponseToFrameViewModel(frame));
    productVm.supplierName = product.supplierName;
    productVm.transportCost = product.transportCost;
    productVm.url = product.url;

    return productVm;
  }

  private mapBrandResponseToBrandViewModel(brand: BrandResponse): BrandViewModel {
    const brandVm = new BrandViewModel();
    brandVm.brand = brand.brand;
    brandVm.lastModifiedAt = brand.lastModifiedAt;

    return brandVm;
  }

  private mapCategoryGroupSelectResponseToCategoryGroupSelectViewModel(categoryGroup: CategoryGroupSelectResponse): CategoryGroupSelectViewModel {
    const categoryGroupVm = new CategoryGroupSelectViewModel();
    categoryGroupVm.id = categoryGroup.id;
    categoryGroupVm.name = categoryGroup.name;
    categoryGroupVm.priceMargin = categoryGroup.priceMargin;

    return categoryGroupVm;
  }
}
