import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseProductsComponent } from './products.component';
import { BaseRedirectComponent } from '../pages/redirect/redirect.component';

const routes: Routes = [
  {
    path: 'ps',
    component: BaseProductsComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Proizvodi' } },
  },
  {
    path: 'ps/c/:category',
    data: {
      breadcrumb: {
        data: 'Second-Level',
        label: 'Proizvodi - Kategorija',
        alias: 'productsTitle',
      },
    },
    component: BaseProductsComponent,
  },
  {
    path: 'ps/c/:category/b/:brand',
    data: {
      breadcrumb: {
        data: 'Third-Level',
        label: 'Proizvodi - Kategorija - Brend',
        alias: 'productsTitle',
      },
    },
    component: BaseProductsComponent,
  },
  {
    path: 'ps/b/:brand',
    data: {
      breadcrumb: {
        data: 'Second-Level',
        label: 'Proizvodi - Brend',
        alias: 'productsTitle',
      },
    },
    component: BaseProductsComponent,
  },
  {
    path: 'ps/c/:category/b/:brand/s/:specification',
    component: BaseRedirectComponent,
  },
  {
    path: 'ps/c/:category/s/:specification',
    component: BaseRedirectComponent,
  },
  {
    path: 'ps/c/:category/st/:searchTerm',
    component: BaseRedirectComponent,
  },
  {
    path: 'ps/st/:searchTerm',
    component: BaseRedirectComponent,
  },
  {
    path: 'ps/with-promo-price',
    data: { noMeta: true },
    component: BaseRedirectComponent,
  },
  {
    path: 'ps/new-products',
    data: { noMeta: true, path: '/products/new-products' },
    component: BaseRedirectComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
