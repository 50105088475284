<div class="col-12">
    <ng-template #brandTemplate>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" [value]="brand" id="brand-cb-{{brand}}" [attr.checked]="isSelected ? 'checked' : null">
            <label class="form-check-label" for="brand-cb-{{brand}}" (click)="onBrandChanged()">
                <span>{{brand}}</span>
            </label>
        </div>
    </ng-template>
    <b2c-frontend-a [relativeHref]="getHref()" [content]="brandTemplate">
    </b2c-frontend-a>
</div>