/* eslint-disable @nx/enforce-module-boundaries */
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import {
  AuthService,
  BasketViewService,
  CompanyViewService,
  WishesViewService,
} from '@b2c-frontend/http';
import { DeviceDetectionComponent, WindowService } from '@b2c-frontend/common';
import { Router } from '@angular/router';
@Component({
  selector: 'lib-ui-template-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  host: { ngSkipHydration: 'true' }
})
export class BaseNavBarComponent
  extends DeviceDetectionComponent
  implements OnInit
{
  @ViewChild('mainHeader') mainHeader!: ElementRef;

  dropdownMenuOpened = false;
  wishlistLoginOpened = false;
  loginOpened = false;
  registerOpened = false;
  showSearchDropdown = false;
  isScrolled = false;
  basketItemsCount = 0;
  wishItemsCount = 0;

  showSearchInput = true;

  constructor(
    private router: Router,
    private wishesViewService: WishesViewService,
    public basketViewService: BasketViewService,
    public authSertice: AuthService,
    public companyViewService: CompanyViewService,
    windowService: WindowService,
  ) {
    super(windowService);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.isScrolled = this.window.scrollY > 0;
  }

  ngOnInit(): void {
    this.showSearchInput = this.window.innerWidth > 1400;
    this.basketViewService.basketObservable.subscribe({
      next: (basket) => {
        if (!basket || !basket.items) {
          this.basketItemsCount = 0;
          return;
        }

        this.basketItemsCount = basket.itemsCount;
      },
    });

    this.basketViewService.getBasket();

    this.wishesViewService.messageSource.subscribe({
      next: (wish) => {
        if (!wish || !wish.items) {
          this.wishItemsCount = 0;
          return;
        }

        this.wishItemsCount = wish.items.length;
      },
    });
  }

  onCloseMenu() {
    this.dropdownMenuOpened = false;
    this.basketViewService.showBasketSide = false;
  }

  onOpenLogin() {
    this.loginOpened = true;
    this.registerOpened = false;
  }

  onDropdownOpen(isOpen: boolean) {
    this.dropdownMenuOpened = isOpen;
  }

  onOpenWishlistLogin() {
    this.wishlistLoginOpened = true;
    this.registerOpened = false;
  }

  onOpenRegister() {
    this.registerOpened = true;
    this.loginOpened = false;
  }
  
  onOpenBasketSide() {
    this.basketViewService.showBasketSide =
      !this.basketViewService.showBasketSide;
  }

  closeBasketSide() {
    this.basketViewService.showBasketSide = false;
  }

  onMenuToggle() {
    this.dropdownMenuOpened = true;
  }

  onSearchDropdownShow(show: boolean) {
    this.showSearchDropdown = show;
  }

  navigateTo(route: string, queryParams?: any) {
    this.router.navigate([route], { queryParams: queryParams });
    this.dropdownMenuOpened = false;
  }

  get showHeaderTopBar(): boolean {
    return !this.isScrolled && this.showSearchInput;
  }

  get showCentralHeader(): boolean {
    return this.isScrolled || this.isMobile;
  }

  get showBottomHeader(): boolean {
    return !this.isScrolled && !this.isMobile;
  }

  get showLogo(): boolean {
    return !this.isScrolled && !this.isMobile;
  }

  get showOutsideContainer(): boolean {
    return this.dropdownMenuOpened || this.basketViewService.showBasketSide || this.showSearchDropdown;
  }
}
