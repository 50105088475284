import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-ui-template-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class BasePaymentMethodComponent {
  @Input() paymentData: any;
  @Output() paymentSelected = new EventEmitter<any>();

  onSelectPaymentCard() {
    this.paymentSelected.emit(this.paymentData);
  }

}
