import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, EMPTY, map } from 'rxjs';
import { BaseClient } from '../base-client';
import { CategoryListResponse, CategoryProductResponse } from '../http-models';
import { CategoryListViewModel, CategoryProductViewModel } from '../view-models';
import { Mappings } from './common.mappings';

@Injectable()
export class CategoriesService extends BaseClient {

    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getCategories(parentId = ""): Observable<CategoryListViewModel[]> {
        return this.get<CategoryListResponse[]>(`categories?parentId=${parentId}`)
                   .pipe(map(categories => { 
                        if (!categories || !categories.length) {
                            return [];
                        }

                        const categoriesVm: CategoryListViewModel[] = [];

                        for (let i = 0; i < categories.length; i++) {
                            const categoryResponse = categories[i];
                            categoriesVm.push(this.mapCategoryListResponseToCategoryListViewModel(categoryResponse));
                        }

                        return categoriesVm;
                    }));
    }

    getCategory(id: string): Observable<CategoryProductViewModel> {
        if (!id) {
            return EMPTY;
        }
        return this.get<CategoryProductResponse>(`categories/${id}/get`)
        .pipe(map(category => {
            if (category) {
                return Mappings.mapCategorProductResponseToCategoryProductViewModel(category);
            }
            return category;
        }));
    }

    getMainCategories(): Observable<CategoryListViewModel[]> {
        return this.get<CategoryListResponse[]>(`categories/get-main`)
        .pipe(map(categories => {
            if (!categories || !categories.length) {
                return [];
            }
            const categoriesVm: CategoryListViewModel[] = [];

            for (let i = 0; i < categories.length; i++) {
                const categoryResponse = categories[i];
                categoriesVm.push(this.mapCategoryListResponseToCategoryListViewModel(categoryResponse));
            }

            return categoriesVm;
        }));
    }

    getCategoriesByProductIds(productIds?: string[], parentId = ""): Observable<CategoryListViewModel[]> {
        return this.post<CategoryListResponse[]>(`categories/get-by-productIds?parentId=${parentId}`, productIds)
                   .pipe(map(categories => {
                        if (!categories || !categories.length) {
                            return [];
                        }
                        const categoriesVm: CategoryListViewModel[] = [];

                        for (let i = 0; i < categories.length; i++) {
                            const categoryResponse = categories[i];
                            categoriesVm.push(this.mapCategoryListResponseToCategoryListViewModel(categoryResponse));
                        }

                        return categoriesVm;
                    }));
    }

    getImageUrl(categoryId: string) {
        return `${this.baseApiUrl}/categories/image/${categoryId}`;
    }

    private mapCategoryListResponseToCategoryListViewModel(category: CategoryListResponse): CategoryListViewModel {
        const categoryVm = new CategoryListViewModel();
        categoryVm.children = category.children?.map(child => ({
            hasCategories: child.hasCategories,
            hasProducts: child.hasProducts,
            id: child.id,
            imageUrl: child.imageUrl,
            name: child.name,
            parentId: child.parentId,
            parentName: child.parentName,
            parentSlug: child.parentSlug,
            priority: child.priority,
            shortDescription: child.shortDescription,
            slug: child.slug,
            urlQueryParameters: child.urlQueryParameters,
            path: '',
            open: false
        }));
        categoryVm.hasCategories = category.hasCategories;
        categoryVm.hasProducts = category.hasProducts;
        categoryVm.id = category.id;
        categoryVm.imageUrl = category.imageUrl;
        categoryVm.name = category.name;
        categoryVm.parentId = category.parentId;
        categoryVm.parentName = category.parentName;
        categoryVm.parentSlug = category.parentSlug;
        categoryVm.priority = category.priority;
        categoryVm.shortDescription = category.shortDescription;
        categoryVm.slug = category.slug;
        categoryVm.urlQueryParameters = category.urlQueryParameters;
        return categoryVm;
    }
}
