import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BasketViewService,
  CompanyViewService,
  CreateOrderRequest,
  EmailService,
  PaymentMethod,
} from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss'],
})
export class BaseSecondStepComponent implements OnInit {
  paymentCards: any = [];

  constructor(
    private basketViewService: BasketViewService,
    private emailService: EmailService,
    private router: Router,
    public companyViewService: CompanyViewService,
  ) {}

  ngOnInit(): void {
    this.paymentCards.push({
      img: '../../../assets/images/payment-card/payment1.svg',
      text: /*$localize*/ `Gotovinski prilikom preuzimanja`,
      selected: false,
      type: PaymentMethod.CashOnDelivery,
      description: /*$localize*/ `Vaša porudžbina će biti dostavljena`,
    });

    this.paymentCards.push({
      img: '../../../assets/images/payment-card/payment3.svg',
      text: /*$localize*/ `Uplata na tekući račun`,
      selected: false,
      type: PaymentMethod.BankTransfer,
      description: /*$localize*/ `Nakon obavljene kupovine, dobićete potrebne podatke za uplatu na navedeni e-mail. Proces plaćanja se može obaviti online ili odlaskom u banku`,
    });

    if (this.companyViewService.company?.enableOnlinePaymentApi) {
      this.paymentCards.push({
        img: '../../../assets/images/payment-card/payment2.svg',
        text: /*$localize*/ `Kupovina na rate kreditnom karticom`,
        selected: false,
        type: PaymentMethod.CreditCard,
        description: /*$localize*/ `Plaćanje kreditnim karticama Banca Intesa bez kamate. Nakon obavljene kupovine bićete preusmereni na portal Banca Intesa gde ćete nakon unosa broja kartice dobiti mogućnost da platite na 11 mesečnih rata`,
      });

      this.paymentCards.push({
        img: '../../../assets/images/payment-card/payment4.svg',
        text: /*$localize*/ `Online platna kartica`,
        selected: false,
        type: PaymentMethod.OnlinePaymentCard,
        description: /*$localize*/ `Koristeći platnu karticu obavite kupovinu bezbedno. Nakon završene kupovine bićete preusmereni na portal Banca Intesa gde možete i obaviti plaćanje. Za pomoć možete posetiti našu stranicu sa uputstvom, ili pozvati našeg operatera`,
      });
    }

    if (this.companyViewService.company?.enablePayPalApi) {
      this.paymentCards.push({
        img: '../../../assets/images/payment-card/payment4.svg',
        text: /*$localize*/ `PayPal`,
        selected: false,
        type: PaymentMethod.PayPal,
        description: /*$localize*/ `Koristeći PayPal obavite kupovinu bezbedno. Nakon završene kupovine bićete preusmereni na portal PayPal-a gde možete i obaviti plaćanje. Za pomoć možete posetiti našu stranicu sa uputstvom, ili pozvati našeg operatera`,
      });
    }

    if (
      !this.basketViewService.orderRequest ||
      !CreateOrderRequest.isValid(
        this.basketViewService.orderRequest,
        this.emailService,
      )
    ) {
      this.router.navigate(['/checkout/first']);
    } else {
      const selectedPaymentCard = this.paymentCards.find(
        (p: any) =>
          p.type === this.basketViewService.orderRequest?.paymentMethod,
      );
      if (selectedPaymentCard) {
        selectedPaymentCard.selected = true;
      }
    }
  }

  onSelectPaymentCard(selectedCard: any) {
    this.paymentCards.forEach((card: any) => {
      card.selected = card === selectedCard;
    });
    this.basketViewService.setPaymentMethod(selectedCard.type);
  }

  onNextClicked() {
    if (!this.basketViewService.orderRequest?.paymentMethod) {
      return;
    }

    this.router.navigate(['/checkout/third']);
  }
}
