import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {
  FrameType,
  FramesService,
  ProductsService,
  CategoryListViewModel,
  FrameViewModel,
  ProductListViewModel,
  PaginationResponse
} from '@b2c-frontend/http';
import { MetaService, MetaTagComponent } from '@b2c-frontend/seo';
import { Constants, WindowService } from '@b2c-frontend/common';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-ui-template-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class BaseHomeComponent extends MetaTagComponent implements OnInit {
  slidersFrame$!: Observable<PaginationResponse<FrameViewModel>>;
  bannersFrame$!: Observable<PaginationResponse<FrameViewModel>>;
  popularCategoriesFrame$!: Observable<FrameViewModel>;
  oneCategoryFrame$!: Observable<FrameViewModel>;
  productsOnSaleFrame$!: Observable<FrameViewModel>;
  mostSoldProducts$!: Observable<ProductListViewModel[]>;
  recommendationFrames$!: Observable<PaginationResponse<FrameViewModel>>;
  popularCategoryFrame$!: Observable<FrameViewModel>;

  popularCategories!: CategoryListViewModel[];
  oneCategoryProducts!: ProductListViewModel[];
  popularCategoriesInCategoryFrame!: CategoryListViewModel[];
  popularProductsInCategoryFrame!: ProductListViewModel[];
  productsOnSale!: ProductListViewModel[];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    margin: 1,
    startPosition: 0,
    nav: true,
    navText: [
      '<img src="../../assets/images/landing/left-arrow.svg" alt="Previous"></img>',
      '<img src="../../assets/images/landing/right-arrow.svg" alt="Next"></img>',
    ],
    responsive: {
      0: {
        items: 2,
        margin: 5,
      },
      600: {
        items: 3,
      },
      800: {
        items: 4,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };
  customOptionsCategories: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    margin: 1,
    startPosition: 0,
    nav: true,
    navText: [
      '<img src="../../assets/images/landing/left-arrow.svg"alt="Previous"></img>',
      '<img src="../../assets/images/landing/right-arrow.svg"alt="Next"></img>',
    ],
    responsive: {
      0: {
        items: 3,
        margin: 5,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1000: {
        items: 6,
      },
      1200: {
        items: 7,
      },
    },
  };

  bannerOptions: OwlOptions = {
    loop: true,
    items: 1,
    autoplay: true,
    nav: true,
    navText: [
      '<img src="../../assets/images/landing/left-arrow.svg"alt="Previous"></img>',
      '<img src="../../assets/images/landing/right-arrow.svg"alt="Next"></img>',
    ],
  };

  constructor(
    private router: Router,
    private productsService: ProductsService,
    private framesService: FramesService,
    metaService: MetaService,
    windowService: WindowService,
  ) {
    super(metaService, windowService);
  }

  ngOnInit(): void {
    if (this.window) {
      this.customOptions.autoplayHoverPause = this.window.innerWidth >= 800;
      this.customOptionsCategories.autoplayHoverPause = this.window.innerWidth >= 800;
    }

    this.setMetaData(
      'Ipsilon Shop početna stranica',
      'Ipsilon',
      '',
      `Najnovija IT oprema i bela tehnika dostupna online! Kupujte računare, laptopove, pametne telefone uz besplatnu dostavu. Ekskluzivni popusti i brza isporuka`,
    );

    this.slidersFrame$ = this.framesService.getFrames(1, 10, '', FrameType.Slider);
    this.bannersFrame$ = this.framesService.getFrames(1, 3, '', FrameType.Banner);
    this.popularCategoriesFrame$ = this.framesService.getByCode(Constants.PopularCategoriesCode);
    this.popularCategoriesFrame$.subscribe({
      next: (frame) => {
        if (!frame) {
          return;
        }
        this.framesService.getCategories(frame.id).subscribe({
          next: categories => {
            this.popularCategories = categories;
          }
        });
      },
    });

    this.oneCategoryFrame$ = this.framesService.getByCode(Constants.OneCategoryCode);
    this.oneCategoryFrame$.subscribe({
      next: (frame) => {
        if (!frame) {
          return;
        }
        this.framesService.getCategories(frame.id).subscribe({
          next: categories => {
            if (!categories || categories.length <= 0) {
              return;
            }

            this.productsService.getProducts(1, 10, '', false, false, '', undefined, undefined, categories.map((c) => c.id))
              .subscribe({
                next: (resultbyCategories) => {
                  this.oneCategoryProducts = resultbyCategories?.items ?? [];
                },
            });
          }
        });
      },
    });

    this.popularCategoryFrame$ = this.framesService.getByCode(Constants.PopularCategoryCode);
    this.popularCategoryFrame$
      .subscribe({
        next: (frame) => {
          this.framesService.getCategories(frame.id).subscribe({
              next: (categories) => {
                if (!categories || categories.length <= 0) {
                  return;
                }
                this.popularCategoriesInCategoryFrame = categories;
                this.productsService.getProducts(1, 3, '', false, true, '', undefined, undefined, categories.map((c) => c.id))
                  .subscribe({
                    next: (result) => {
                      if (!result || !result.items) {
                        return;
                      }

                      if (result.items.length < 3) {
                        this.framesService.getProducts(frame.id).subscribe({
                            next: (products) => {
                              this.popularProductsInCategoryFrame = products
                            },
                          });
                        return;
                      }
                      this.popularProductsInCategoryFrame = result.items;
                    },
                  });
              },
            });
        },
      });

    this.productsOnSaleFrame$ = this.framesService.getByCode(Constants.ProductsOnSaleCode);
    this.productsOnSaleFrame$
      .subscribe({
        next: (frame) => {
          if (!frame) {
            return;
          }

          this.productsOnSale = [];
          this.framesService.getCategories(frame.id).subscribe({
              next: (categories) => {
                if (!categories || !categories.length) {
                  this.framesService.getProducts(frame.id).subscribe({
                      next: (products) => {
                        this.productsOnSale = products;
                      },
                    });
                  return;
                }

                this.productsService.getProducts(1, 10, '', true, false, '', undefined, undefined, categories.map((c) => c.id))
                  .subscribe({
                    next: (resultbyCategories) => {
                      if (!resultbyCategories || !resultbyCategories.items) {
                        this.productsService
                          .getProducts(1, 10, '', true)
                          .subscribe({
                            next: (resultByProducts) => {
                              this.productsOnSale = resultByProducts.items;
                            },
                          });
                        return;
                      }

                      this.productsOnSale = resultbyCategories.items;
                    },
                  });
              },
            });
        },
      });

    this.mostSoldProducts$ = this.productsService.getMostSoldProducts();
    this.recommendationFrames$ = this.framesService.getFrames(1, 4, '', FrameType.Recommendation);
  }

  getHrefFrame(frame: FrameViewModel) {
    return `/ps/c/${frame.slug}`;
  }

  navigateToFrame(frame: FrameViewModel) {
    return this.router.navigate([`/ps/c/${frame.slug}`], { queryParams: { frameId: frame.id } });
  }

  getHrefCategory(category: CategoryListViewModel) {
    return `/ps/c/${category.slug}`;
  }

  navigateToCategory(category: CategoryListViewModel) {
    return this.router.navigate([`/ps/c/${category.slug}`], { queryParams: { categoryId: category.id } });
  }
}
