import { Component, Input } from '@angular/core';
import { DeviceDetectionComponent, WindowService } from '@b2c-frontend/common';
import { ImageService } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-frame-image',
  templateUrl: './frame-image.component.html',
})
export class BaseFrameImageComponent extends DeviceDetectionComponent {
  @Input() imageUrl?: string;
  @Input() cssClasses?: string;
  @Input() altTitle?: string;

  constructor(private imageService: ImageService, windowService: WindowService) {
    super(windowService);
  }

  getImageUrl() {
    if (!this.imageUrl) {
      return '';
    }

    const regularImageUrl = this.imageUrl.split(';').find((url) => !url.includes('mobile'));
    const mobileImageUrl = this.imageUrl.split(';').find((url) => url.includes('mobile'));

    if (this.isMobile) {
        return this.imageService.getImageUrl(!mobileImageUrl ? regularImageUrl : mobileImageUrl);
    }

    return this.imageService.getImageUrl(regularImageUrl ?? '');
  }
}
