<ng-container *ngIf="basket$ | async as basket">
  <div class="container" *ngIf="opened">
    <div class="basket-wrapper" *ngIf="basket && basket.items && basket.items.length > 0">
      <div class="basket-top">
        <span>Korpa</span>
        <img (click)="closeBasketSide()" src="../../../assets/images/header/x-button.svg" alt="Zatvori"
          class="hover-image">
        <img *ngIf="!isMobile" (click)="closeBasketSide()" src="../../../assets/images/header/x-button-red.svg"
          alt="Zatvori crveno" class="hover-image-red">
      </div>
      <div class="basket-info">
        <div class="basket-price">
          <p class="number-of-products">Broj proizvoda u korpi: <b>{{basket.itemsCount}}</b></p>
          <div class="prices">
            <span>Ukupno za plaćanje</span>
            <span><b>{{basket.total | number:'1.2-2'}} {{companyViewService.company?.defaultCurrency}}</b></span>
          </div>
          <div class="prices action-price-prices">
            <span>Akcijska ušteda</span>
            <span class="action-price">{{basket.totalSaving ?? 0.0 | number:'1.2-2'}}
              {{companyViewService.company?.defaultCurrency}}</span>
          </div>
        </div>
        <div class="basket-buttons">
          <button (click)="onOpenBasketPage()" class="grey_button basket-button">Pregled korpe</button>
          <button (click)="onOpenCheckoutPage()" class="main_button basket-button">Završi kupovinu</button>
        </div>
        <div class="basket-products">
          <div *ngFor="let product of basket.items">
            <lib-ui-template-basket-product-item (removeProduct)="onRemoveProductFromBasket($event)"
              [product]="product"></lib-ui-template-basket-product-item>
          </div>
        </div>
      </div>
    </div>

    <div class="basket-wrapper" *ngIf="!basket || !basket.items || !basket.items.length"
      [style.margin-right]="marginRight">
      <div class="basket-top">
        <span>Korpa</span>
        <img (click)="closeBasketSide()" src="../../../assets/images/header/x-button.svg" alt="Zatvori">
        <img *ngIf="!isMobile" (click)="closeBasketSide()" src="../../../assets/images/header/x-button-red.svg"
          alt="Zatvori crveno" class="hover-image-red">
      </div>
      <div class="basket-text">
        <img style="height: 40px;margin-top: 5px;" src="../../../assets/images/checkout/recycle-bin.gif"
          alt="Recycle Bin"><br>
        <span style="font-size: 20px;">Korpa je prazna</span><br>
        <span style="font-size: 16px;">Izgleda da nemaš proizvoda u korpi</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(basket$ | async) === undefined">
  <div class="container" *ngIf="opened">
    <div class="basket-wrapper" [style.margin-right]="marginRight">
      <div class="basket-top">
        <span>Korpa</span>
        <img (click)="closeBasketSide()" src="../../../assets/images/header/x-button.svg" alt="Zatvori">
        <img *ngIf="!isMobile" (click)="closeBasketSide()" src="../../../assets/images/header/x-button-red.svg"
          alt="Zatvori crveno" class="hover-image-red">
      </div>
      <div class="basket-text">
        <img style="height: 40px;margin-top: 5px;" src="../../../assets/images/checkout/recycle-bin.gif"
          alt="Recycle Bin"><br>
        <span style="font-size: 20px;">Korpa je prazna</span><br>
        <span style="font-size: 16px;">Izgleda da nemaš proizvoda u korpi</span>
      </div>
    </div>
  </div>
</ng-container>