<div class="product-card">
  <div class="discount-rate d-flex align-items-center justify-content-between">
    <span *ngIf="product.discountInPercentage && product.isPromoPriceActive" class="discount">Uštedi
      {{product.discountInPercentage}}%</span>
    <span *ngIf="product.isNew" class="new-product">Novo</span>
    <img *ngIf="product.isInWishList" class="fas fa-solid fa-xmark rate-img" (click)="removeFromWishList()"
      src="../../../assets/images/header/x-button.svg" alt="x-button" style="height: 18px;" />
    <div class="form-check" (click)="onCheckboxChanged()" *ngIf="useCheckbox">
      <input style="cursor: pointer;" class="form-check-input" type="checkbox" [value]="product.id"
        [attr.checked]="product.selected ? 'checked' : null" />
    </div>
  </div>
  <div class="wishlist-icon">
    <img *ngIf="!useCheckbox && authService.isAuthenticated() && !product.isInWishList" class="rate-img"
      src="../../../assets/images/header/heart-silver.svg" alt="heart-silver" (click)="addToWish()" />
    <span *ngIf="!useCheckbox && authService.isAuthenticated() && product.isInWishList" class="rate-img">
      <i class="fa fa-solid fa-heart wishlist-icon-full"></i>
    </span>
  </div>
  <div class="sticker" *ngIf="product.stickerFrames && product.stickerFrames.length">
    <p *ngFor="let stickerFrame of product.stickerFrames">
      <img loading="lazy" decoding="async" class="sticker-img" [src]="getStickerFrameImageUrl(stickerFrame.id)"
        [alt]="stickerFrame.title" />
    </p>
  </div>
  <ng-template #openProductTemplate>
    <div class="image d-flex" (click)="openProduct()">
      <img *ngIf="product.mainPictureUrl" class="main-img mobile-image" [src]="product.mainPictureUrl"
        [alt]="product.name" loading="lazy" decoding="async" />
      <img *ngIf="!product.mainPictureUrl" class="main-img mobile-image" loading="lazy" decoding="async"
        src="../../../assets/images/product-card/no-image.svg" [alt]="product.name" />
    </div>
    <div class="title d-flex" (click)="openProduct()">
      <h3 class="product-name">{{product.name}}</h3>
    </div>
  </ng-template>
  <b2c-frontend-a [relativeHref]="getHref()" [content]="openProductTemplate">
  </b2c-frontend-a>
  <div class="promotion-container">
    <ng-container *ngIf="product.availability && product.availability !== '0'">
      <ng-container
        *ngIf="product.transportCost === 0 && product.isPromoPriceActive && !!product.promoPrice; else singleText">
        <div class="old animate">
          <i class="fa fa-solid fa-truck-moving"></i>Besplatna dostava
        </div>
        <div class="new animate">
          Ušteda {{ (product.price - product.promoPrice) | number:'1.0-0' }} {{
          companyViewService.company?.defaultCurrency }}
        </div>
      </ng-container>
      <ng-template #singleText>
        <div *ngIf="product.transportCost === 0" class="old">
          <i class="fa fa-solid fa-truck-moving"></i>Besplatna dostava
        </div>

        <div *ngIf="product.isPromoPriceActive && !!product.promoPrice" class="new">
          Ušteda {{ (product.price - product.promoPrice) | number:'1.0-0' }} {{
          companyViewService.company?.defaultCurrency }}
        </div>
      </ng-template>
      <div *ngIf="product.isPromoPriceActive && !!product.promoPrice" class="discounted-price mobile-price"
        [ngClass]="!useCheckbox ? 'mt-3' : ''">
        {{ product.promoPrice | number:'1.2-2' }}
        <span>{{ companyViewService.company?.defaultCurrency }}</span>
      </div>
    </ng-container>
  </div>
  <div class="price-container d-flex justify-content-between align-items-end">
    <div class="prices mobile-prices">
      <div [ngStyle]="getRegularPriceStyles()"
        class="regular-price mobile-regular-price {{product.isPromoPriceActive ? 'crossed' : ''}}">
        {{product.price | number:'1.2-2'}}
        <span>{{companyViewService.company?.defaultCurrency}}</span>
      </div>

    </div>
    <div *ngIf="showAddToBasket && product.availability && product.availability !== '0' && !useCheckbox" class="add-to-basket-button"
      (click)="addToBasket()">
      <img class="basket-img" src="../../../assets/images/product-card/add-to-cart.svg" alt="add-to-cart" />
      <span class="basket-hover-box">Dodaj u korpu</span>
    </div>
  </div>
</div>