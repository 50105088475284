export enum FrameType {
    None = 0,
    Banner = 1,
    Blog = 2,
    EmailTemplate = 3,
    PromoEmail = 4,
    Recommendation = 5,
    StaticPage = 6,
    Slider = 7,
    Sticker = 8
}

export enum OrderStatus
{
    Created = 1,
    Confirmed,
    ReadyToSend,
    Sent,
    Failed,
    Completed,
    Refund
}

export const OrderStatus2LabelMapping: Record<any, string> = {
    [OrderStatus.Created]: "Nova",
    [OrderStatus.Confirmed]: "Potvrđena",
    [OrderStatus.ReadyToSend]: "Spremna za slanje",
    [OrderStatus.Sent]: "Poslata",
    [OrderStatus.Failed]: "Nije uspela",
    [OrderStatus.Completed]: "Završena",
    [OrderStatus.Refund]: "Refundacija"
};

export enum PaymentMethod
{
    CashOnDelivery = 1,
    CreditCard,
    BankTransfer,
    OnlinePaymentCard,
    PayPal
}

export const PaymentMethod2LabelMapping: Record<any, string> = {
    [PaymentMethod.CashOnDelivery]: "Način plaćanja po uzeću",
    [PaymentMethod.CreditCard]: "Način plaćanja kreditnom karticom",
    [PaymentMethod.BankTransfer]: "Način plaćanja prenos preko banke",
    [PaymentMethod.OnlinePaymentCard]: "Način plaćanja preko online platne kartice",
    [PaymentMethod.PayPal]: "Način plaćanja preko paypal-a",
};


export enum PersonType {
  Person = 1,
  Company = 2
}

export enum Sorting {
    PriceUp = 1,
    PriceDown = 2
}
