/* eslint-disable @nx/enforce-module-boundaries */
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  CategoryProductViewModel,
  CategorySearchedListViewModel,
  ProductListViewModel,
  ProductsService,
} from '@b2c-frontend/http';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'lib-ui-template-nav-bar-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class BaseSearchInputComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  searchTerm = '';
  timer: any;
  showLoader!: boolean;

  searchedResult: CategorySearchedListViewModel[] = [];
  filteredProducts: ProductListViewModel[] = [];
  categories: CategoryProductViewModel[] = [];

  @Input() showSearchDropdown = false;
  @Output() searchDropdownShow = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private productsService: ProductsService,
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearTimeout(this.timer);
  }

  onSearchTermChanged(event: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.searchTerm.length < 3) {
        return;
      }
      this.showLoader = true;
      this.searchTerm = event.target.value;
      this.getProducts();
    }, 1000);
  }

  onSearchIconClicked() {
    this.getProducts();
  }

  onCategorySelected(category: CategoryProductViewModel) {
    this.categories.forEach((c) => (c.selected = false));
    category.selected = true;
    this.filteredProducts = this.searchedResult.find((s) => s.name === category.name)?.products ?? [];
    this.showSearchDropdown = false;
    this.searchDropdownShow.emit(this.showSearchDropdown);
    this.router.navigate([`/ps/c/${category.slug}`], {
      queryParams: {
        searchTerm: this.searchTerm,
        categoryId: category.categoryId,
      },
    });
    this.searchTerm = '';
  }

  showAllProducts() {
    this.showSearchDropdown = false;
    this.searchDropdownShow.emit(this.showSearchDropdown);
    this.router.navigate([`/ps`], {
      queryParams: { searchTerm: this.searchTerm },
    });
    this.searchTerm = '';
  }

  closeSearchDropdown() {
    this.showSearchDropdown = false;
    this.searchDropdownShow.emit(this.showSearchDropdown);
  }

  private getProducts() {
    this.productsService
      .search(this.searchTerm, 30)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.showLoader = false;
          this.showSearchDropdown = true;
          this.searchDropdownShow.emit(this.showSearchDropdown);
          if (!result || !result.length) {
            this.categories = [];
            this.filteredProducts = [];
            return;
          }

          this.categories = [];
          this.filteredProducts = [];
          this.searchedResult = result;
          for (let i = 0; i < result.length; i++) {
            const element = result[i];
            const categoryVm = new CategoryProductViewModel();
            categoryVm.categoryId = element.categoryId;
            categoryVm.name = element.name;
            categoryVm.selected = false;
            categoryVm.priority = element.priority;
            categoryVm.slug = element.slug;
            this.categories.push(categoryVm);
          }
          this.filteredProducts = this.searchedResult[0].products;
          this.categories[0].selected = true;
        },
        error: (result) => {
          this.showSearchDropdown = true;
          this.searchDropdownShow.emit(this.showSearchDropdown);
          this.categories = [];
          this.filteredProducts = [];
          this.showLoader = false;
        },
      });
  }
}
