<h5 *ngIf="product.insignificantSpecification">{{product.insignificantSpecification.name}}</h5>
<ng-template #openProductTemplate>
    <div class="image" (click)="navigateToProduct()">
        <img loading="lazy"
            decoding="async"
            [src]="product.mainPictureUrl"
            [alt]="product.name"/>
        <p *ngIf="product.insignificantSpecification"><strong>{{product.insignificantSpecification.value}}</strong></p>
    </div>
</ng-template>
<b2c-frontend-a [relativeHref]="getHref()" [content]="openProductTemplate"></b2c-frontend-a>