import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
  enabled: false,
};

@NgModule({
    declarations: [CookieConsentComponent],
    imports: [
        CommonModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    exports: [CookieConsentComponent]
})
export class CookieConsentModule {}
