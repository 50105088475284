import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class AuthService {
  private tokenKey = `${this.originUrl}-frontend_token`;
  constructor(private readonly jwtHelper: JwtHelperService, @Inject('ORIGIN_URL') protected originUrl: string) { }

  public getToken(): string {
    return localStorage?.getItem(this.tokenKey) ?? "";
  }

  public setToken(token: string): void {
    localStorage?.setItem(this.tokenKey, token);
  }

  public removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }

  public get companyId(): string {
    const token = this.getToken();
    if (!token) {
      return "";
    }

    const tokenPayload = <any>jwtDecode(token);
    return tokenPayload?.sub;
  }

  public get fullName(): string {
    const token = this.getToken();
    if (!token) {
      return "";
    }

    const tokenPayload = <any>jwtDecode(token);
    return tokenPayload?.name;
  }

  public get firstName(): string {
    const names = this.fullName?.split(' ');
    if (!names || !names.length) {
      return this.fullName;
    }

    return names[0];
  }

  public get username(): string {
    const token = this.getToken();
    if (!token) {
      return "";
    }

    const tokenPayload = <any>jwtDecode(token);
    return tokenPayload?.email;
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    // Check whether the token is expired and return true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
}
