<div class="collapsible-wrap" *ngIf="brandsViewModel && brandsViewModel.length > 1">
    <div class="collapsible" (click)="onOpenBrands()">
        <span>Brend</span>
        <img src="../../../../assets/images/banner/arrow-down.svg" alt="arrow-down" [class.arrow-rotate]="isBrandsOpened">
    </div>
    <div class="collapsible-row" *ngIf="isBrandsOpened">
        <lib-ui-template-product-brand 
            *ngFor="let brand of brandsViewModel"
            [brand]="brand"
            [isSelected]="isBrandSelected(brand)"
            (brandChanged)="onBrandChanged($event)"></lib-ui-template-product-brand>
    </div>
</div>
<div class="collapsible-wrap" *ngIf="brands && brands.length > 1 && (!brandsViewModel || !brandsViewModel.length)">
    <div class="collapsible" (click)="onOpenBrands()">
        <span>Brend</span>
        <img src="../../../../assets/images/banner/arrow-down.svg" alt="arrow-down" [class.arrow-rotate]="isBrandsOpened">
    </div>
    <div class="collapsible-row" *ngIf="isBrandsOpened">
        <lib-ui-template-product-brand 
            *ngFor="let brand of brands"
            [brand]="brand"
            [isSelected]="isBrandSelected(brand)"
            (brandChanged)="onBrandChanged($event)"></lib-ui-template-product-brand>
    </div>
</div>