import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@b2c-frontend/http';
import { BaseUserDataComponent } from './user-data/user-data.component';
import { BaseWishlistAccountComponent } from './wishlist-account/wishlist-account.component';
import { BaseAccountComponent } from './account.component';
import { BaseOrdersComponent } from './orders/orders.component';
import { BaseLoginComponent } from './login/login.component';
import { BaseWishlistLoginComponent } from './wishlist-login/wishlist-login.component';
import { BaseRegisterComponent } from './register/register.component';

const routes: Routes = [
  {path: 'login', component: BaseLoginComponent },
  {path: 'wishlist-login', component: BaseWishlistLoginComponent },
  {path: 'register', component: BaseRegisterComponent },
  {
    path: 'account', component: BaseAccountComponent,
    data: { breadcrumb: { data: 'First-Level', label: 'Korisnički račun' } },
    children: [
    {
      path: 'user-data', component: BaseUserDataComponent,
      data: { breadcrumb: { data: 'Second-Level', label: 'Korisnički podaci' } },
      title: "Korisnički nalog | Ipsilon",
      canActivate: [AuthGuardService]
    },
    {
      path: 'wishlist', component: BaseWishlistAccountComponent,
      data: { breadcrumb: { data: 'Second-Level', label: 'Lista želja' } },
      title: "Lista želja | Ipsilon",
      canActivate: [AuthGuardService]
    },
    {
      path: 'orders', component: BaseOrdersComponent,
      data: { breadcrumb: { data: 'Second-Level', label: 'Porudžbenice' } },
      title: "Lista porudžbenica | Ipsilon",
      canActivate: [AuthGuardService]
    }
  ]},
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
