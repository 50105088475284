import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseHomeComponent } from './home.component';

const routes: Routes = [
    {
        path: '',
        data: { breadcrumb: { data: 'Second-Level', label: 'Početna' }, title: "Online Shop | Ipsilon" },
        component: BaseHomeComponent
    },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
