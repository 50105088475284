import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FramesService,
  CategoryListViewModel,
  FrameViewModel
} from '@b2c-frontend/http';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { MetaService, MetaTagComponent } from '@b2c-frontend/seo';
import { WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'lib-ui-template-banner-blog',
  templateUrl: './banner-blog.component.html',
  styleUrls: ['./banner-blog.component.scss'],
})
export class BaseBannerBlogComponent
  extends MetaTagComponent
  implements OnInit, OnDestroy
{
  private ngUnsubscribe = new Subject<void>();
  frame!: FrameViewModel;
  categories$?: Observable<CategoryListViewModel[]>;
  description: any;

  constructor(
    private framesService: FramesService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private sanitizer: DomSanitizer,
    metaService: MetaService,
    windowService: WindowService,
  ) {
    super(metaService, windowService);
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe), shareReplay(1)).subscribe({
      next: (params) => {
        const frameId = params['blogId'];
        if (frameId) {
          this.framesService
            .getById(frameId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (frame) => {
                if (!frame) {
                  return;
                }
                this.frame = frame;
                this.description = this.sanitizer.bypassSecurityTrustHtml(this.frame.description ?? '');
                this.breadcrumbService.set('@blog', this.frame.title);
                this.setMetaData(
                  this.frame.title,
                  'Frame',
                  this.framesService.getImageUrl(this.frame.id),
                  this.frame.shortDescription ?? this.frame.description,
                );
              },
            });
          this.categories$ = this.framesService.getCategories(frameId, 8);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
