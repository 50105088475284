<b2c-frontend-cookie-consent [href]="'/page/static-page/cookie-policy'"></b2c-frontend-cookie-consent>
<ng-container>
  <section class="container mt-3">
    <div class="row d-flex align-items-center">
      <div class="col-12">
        <xng-breadcrumb *ngIf="showBreadcrumbs">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <div [innerHtml]="sanitizer.bypassSecurityTrustHtml(breadcrumb)"></div>
          </ng-container>
        </xng-breadcrumb>
      </div>
    </div>
  </section>
</ng-container>