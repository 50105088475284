import { Injectable, OnDestroy } from '@angular/core';
import { CompanyService } from './company.service';
import { CompanyResponse } from '../http-models';
import { Subject, takeUntil } from 'rxjs';
import { CompanyViewModel } from '../view-models';

@Injectable()
export class CompanyViewService implements OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    private internalCompany?: CompanyViewModel;

    constructor(private companyService: CompanyService) { }

    getCompany(): void {
        this.companyService.getCompany().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: company => {
                this.internalCompany = company;
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    get company(): CompanyResponse | undefined {
        return this.internalCompany;
    }
}
