import { Component, OnDestroy, OnInit } from '@angular/core';
import { FrameType, FramesService, FrameViewModel } from '@b2c-frontend/http';
import { PaginationInstance } from 'ngx-pagination';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BaseBlogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  
  public searchTerm = "";
  public totalCount = 0;
  public totalPages = 1;

  blogs: FrameViewModel[] = [];

  public paginatorConfig: PaginationInstance = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };

  constructor(private framesService: FramesService) {}

  ngOnInit(): void {
    this.refresh(1);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearchTermChanged(event: any) {
    this.searchTerm = event.target.value;
    this.refresh(1);
  }

  onPageChanged(page: number) {
    this.paginatorConfig.currentPage = page;
    this.refresh(page);
  }

  private refresh(page: number) {
    this.framesService.getFrames(page, 20, this.searchTerm, FrameType.Blog).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: result => {
        this.blogs = result.items;
        this.totalCount = result.totalCount;
        this.totalPages = result.totalPages;

        this.paginatorConfig.totalItems = this.totalCount;
        this.paginatorConfig.itemsPerPage = result.pageSize;
      }
    });
  }
}
