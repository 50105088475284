import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import { addProfile } from '@automapper/core';
import {
  mapper,
  basketItemViewModelMappingProfile,
  basketViewModelMappingProfile,
  brandViewModelMappingProfile,
  categoryListViewModelMappingProfile,
  categoryProductViewModelMappingProfile,
  categorySearchedListViewModelMappingProfile,
  categorySelectViewModelMappingProfile,
  companyViewModelMappingProfile,
  frameViewModelMappingProfile,
  imageUrlViewModelMappingProfile,
  orderListViewModelMappingProfile,
  placesViewModelMappingProfile,
  productListViewModelFromWishResponseMappingProfile,
  productListViewModelMappingProfile,
  productViewModelMappingProfile,
  specificationListViewModelMappingProfile,
  specificationValueListViewModelMappingProfile,
  userViewModelMappingProfile,
  wishItemViewModelMappingProfile,
  wishViewModelMappingProfile
} from '@b2c-frontend/http';
import { enableProdMode, LOCALE_ID } from '@angular/core';
import { environment } from './environments/environment';

addProfile(mapper, basketViewModelMappingProfile);
addProfile(mapper, basketItemViewModelMappingProfile);
addProfile(mapper, categoryListViewModelMappingProfile);
addProfile(mapper, companyViewModelMappingProfile);
addProfile(mapper, frameViewModelMappingProfile);
addProfile(mapper, orderListViewModelMappingProfile);
addProfile(mapper, productListViewModelMappingProfile);
addProfile(mapper, productViewModelMappingProfile);
addProfile(mapper, imageUrlViewModelMappingProfile);
addProfile(mapper, wishViewModelMappingProfile);
addProfile(mapper, wishItemViewModelMappingProfile);
addProfile(mapper, userViewModelMappingProfile);
addProfile(mapper, specificationValueListViewModelMappingProfile);
addProfile(mapper, specificationListViewModelMappingProfile);
addProfile(mapper, categorySearchedListViewModelMappingProfile);
addProfile(mapper, categoryProductViewModelMappingProfile);
addProfile(mapper, productListViewModelFromWishResponseMappingProfile);
addProfile(mapper, placesViewModelMappingProfile);
addProfile(mapper, brandViewModelMappingProfile);
addProfile(mapper, categorySelectViewModelMappingProfile);

if (process.env['NODE_ENV'] === 'production') {
  enableProdMode();
}

export function getBaseApiUrl() {
  return !process.env['NX_API_URL'] ? environment.apiUrl : process.env['NX_API_URL'];
}

export function getOriginUrl() {
  return !process.env['NX_ORIGIN_URL'] ? environment.originUrl : process.env['NX_ORIGIN_URL'];
}

export function getLocaleId() {
  return !process.env['NX_LOCALE_ID'] ? environment.defaultLanguage : process.env['NX_LOCALE_ID'];
}

const providers = [
  { provide: 'BASE_API_URL', useFactory: getBaseApiUrl, deps: [] },
  { provide: 'ORIGIN_URL', useFactory: getOriginUrl, deps: [] },
  { provide: LOCALE_ID, useFactory: getLocaleId, deps: [] }
];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
