import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CompanyService,
  UserViewService,
  UsersService,
  PlacesViewModel
} from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class BaseUserDataComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  places: PlacesViewModel[] = [];
  cities: string[] = [];
  streets: string[] = [];
  postalCodes: string[] = [];

  userDataForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    street: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    streetNumber: new FormControl('', [
      Validators.required,
      Validators.maxLength(20),
    ]),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(20),
    ]),
    city: new FormControl('', [Validators.required, Validators.maxLength(64)]),
    state: new FormControl('', [Validators.required, Validators.maxLength(64)]),
    phoneNumber: new FormControl('', [Validators.maxLength(30)]),
    mobilePhoneNumber: new FormControl('', [Validators.maxLength(30)]),
    email: new FormControl('', [Validators.maxLength(64), Validators.email]),
    companyName: new FormControl('', [Validators.maxLength(100)]),
    companyTaxNumber: new FormControl('', [Validators.maxLength(30)]),
    apartmentNumber: new FormControl('', [Validators.maxLength(20)]),
    floor: new FormControl('', [Validators.maxLength(20)]),
  });

  checkPasswords: ValidatorFn = (
    group: AbstractControl,
  ): ValidationErrors | null => {
    const pass = group.get('newPassword')?.value;
    const confirmPass = group.get('confirmNewPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  changePasswordForm = new FormGroup(
    {
      currentPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      confirmNewPassword: new FormControl(''),
    },
    { validators: this.checkPasswords },
  );

  constructor(
    private companyService: CompanyService,
    private usersService: UsersService,
    private userViewService: UserViewService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.usersService
      .getUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (userDataVm) => {
          if (!userDataVm) {
            return;
          }
          this.userDataForm.setValue({
            firstName: userDataVm.firstName,
            lastName: userDataVm.lastName,
            apartmentNumber: userDataVm.apartmentNumber ?? '',
            city: userDataVm.city ? userDataVm.city : null,
            companyName: userDataVm.companyName ?? '',
            companyTaxNumber: userDataVm.companyTaxNumber ?? '',
            email: userDataVm.email ?? '',
            floor: userDataVm.floor ?? '',
            mobilePhoneNumber: userDataVm.mobilePhoneNumber ?? '',
            phoneNumber: userDataVm.phoneNumber ?? '',
            postalCode: userDataVm.postalCode ? userDataVm.postalCode : null,
            state: userDataVm.state ? userDataVm.state : /*$localize*/ `Srbija`,
            street: userDataVm.street ? userDataVm.street : null,
            streetNumber: userDataVm.number,
          });

          this.breadcrumbService.set(
            'Second-Level',
            `${userDataVm.firstName} ${userDataVm.lastName}`,
          );
        },
      });
    this.refreshPlaces();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  saveUserData() {
    const values = this.userDataForm.value;

    if (this.userDataForm.invalid) {
      return;
    }

    this.usersService
      .update({
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        companyTaxNumber: values.companyTaxNumber,
        phoneNumber: values.phoneNumber,
        mobilePhoneNumber: values.mobilePhoneNumber,
        email: values.email,
        street: values.street,
        number: values.streetNumber,
        city: values.city,
        postalCode: values.postalCode,
        state: values.state,
        apartmentNumber: values.apartmentNumber,
        floor: values.floor,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.userViewService.getUser();
        },
      });
  }

  changePassword() {
    const values = this.changePasswordForm.value;

    if (this.changePasswordForm.invalid) {
      return;
    }

    this.usersService
      .changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.router.navigate(['/account/user-data']);
        },
      });
  }

  onChanged() {
    this.refreshPlaces();
  }

  onCleared() {
    this.refreshPlaces();
  }

  private refreshPlaces() {
    this.companyService
      .getPlaces(
        this.userDataForm.value.postalCode ?? '',
        this.userDataForm.value.city ?? '',
        this.userDataForm.value.street ?? '',
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (places) => {
          this.places = places;
          this.cities = [...new Set(this.places.map((item) => item.city))];
          this.postalCodes = [
            ...new Set(this.places.map((item) => item.postalCode)),
          ];
          this.streets = [...new Set(this.places.map((item) => item.street))];
        },
      });
  }
}
