<div *ngIf="showDialog" class="outside-container" (click)="onOutsideClick()">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-center login-wrapper" (click)="$event.stopPropagation()">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="text-center">
              <img class="close-button" (click)="onOutsideClick()" src="../../../assets/images/header/x-button.svg"
                alt="Zatvori"  />
              <img class="close-button hover-image-red" (click)="onOutsideClick()"
                src="../../../assets/images/header/x-button-red.svg" alt="Zatvori crveno"  />
              <h1 class="h3 mb-3 font-weight-normal">Uloguj se</h1>
              <p>
                <span>Nemaš nalog?</span>&nbsp;
                <span (click)="onOpenRegister()">Napravi svoj nalog ovde</span>
              </p>
            </div>
            <lib-ui-template-text-input formControlName="email" [label]="'Email adresa'" [type]="'email'">
            </lib-ui-template-text-input>
            <lib-ui-template-text-input formControlName="password" [label]="'Lozinka'" [type]="'password'">
            </lib-ui-template-text-input>
            <button class="main_button submit-button" type="submit">Prijavi se</button>
          </form>
        </div>
      </div>
    </div>
  </div>