<div class="col-12" *ngIf="category && category.hasProducts">
  <ng-template #openCategoryTemplate>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" [value]="category.id" id="category-cb-{{category.id}}"
        (click)="onCategoryChanged(category)" [attr.checked]="isCategorySelected(category.id) ? 'checked' : null">
      <div class="collapsible" (click)="onCategoryChanged(category)">
        <label class="form-check-label" for="category-cb-{{category.id}}">
          <span>{{category.name}}</span>
        </label>
        <img *ngIf="category.hasCategories" class="arrow-right" src="../../../assets/images/landing/right-arrow.svg"
          alt="">
      </div>
    </div>
  </ng-template>
  <b2c-frontend-a [relativeHref]="getHref()" [content]="openCategoryTemplate">
  </b2c-frontend-a>
</div>