import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr';
import { Response } from 'express';
//import { ɵ$localize } from '@angular/localize';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    @Optional() @Inject('RESPONSE') private response: Response) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: any): ObservableInput<any> => {
        if(error && error.status !== 200) {
          this.response.status(error.status);
          if (error.error && error.error.message) {
            this.toastr.error(error.error.message, /*$localize*/`Notifikacija`);
          }
        }

        return throwError(() => {
          return error;
        });
      })
    );
  }
}
