<div class="mb-2">
    <input
        #input
        class="grey_input"
        [type]="type"
        (blur)="onTouched($event)"
        [id]="label"
        [placeholder]="label"
        [(ngModel)]="model"
        (input)="onChange($event)"
        (change)="onInputChanged()"
        autofocus=""/>
    <small class="text-danger" *ngIf="touched && (validationMessage || !model)">
        {{validationMessage}}
    </small>
</div>