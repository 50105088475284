import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BasketService } from './basket.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { BasketViewModel } from '../view-models';
import { CreateOrderRequest, UserResponse } from '../http-models';
import { PaymentMethod, PersonType } from '../enums';
import { OrdersService } from './orders.service';

@Injectable()
export class BasketViewService implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private basketMessageSource = new BehaviorSubject(this.basket);
  private basket?: BasketViewModel;

  private order!: CreateOrderRequest | null;
  private orderMessageSource = new BehaviorSubject(this.orderId);
  private orderId?: string;

  public showBasketSide = false;
  public basketIdKey = `${this.originUrl}-basketId`;

  public basketObservable = this.basketMessageSource.asObservable();
  public orderObservable = this.orderMessageSource.asObservable();

  constructor(
    private basketService: BasketService,
    private ordersService: OrdersService,
    @Inject('ORIGIN_URL') protected originUrl: string) {

  }
  
  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  addItem(request: any): void {
    let basketId = this.basketId;
    if (!basketId) {
      basketId = this.randomString(32);
    }

    this.basketService.addItem(basketId, request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: basket => {
        localStorage?.setItem(this.basketIdKey, basket.basketId);
        if (!basket.items || !basket.items.length) {
          localStorage.removeItem(this.basketIdKey);
          localStorage.removeItem(basket.basketId);
        }

        this.setTotalAmount(basket.total + basket.transportCost);

        this.basketMessageSource.next(basket);
      }
    });
  }

  deleteItem(productId: string): void {
    this.basketService.deleteItem(this.basketId ?? '', productId).subscribe({
      next: basket => {
        if (!basket.items || !basket.items.length) {
          localStorage.removeItem(this.basketIdKey);
          localStorage.removeItem(basket.basketId);
        }

        this.setTotalAmount(basket.total + basket.transportCost);

        this.basketMessageSource.next(basket);
      }
    });
  }

  getBasket(): void {
    if (!this.basketId) {
      localStorage.removeItem(this.basketIdKey);
      return;
    }

    this.basketService.getBasket(this.basketId).subscribe({
      next: basket => {
        if (!basket || !basket.items || !basket.items.length) {
          localStorage.removeItem(this.basketIdKey);
        }

        this.basketMessageSource.next(basket);
      },
      error: result => {
        this.basketMessageSource.next(undefined);
      }
    });
  }

  get basketId(): string | null {
    return localStorage?.getItem(this.basketIdKey);
  }

  createOrder() {
    if (!this.basketId) {
      return;
    }

    if (!this.order) {
      return;
    }

    this.ordersService.createOrder(this.order).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: result => {
        if (this.basketId && localStorage?.getItem(this.basketIdKey)) {
          localStorage.removeItem(this.basketIdKey);
        }
        
        this.orderMessageSource.next(result);
      },
      error: result => {
        if (this.basketId && localStorage?.getItem(this.basketIdKey)) {
          localStorage.removeItem(this.basketIdKey);
        }

        this.orderMessageSource.next(undefined);
      }
    });
  }

  set orderRequest(order: CreateOrderRequest | null) {
    this.order = order;
  }

  get orderRequest(): CreateOrderRequest | null {
    return this.order;
  }

  setPaymentMethod(paymentMethod?: PaymentMethod) {
    if (!this.order) {
      return;
    }
    
    this.order.paymentMethod = paymentMethod;
  }

  setTotalAmount(totalAmount: number) {
    if (!this.order) {
      return;
    }

    this.order.totalAmount = totalAmount;
  }

  setNotes(notes: string) {
    if (!this.order) {
      return;
    }

    if (!this.order.notes) {
      this.order.notes = '';
    }
    this.order.notes += ' ' + notes;
  }

  setOrderDataFromUserData(user: UserResponse) {
    if (!this.order || !user) {
      return;
    }

    if (!this.order.personType && user.companyName) {
      this.order.personType = user.companyName ? PersonType.Company : PersonType.Person;
    }
    
    if (!this.order.address.apartmentNumber && user.apartmentNumber) {
      this.order.address.apartmentNumber = user.apartmentNumber;
    }
    
    if (!this.order.address.city && user.city) {
      this.order.address.city = user.city;
    }
    
    if (!this.order.address.floor && user.floor) {
      this.order.address.floor = user.floor;
    }
    
    if (!this.order.address.number && user.number) {
      this.order.address.number = user.number;
    }
    
    if (!this.order.address.postalCode && user.postalCode) {
      this.order.address.postalCode = user.postalCode;
    }
    
    if (!this.order.address.state) {
      this.order.address.state = user.state ? user.state : "Srbija";
    }
    
    if (!this.order.address.street && user.street) {
      this.order.address.street = user.street;
    }
    
    if (!this.order.contact.companyName && user.companyName) {
      this.order.contact.companyName = user.companyName;
    }
    
    if (!this.order.contact.companyTaxNumber && user.companyTaxNumber) {
      this.order.contact.companyTaxNumber = user.companyTaxNumber;
    }
    
    if (!this.order.contact.email) {
      this.order.contact.email = user.email ? user.email : user.username;
    }
    
    const names = user.fullName?.split(' ');

    if (names && names.length > 1) {
      if (!this.order.contact.firstName) {
        this.order.contact.firstName = names[0];  
      }
      
      if (!this.order.contact.lastName) {
        this.order.contact.lastName = names[1];
      }
    }

    if (!this.order.contact.mobilePhoneNumber && user.mobilePhoneNumber) {
      this.order.contact.mobilePhoneNumber = user.mobilePhoneNumber;
    }
    
    if (!this.order.contact.phoneNumber && user.phoneNumber) {
      this.order.contact.phoneNumber = user.phoneNumber;
    }
  }

  private randomString(length: number) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
}
