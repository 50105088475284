import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from '@b2c-frontend/common';
import { FramesService } from '@b2c-frontend/http';
import { MetaService, MetaTagComponent } from '@b2c-frontend/seo';
import { Subject, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'lib-ui-template-static-page',
  templateUrl: './static-page.component.html'
})
export class BaseStaticPageComponent extends MetaTagComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  description!: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private framesService: FramesService,
    public sanitizer: DomSanitizer,
    metaService: MetaService,
    windowService: WindowService) {
      super(metaService, windowService);
    }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: params => {
        const code = params['code'];
        this.framesService.getByCode(code).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: frame => {
            this.description = '';
            if (!frame) {
              return;
            }

            this.description = frame.description ?? '';
            this.breadcrumbService.set('@staticPage', frame.title);
            this.setMetaData(frame.title, 'Frame', this.framesService.getImageUrl(frame.id), frame.shortDescription ?? frame.description);
          },
          error: result => {
            this.description = 'Stranica nije pronađena';
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
