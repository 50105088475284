import { Component, Input } from '@angular/core';
import { BasketViewModel, CompanyViewService } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-basket-summary',
  templateUrl: './basket-summary.component.html',
  styleUrls: ['./basket-summary.component.scss']
})
export class BaseBasketSummaryComponent {
  @Input() basket?: BasketViewModel;

  constructor(public companyViewService: CompanyViewService) {}
}
