import { Component } from '@angular/core';
import { WindowService } from '../../services/window.service';

@Component({
  selector: 'lib-common-device-detection',
  templateUrl: './device-detection.component.html'
})
export class DeviceDetectionComponent {

  protected userAgent!: string;
  
  window: any;
  isMobile = false;
  isMobileDevice = false;

  constructor(protected windowService: WindowService) {
    this.window = this.windowService.getWindow();
    this.checkWindowSize();
    this.detectDevice();
  }

  checkWindowSize(): void {
    this.isMobile = this.window.innerWidth < 768;
  }

  detectDevice(): void {
    this.userAgent = this.window.navigator.userAgent || this.window.navigator.mediaDevices || this.window.opera;
    this.isMobileDevice = /android|iphone|ipad|iPod/i.test(this.userAgent);
  }
}
