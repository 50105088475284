import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ImageUrlViewModel, ImageService } from '@b2c-frontend/http';
import { WindowService } from '@b2c-frontend/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-ui-template-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
})
export class ProductImagesComponent implements OnChanges {
  private window: any;
  private scrollRange = 200;
  @ViewChild('imagesWrapper') imagesWrapper!: ElementRef;

  visibleImages: ImageUrlViewModel[] = [];
  isMobile = false;
  showNavigations = false;

  selectedImage?: ImageUrlViewModel | null;

  @Input() images?: ImageUrlViewModel[] = [];
  @Input() productId!: string;
  @Input() productName!: string;

  constructor(
    @Inject('BASE_API_URL') public baseApiUrl: string,
    private windowService: WindowService,
    private imageService: ImageService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.images || !this.images.length) {
      return;
    }

    this.window = this.windowService.getWindow();
    this.isMobile = this.window.innerWidth < 800;

    this.selectedImage = this.images.find((i) => i.isSelected) ?? null;

    this.visibleImages = [];

    for (let i = 0; i < this.images.length; i++) {
      const image = this.images[i];

      this.imageService.checkImage(
        image.url ?? '',
        (result: any) => {
          if (result === 'success') {
            image.is3D = false;
            image.isVideo = false;
          }

          this.visibleImages.push(image);
        },
        (result: any) => {
          if (image.is3D || image.isVideo) {
            image.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              image.url,
            );
            this.visibleImages.push(image);
          }
        },
      );
    }
  }

  print() {
    this.window.open(
      `${this.baseApiUrl}/products/${this.productId}/print`,
      '_blank',
    );
  }

  onImageSelected(selectedImage: ImageUrlViewModel) {
    this.selectedImage = selectedImage;
  }

  toggleImageSelection(selectedImage: ImageUrlViewModel) {
    if (this.images) {
      for (const image of this.images) {
        if (image) {
          image.isSelected = false;
        }
      }
    }

    selectedImage.isSelected = true;
    this.selectedImage = selectedImage;
  }

  onPrevClicked() {
    const scrollTop = this.imagesWrapper.nativeElement.scrollTop;
    const top = scrollTop - this.scrollRange > 0 ? scrollTop - this.scrollRange : 0;
    this.imagesWrapper.nativeElement.scrollTop = top;
  }

  onNexClicked() {
    const scrollTop = this.imagesWrapper.nativeElement.scrollTop;
    this.imagesWrapper.nativeElement.scrollTop = scrollTop + this.scrollRange;
  }
}
