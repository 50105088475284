import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { BasketViewModel, BasketViewService } from '@b2c-frontend/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-ui-template-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class BaseCheckoutComponent {

  basket$?: Observable<BasketViewModel | undefined>;

  constructor(private router: Router, private basketViewService: BasketViewService) { }

  isPageActive(pageNumber: string): boolean {
    const currentRoute = this.router.url;
    const children = currentRoute.split('/');
    let currentPage = children[children.length - 1];

    if (currentPage === 'checkout') {
      currentPage = 'first';
    }

    this.basket$ = this.basketViewService.basketObservable;

    return currentRoute.includes(`/${pageNumber}`) && currentPage === `${pageNumber}`;
  }
}
