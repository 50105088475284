import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import {
  CreateOrderRequest,
  OrderListResponse
} from '../http-models';
import { OrderStatus } from '../enums';
import { OrderListViewModel, PaginationViewModel } from '../view-models';
import { mapper } from '../mappings/mapper';

@Injectable()
export class OrdersService extends BaseClient {
  private pageSize = 20;

  constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
    super(http, baseApiUrl);
  }

  getOrders(
    page = 1,
    pageSize = this.pageSize,
    searchTerm = '',
    status?: OrderStatus | null,
  ): Observable<PaginationViewModel<OrderListViewModel>> {
    let url = `/orders/list?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`;
    if (status) {
      url += `&orderStatus=${status}`;
    }

    return this.get<PaginationViewModel<OrderListResponse>>(url).pipe(
      map((result) => {
        if (!result || !result.items || !result.items.length) {
          return new PaginationViewModel<OrderListViewModel>();
        }
        const pagination = new PaginationViewModel<OrderListViewModel>();
        pagination.items = mapper.mapArray(
          result.items ?? [],
          OrderListResponse,
          OrderListViewModel,
        );
        pagination.pageSize = result.pageSize;
        pagination.totalCount = result.totalCount;
        pagination.totalPages = result.totalPages;

        return pagination;
      }),
    );
  }

  createOrder(request: CreateOrderRequest): Observable<string> {
    return this.post<string>(`orders`, request);
  }
}
