<section class="container mt-5">
    <div class="row">
        <div class="col-12">
            <h4>Interna greška servera</h4>
        </div>
        <div class="col-12">
            <ng-container *ngIf="error">
                <h5 class="text-danger">
                    <span>Greška</span>: {{error.message}}
                </h5>
                <p class="font-weight-bold">
                    Angular nije odgovoran za ovu grešku.
                </p>
                <p>Šta dalje?</p>
                <ol>
                    <li>Otvorite Chrome Dev Tools</li>
                    <li>Inspektujte karticu Mreže</li>
                    <li>Proverite neuspešan zahtev</li>
                    <li>Pregledajte URL zahteva</li>
                    <li>Reprodukujte grešku u Postman-u</li>
                </ol>
                <p>Sledeći je stack trace:</p>
                <code class="mt-5" style="background-color: whitesmoke;">{{error.details}}</code>
            </ng-container>
        </div>
    </div>
</section>
