import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { WishesService } from './wishes.service';
import { WishViewModel } from '../view-models';

@Injectable()
export class WishesViewService implements OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    public messageSource = new Subject<WishViewModel>();

    constructor(private wishesService: WishesService) {
        
    }

    addItem(request: any) {
        this.wishesService.addItem(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: wish => {
                this.messageSource.next(wish);
            }
        });
    }

    deleteItem(productId: string) {
        this.wishesService.deleteItem(productId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: wish => {
                this.messageSource.next(wish);
            }
        });
    }

    getWishList() {
        this.wishesService.getWishList().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: wish => {
                this.messageSource.next(wish);
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
