import {
  Component,
  Inject,
  inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { Response } from 'express';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-redirect',
  templateUrl: './redirect.component.html',
})
export class BaseRedirectComponent implements OnInit, OnDestroy {
  private readonly platformId = inject(PLATFORM_ID);
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Optional() @Inject('RESPONSE') private response: Response,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe), shareReplay(1)).subscribe({
      next: (params) => {
        const id = params['id'];
        const blogId = params['blogId'];
        const searchTerm = params['searchTerm'];
        const specification = params['specification'];
        const category = params['category'];
        const brand = params['brand'];

        let redirectUrl = '/ps';
        let queryParams = this.activatedRoute.snapshot.queryParams;

        if (this.router.url.includes('with-promo-price')) {
          queryParams = { withPromoPrice: true };
        } else if (this.router.url.includes('new-products')) {
          queryParams = { showNewProducts: true };
        } else if (blogId) {
          redirectUrl = `/page/blogs/blog/${blogId}`;
        } else if (id) {
          redirectUrl = `/p/${id}`;
        } else if (searchTerm) {
          const content = searchTerm.replaceAll('-', ' ');
          queryParams = { searchTerm: content };
          if (category) {
            redirectUrl = `/ps/c/${category}`;
          }
        } else if (specification) {
          const content = specification
            .replaceAll('---', ';')
            .replaceAll('-', ' ')
            .replaceAll('_', ':');
          queryParams = { specifications: content };

          if (category && brand) {
            redirectUrl = `/ps/c/${category}/b/${brand}`;
          } else if (category) {
            redirectUrl = `/ps/c/${category}`;
          } else if (brand) {
            redirectUrl = `/ps/b/${brand}`;
          }
        } else if (category && brand) {
          redirectUrl = `/ps/c/${category}/b/${brand}`;
        } else if (category) {
          redirectUrl = `/ps/c/${category}`;
        } else if (brand) {
          redirectUrl = `/ps/b/${brand}`;
        }

        if (isPlatformServer(this.platformId) && this.response) {
          if (queryParams) {
            let queryParamsStr = '';
            Object.getOwnPropertyNames(queryParams).forEach((key) => {
              queryParamsStr += `${key}=${queryParams[key]}&`;
            });
            this.response.redirect(301, `${redirectUrl}?${queryParamsStr}`);
          } else {
            this.response.redirect(301, `${redirectUrl}`);
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
