<section class="container" *ngIf="frame">
    <div class="container-description">
        <div class="title">
            <h1>{{frame.title}}</h1>
            <div class="title-line"></div>
        </div>
        <div [innerHtml]="description"></div>
    </div>
    <div class="wrapper" *ngIf="categories$ | async as categories">
        <div class="category-wrapper">
            <lib-ui-template-category-second-card *ngFor="let category of categories" [category]="category"></lib-ui-template-category-second-card>
        </div>
    </div>
</section>
<section class="container" *ngIf="!frame">
    <div class="wrapper">
        Preporuka nije pronađena
    </div>
</section>