import { NgModule } from '@angular/core';
import { BaseSharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseLoginComponent } from './login/login.component';
import { BaseRegisterComponent } from './register/register.component';
import { BaseWishlistLoginComponent } from './wishlist-login/wishlist-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SeoModule } from '@b2c-frontend/seo';
import { BaseUserDataComponent } from './user-data/user-data.component';
import { BaseWishlistAccountComponent } from './wishlist-account/wishlist-account.component';
import { BaseAccountComponent } from './account.component';
import { BaseOrdersComponent } from './orders/orders.component';
import { AccountRoutingModule } from './account-routing.module';

@NgModule({
  declarations: [
    BaseLoginComponent,
    BaseRegisterComponent,
    BaseWishlistLoginComponent,
    BaseUserDataComponent,
    BaseWishlistAccountComponent,
    BaseAccountComponent,
    BaseOrdersComponent
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    NgSelectModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    BaseSharedModule,
    SeoModule
  ],
  exports: [
    BaseLoginComponent,
    BaseRegisterComponent,
    BaseWishlistLoginComponent,
    BaseUserDataComponent,
    BaseWishlistAccountComponent,
    BaseAccountComponent,
    BaseOrdersComponent
  ]
})
export class BaseAccountModule { }
