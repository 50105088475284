<ng-container *ngIf="basket">
    <div class="basket-summary" *ngIf="basket && basket.items && basket.items.length > 0">
        <div class="checkout-review">
            <h3>Pregled kupovine</h3>
            <hr style="width: 100%;">
            <div class="sum-div">
                <p class="left">Ukupna ušteda</p>
                <p class="right green-text">{{basket.totalSaving ?? 0.0}} {{companyViewService.company?.defaultCurrency}}</p>
            </div>
            <div class="sum-div">
                <p class="left">Troškovi isporuke</p>
                <p class="right">{{basket.transportCost}} {{companyViewService.company?.defaultCurrency}}</p>
            </div>
            <div class="sum-div">
                <p class="left">Vreme isporuke</p>
                <p class="right">2 do 5 radnih dana</p>
            </div>
            <div class="sum-div">
                <p class="left">Plaćanje na rate</p>
                <p class="right">0 {{companyViewService.company?.defaultCurrency}}</p>
            </div>
            <hr style="width: 100%;">
            <div class="sum-div">
                <h3 class="left" style="font-size: 14px;">Ukupno za plaćanje</h3>
                <p class="right" style="font-size: 14px;">{{(basket.total + basket.transportCost)}} {{companyViewService.company?.defaultCurrency}}</p>
            </div>
        </div>
    </div>
</ng-container>