/* eslint-disable @nx/enforce-module-boundaries */
import {
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  AuthService,
  BasketViewService,
  CompanyViewService,
  ProductsService,
  WishesViewService,
  CategoryProductViewModel,
  CategorySearchedListViewModel,
  ProductListViewModel
} from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { WindowService } from '@b2c-frontend/common';
import { shareReplay, Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'lib-ui-template-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class BaseNavBarComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private window: any;
  @ViewChild('mainHeader') mainHeader!: ElementRef;

  stickyVisible = false;
  dropdownMenuOpened = false;
  wishlistLoginOpened = false;
  componentOpen = false;
  closeComponentsInMainHeader = false;
  mainHeaderVisible = true;
  isScrolled = false;
  loginOpened = false;
  registerOpened = false;
  showSearchDropdown = false;
  basketItemsCount = 0;
  wishItemsCount = 0;
  searchTerm = '';
  searchedResult: CategorySearchedListViewModel[] = [];
  filteredProducts: ProductListViewModel[] = [];
  categories: CategoryProductViewModel[] = [];
  timer: any;
  mobileClickCounter!: number;
  showLoader!: boolean;

  @ViewChild('searchDropdown') searchDropdown!: ElementRef;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private productsService: ProductsService,
    private wishesViewService: WishesViewService,
    private windowService: WindowService,
    public basketViewService: BasketViewService,
    public authSertice: AuthService,
    public companyViewService: CompanyViewService,
  ) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.isScrolled = this.window.scrollY > 0;
  }

  ngOnInit(): void {
    this.window = this.windowService.getWindow();
    this.basketViewService.basketObservable
      .pipe(takeUntil(this.ngUnsubscribe), shareReplay(1))
      .subscribe({
        next: (basket) => {
          if (!basket || !basket.items) {
            this.basketItemsCount = 0;
            return;
          }

          this.basketItemsCount = basket.itemsCount;
        },
      });

    this.basketViewService.getBasket();

    this.wishesViewService.messageSource
      .pipe(takeUntil(this.ngUnsubscribe), shareReplay(1))
      .subscribe({
        next: (wish) => {
          if (!wish || !wish.items) {
            this.wishItemsCount = 0;
            return;
          }

          this.wishItemsCount = wish.items.length;
        },
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearTimeout(this.timer);
  }

  closeMainHeaderComponents() {
    this.showSearchDropdown = false;
  }

  onSearchTermChanged(event: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.searchTerm.length < 3) {
        return;
      }
      this.showLoader = true;
      this.searchTerm = event.target.value;
      this.getProducts();
    }, 1000);
  }

  logout() {
    console.log('');
  }

  onOpenMenu() {
    this.dropdownMenuOpened = true;
    this.componentOpen = true;
    this.disableScrolling();
  }

  onCloseMenu() {
    this.dropdownMenuOpened = false;
    this.basketViewService.showBasketSide = false;
    this.componentOpen = false;
    this.enableScrolling();
  }

  onOpenLogin() {
    this.loginOpened = true;
    this.registerOpened = false;
  }

  onOpenWishlistLogin() {
    this.wishlistLoginOpened = true;
    this.registerOpened = false;
  }

  onOpenRegister() {
    this.registerOpened = true;
    this.loginOpened = false;
  }

  closeSearchDropdown() {
    this.showSearchDropdown = false;
  }

  onOpenBasketSide() {
    this.basketViewService.showBasketSide =
      !this.basketViewService.showBasketSide;
    this.disableScrolling();
  }

  closeBasketSide() {
    this.basketViewService.showBasketSide = false;
  }

  onCategorySelected(category: CategoryProductViewModel) {
    this.categories.forEach((c) => (c.selected = false));
    category.selected = true;
    this.filteredProducts =
      this.searchedResult.find((s) => s.name === category.name)?.products ?? [];
    this.showSearchDropdown = false;
    this.router.navigate([`/ps/c/${category.slug}`], {
      queryParams: {
        searchTerm: this.searchTerm,
        categoryId: category.categoryId,
      },
    });
    this.searchTerm = '';
  }

  onMenuToggle(event: MouseEvent) {
    this.dropdownMenuOpened = !this.dropdownMenuOpened;
    if (this.dropdownMenuOpened) {
      this.disableScrolling();
    } else {
      this.enableScrolling();
    }
    event.stopPropagation();
  }

  onSearchIconClicked() {
    this.getProducts();
    this.componentOpen = true;
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  showAllProducts() {
    this.showSearchDropdown = false;
    this.router.navigate([`/ps`], {
      queryParams: { searchTerm: this.searchTerm },
    });
    this.searchTerm = '';
  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      const mainObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          this.mainHeaderVisible = entry.isIntersecting;

          if (!this.mainHeaderVisible) {
            this.stickyVisible = true;
            this.closeComponentsInMainHeader = true;
          } else {
            this.stickyVisible = false;
            this.closeComponentsInMainHeader = false;
          }
        });
      });
      mainObserver.observe(this.mainHeader.nativeElement);
    }
  }

  private disableScrolling() {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  private enableScrolling() {
    this.renderer.removeStyle(document.body, 'overflow');
  }

  private getProducts() {
    this.productsService
      .search(this.searchTerm, 30)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.showLoader = false;
          this.showSearchDropdown = true;
          if (!result || !result.length) {
            this.categories = [];
            this.filteredProducts = [];
            return;
          }

          this.categories = [];
          this.filteredProducts = [];
          this.searchedResult = result;
          for (let i = 0; i < result.length; i++) {
            const element = result[i];
            const categoryVm = new CategoryProductViewModel();
            categoryVm.categoryId = element.categoryId;
            categoryVm.name = element.name;
            categoryVm.selected = false;
            categoryVm.priority = element.priority;
            categoryVm.slug = element.slug;
            this.categories.push(categoryVm);
          }
          this.filteredProducts = this.searchedResult[0].products;
          this.categories[0].selected = true;
        },
        error: (result) => {
          this.showSearchDropdown = true;
          this.categories = [];
          this.filteredProducts = [];
          this.showLoader = false;
        },
      });
  }
}
