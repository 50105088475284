import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductListViewModel } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-identical-product-card',
  templateUrl: './identical-product-card.component.html',
  styleUrls: ['./identical-product-card.component.scss']
})
export class IdenticalProductComponent {
  @Input() product!: ProductListViewModel;

  constructor(private router: Router) {}

  getHref(): string {
    return `/p/${this.product.slug}}`;
  }

  navigateToProduct() {
    this.router.navigate([`/p/${this.product.slug}`]);
  }
}