<section class="featured container mt-3" *ngIf="product">
    <ng-container *ngIf="recommendedProducts$ | async as recommendedProducts">
        <div *ngIf="recommendedProducts && recommendedProducts.length > 0">
            <div class="d-flex section-title">
                <h3 class="similar-title" *ngIf="secondaryCategory$ | async as secondaryCategory">
                    <span>Ovom kupovinom ostvarujete popust od</span>&nbsp;
                    <div class="product-group" *ngIf="secondaryCategory && secondaryCategory.id && secondaryCategory.name && secondaryCategory.priceMargin">
                        <ng-template #popDiscountContent>
                            <div [innerHTML]="popDiscountHtmlContent"></div>
                        </ng-template>
                        <div ngbDropdown [popover]="popDiscountContent" triggers="hover" display="dynamic">
                            {{ secondaryCategory.priceMargin }}% <span>za kupovinu</span>: {{ secondaryCategory.name }}
                        </div>
                    </div>
                </h3>
            </div>
            <div class="bonus-group align-items-center justify-content-start" [ngClass]="isMobile ? 'flex-column' : 'flex-row'">
                <lib-ui-template-product-card [product]="productFromList"></lib-ui-template-product-card>
                <img class="ms-2 me-2" src="../../assets/images/product-card/add-to-cart.svg" style="width: 3rem;">
                <owl-carousel-o [options]="sliderCustomOptions" [ngStyle]="{'width': isMobile ? '100%' : '40%'}">
                    <ng-template carouselSlide *ngFor="let recommededProduct of recommendedProducts" [id]="'recommeded-product-' + recommededProduct.id">
                        <lib-ui-template-product-card [product]="recommededProduct" [useCheckbox]="true" [linkedProductId]="product.id"
                            (checkboxChange)="onCheckboxChanged($event)"></lib-ui-template-product-card>
                    </ng-template>
                </owl-carousel-o>
                <img class="ms-2 me-2" src="../../assets/images/product-card/equals.svg" style="width: 3rem;">
                <div class="purchase-both ms-4" [ngClass]="isMobile ? 'mt-3' : ''">
                    <p class="price-both" *ngIf="selectedProducts && selectedProducts.length">
                        <span>Ukupna cena</span>: <span>{{totalPrice}}</span> {{companyViewService.company?.defaultCurrency}}
                    </p>
                    <div class="btn purchase-items" *ngIf="selectedProducts && selectedProducts.length" (click)="addItemsToBasket()">
                        <span>Dodaj u korpu</span>
                    </div>
                </div>
                <div class="btn purchase-items disabled" [ngClass]="isMobile ? 'mt-3' : ''" *ngIf="!selectedProducts || !selectedProducts.length">
                    <span>Čekirajte barem 1 proizvod</span>
                </div>
            </div>
        </div>
    </ng-container>
</section>
