import { Component, inject, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Response } from 'express';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'lib-ui-template-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class BaseNotFoundComponent implements OnInit {
  private readonly platformId = inject(PLATFORM_ID);

  constructor(@Optional() @Inject('RESPONSE') private response: Response) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId) && this.response) {
      this.response.status(404);
    }
  }
}
