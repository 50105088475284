<div class="checkout-wrapper">
    <div class="container">
        <div class="row d-flex">
            <div class="col-md-9 col-xs-12 col-sm-12">
                <div class="first-column">
                      <div class="checkout-status-div">
                        <div class="pages d-flex align-items-center">
                          <span class="pages-number" [ngClass]="{'active-page-number': isPageActive('first')}">1</span>
                          <span class="pages-text" [ngClass]="{'active-page-text': isPageActive('first')}">Podaci o dostavi</span>
                          <hr>
                          <span class="pages-number" [ngClass]="{'active-page-number': isPageActive('second')}">2</span>
                          <span class="pages-text" [ngClass]="{'active-page-text': isPageActive('second')}">Način plaćanja</span>
                          <hr>
                          <span class="pages-number" [ngClass]="{'active-page-number': isPageActive('third')}">3</span>
                          <span class="pages-text" [ngClass]="{'active-page-text': isPageActive('third')}">Potvrda</span>
                        </div>
                      </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div class="col-md-3 col-xs-12 col-sm-12">
                <div class="second-column" *ngIf="basket$ | async as basket">
                    <lib-ui-template-basket-summary [basket]="basket"></lib-ui-template-basket-summary>
                </div>
            </div>
        </div>
    </div>
</div>
