<div class="card-wrapper" (click)="navigateToDetails()">
    <ng-template #openFrameTemplate>
        <lib-ui-template-frame-image [imageUrl]="frame.imageUrl" [cssClasses]="'img'" [altTitle]="frame.title">
        </lib-ui-template-frame-image>
        <h3 class="title">{{frame.title}}</h3>
        <p class="description">{{frame.shortDescription}}</p>
        <a href class="button"><span>Pročitaj više</span> ></a>
    </ng-template>
    <b2c-frontend-a [relativeHref]="getHref()" [content]="openFrameTemplate">
    </b2c-frontend-a>
</div>