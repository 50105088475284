import { Component, OnInit } from '@angular/core';
import { BasketViewService, BasketViewModel } from '@b2c-frontend/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-ui-template-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BaseBasketComponent implements OnInit {
  basket$?: Observable<BasketViewModel | undefined>;

  constructor(private basketViewService: BasketViewService) { }

  ngOnInit() {
    this.basket$ = this.basketViewService.basketObservable;
    this.basketViewService.getBasket();
  }
}
