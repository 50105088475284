import { Component, OnInit } from '@angular/core';
import { AuthService, OrderStatus, UserViewService, UserViewModel } from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-ui-template-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class BaseAccountComponent implements OnInit {
  user$!: Observable<UserViewModel>;

  constructor(
    private userViewService: UserViewService,
    private authService: AuthService,
    private router: Router) {}

  ngOnInit(): void {
    this.user$ = this.userViewService.messageSource;
    this.userViewService.getUser();
  }

  logout() {
    this.authService.removeToken();
    this.router.navigate(['/home']);
  }

  get OrderStatus() {
    return OrderStatus;
  }
}
