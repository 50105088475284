import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontendCommonModule } from '@b2c-frontend/common';
import { NgxPaginationModule } from 'ngx-pagination';

import { MetaService } from './services/meta.service';
import { AHtmlTagComponent } from './components/a/a.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MetaTagComponent } from './components/meta/meta.component';

@NgModule({
  declarations: [AHtmlTagComponent, PaginationComponent, MetaTagComponent],
  imports: [CommonModule, FrontendCommonModule, NgxPaginationModule],
  exports: [AHtmlTagComponent, PaginationComponent, MetaTagComponent],
  providers: [MetaService]
})
export class SeoModule {}
