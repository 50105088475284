<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-lg-1 col-md-1 col-xs-12 col-sm-12">
                <img *ngIf="!isMobile && visibleImages.length > 6"
                    src="../../../../assets/images/product-details/arow-up.svg"
                    class="prev-arrow" (click)="onPrevClicked()" />
                <div class="product-images-wrapper" #imagesWrapper [style.width]="!isMobile ? '50px' : 'auto'">
                    <div class="product-image-item fade-in-image" *ngFor="let image of visibleImages"
                        [class.selected-image]="image.isSelected">
                        <div class="product-image" *ngIf="image && (image.url || image.safeUrl)">
                            <img *ngIf="!isMobile && image.is3D && !image.isVideo"
                                src="../../assets/images/product-details/3d-image.png" [alt]="image.url"
                                (mouseover)="toggleImageSelection(image)">
                            <img *ngIf="!isMobile && image.isVideo && !image.is3D"
                                src="../../assets/images/product-details/video.png" [alt]="image.url"
                                (mouseover)="toggleImageSelection(image)">
                            <img *ngIf="!image.isVideo && !image.is3D" [src]="image.url" [alt]="image.url"
                                (mouseover)="toggleImageSelection(image)">
                            <iframe
                                *ngIf="isMobile && (image.isVideo || image.is3D)"
                                [src]="image.safeUrl" style="border:0;" loading="lazy"
                                [attr.width]="isMobile ? '380rem' : '90%'"
                                [attr.height]="isMobile ? '350rem' : '420px'"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <img *ngIf="!isMobile && visibleImages.length > 6"
                    src="../../../../assets/images/product-details/arow-down.svg"
                    class="next-arrow"
                    (click)="onNexClicked()" />
            </div>
            <div class="col-lg-11 col-md-11 col-xs-12 col-sm-12 selected-image">
                <div class="image-magnifier-wrapper">
                    <img *ngIf="!selectedImage || !selectedImage.url" class="product-selected-image"
                        src="../../assets/images/product-card/no-image-detail.png" [alt]="productName">
                    <img *ngIf="selectedImage && selectedImage.url && !selectedImage.is3D && !selectedImage.isVideo"
                        class="product-selected-image ms-4" [src]="selectedImage.url" [alt]="productName">
                    <iframe
                        *ngIf="selectedImage && selectedImage.safeUrl && (selectedImage.isVideo || selectedImage.is3D)"
                        [src]="selectedImage.safeUrl" width="90%" height="420px" style="border:0;" loading="lazy"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="spec-icons me-3">
            <div class="spec-icon-wrapper" (click)="print()">
                <span class="d-inline-block" tabindex="0" data-toggle="tooltip" data-placement="top"
                title="Stampaj specifikaciju">
                <img src="../../../assets/images/product-details/print-spec.svg" alt="print-spec" /></span>
            </div>
        </div>
    </div>
</div>