import { Component, HostListener, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { CompanyViewService } from '@b2c-frontend/http';
import { WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'b2c-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private window: any;

  title = 'b2c-frontend';
  showScrollToTopButton = false;
  scrollOffset = 300;

  constructor(
    @Inject(LOCALE_ID) localeId: any,
    private companyViewService: CompanyViewService,
    private windowService: WindowService) {
  }

  ngOnInit(): void {
    this.window = this.windowService.getWindow();
    this.companyViewService.getCompany();
  }

  @HostListener('window:scroll')
  scrollToTop() {
    if (this.window.scrollY > this.scrollOffset) {
      this.showScrollToTopButton = true;
    } else {
      this.showScrollToTopButton = false;
    }
  }

  onScrollToTopClick() {
    this.window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
