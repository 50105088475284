import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'b2c-frontend-cookie-consent',
  templateUrl: './cookie-consent.component.html'
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private cookieConfig: NgcCookieConsentConfig = {
    palette: {
      popup: {
        background: '#000'
      },
      button: {
        background: '#ffc104'
      }
    },
    theme: 'edgeless',
    type: 'opt-out'
  };

  @Input() href!: string;

  constructor(private cookieService: NgcCookieConsentService, @Inject('ORIGIN_URL') protected originUrl: string) {}

  ngOnInit() {
    this.cookieService.statusChange$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (event: NgcStatusChangeEvent) => {
        localStorage.setItem(`${this.originUrl}-coockies`, event.status);
      },
    });

    const hasAnswered = !!localStorage.getItem(`${this.originUrl}-coockies`);

    if (!hasAnswered) {
      const domain = this.originUrl?.replace('http://', '').replace('https://', '');
      this.cookieConfig.enabled = !hasAnswered;
      this.cookieConfig.cookie = {
        domain: domain
      };
      const message = /*$localize*/`Za vreme korišćenja bilo koje stranice, naš veb-sajt može da sačuva određene informacije na korisnikov uređaj, putem kolačića (eng. cookies)`;
      const allowLabel = /*$localize*/`Prihvati`;
      const denyLabel = /*$localize*/`Odbij`;
      const policyText = /*$localize*/`Politika kolačića`;
      const linkLabel = /*$localize*/`Više`;

      this.cookieConfig.content = {
        message: message,
        allow: allowLabel,
        deny: denyLabel,
        policy: policyText,
        link: linkLabel,
        href: this.href,
      };

      this.cookieService.init(this.cookieConfig);                   
    }
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }
}
