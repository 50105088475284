import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appRoutes } from './app.routes';

import { AppComponent } from './app.component';

import { registerLocaleData } from '@angular/common';

import localeSr from '@angular/common/locales/sr';
import { UiTemplateModule } from '@b2c-frontend/ui-template';
registerLocaleData(localeSr, 'sr');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top', scrollOffset: [0, 0] }),
    BrowserAnimationsModule,
    UiTemplateModule
  ],
  providers: [provideClientHydration(withEventReplay())],
  bootstrap: [AppComponent],
})
export class AppModule {}
