<section class="container">
    <div class="row" *ngIf="blogs && blogs.length > 0">
        <div class="col-12">
            <h1>Blogovi</h1>
            <div class="blog-navigation">
                <input placeholder="Pojam za pretragu"  class="main_input blog_input" type="text" (change)="onSearchTermChanged($event)">
            </div>
        </div>
    </div>
    <div class="row" *ngIf="blogs && blogs.length > 0">
        <div class="col-12 mb-4">
            <div class="blogs">
                <lib-ui-template-frame-card *ngFor="let blog of blogs | paginate: paginatorConfig" [frame]="blog" [pagePrefix]="'page/blogs'"></lib-ui-template-frame-card>
            </div>
        </div>
        <div class="col-12">
            <b2c-frontend-pagination
                [config]="paginatorConfig"
                [totalPages]="totalPages"
                (pageChanged)="onPageChanged($event)"></b2c-frontend-pagination>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!blogs || !blogs.length">
        <div class="col-12">Nema rezultata</div>
    </div>
</section>
