import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import {
  BrandResponse,
  CategorySearchedListResponse,
  CategoryGroupSelectResponse,
  ProductDetailsResponse,
  ProductListPaginationResponse,
  ProductListResponse
} from '../http-models';
import { BasketViewService } from './basket-view.service';
import { BrandViewModel, CategoryGroupSelectViewModel, CategorySearchedListViewModel, ProductDetailsViewModel, ProductListPaginationViewModel, ProductListViewModel } from '../view-models';
import { mapper } from '../mappings/mapper';

@Injectable()
export class ProductsService extends BaseClient {
  private pageSize = 10;

  constructor(
    http: HttpClient,
    @Inject('BASE_API_URL') baseApiUrl: string,
    private basketViewService: BasketViewService,
  ) {
    super(http, baseApiUrl);
  }

  getProducts(
    page = 1,
    pageSize = this.pageSize,
    searchTerm = '',
    withPromoPrice = false,
    includeNew = false,
    sorting = '',
    minPrice: number | undefined = undefined,
    maxPrice: number | undefined = undefined,
    categoryIds: string[] = [],
    specifications: string[] = [],
    brands: string[] = [],
    needProductIds = false,
  ): Observable<ProductListPaginationViewModel> {
    let queryParams = `?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&withPromoPrice=${withPromoPrice}&includeNew=${includeNew}&needProductIds=${needProductIds}`;

    if (categoryIds && categoryIds.length > 0) {
      categoryIds.forEach((id) => {
        queryParams += `&categoryIds=${id}`;
      });
    }

    if (specifications && specifications.length > 0) {
      specifications.forEach((value) => {
        queryParams += `&specifications=${value}`;
      });
    }

    if (brands && brands.length > 0) {
      brands.forEach((value) => {
        queryParams += `&brands=${value}`;
      });
    }

    if (sorting) {
      queryParams += `&sorting=${sorting}`;
    }

    if (minPrice && maxPrice) {
      queryParams += `&minPrice=${minPrice}&maxPrice=${maxPrice}`;
    }

    return this.get<ProductListPaginationResponse>(`products${queryParams}`)
               .pipe(map(result => {
                if (!result || !result.items || !result.items.length) {
                  return new ProductListPaginationViewModel();
                }
                const pagination = new ProductListPaginationViewModel();
                pagination.items = mapper.mapArray(result.items, ProductListResponse, ProductListViewModel);
                pagination.pageSize = result.pageSize;
                pagination.productIds = result.productIds;
                pagination.totalCount = result.totalCount;
                pagination.totalPages = result.totalPages;

                return pagination;
              }));
  }

  getProductsWithQueryParams(queryParams: string): Observable<ProductListPaginationViewModel> {
    return this.get<ProductListPaginationResponse>(`products?${queryParams}`).pipe(map(result => {
      if (!result || !result.items || !result.items.length) {
        return new ProductListPaginationViewModel();
      }
      const pagination = new ProductListPaginationViewModel();
      pagination.items = mapper.mapArray(result.items, ProductListResponse, ProductListViewModel);
      pagination.pageSize = result.pageSize;
      pagination.productIds = result.productIds;
      pagination.totalCount = result.totalCount;
      pagination.totalPages = result.totalPages;

      return pagination;
    }));
  }

  search(
    searchTerm = '',
    pageSize = this.pageSize,
  ): Observable<CategorySearchedListViewModel[]> {
    return this.get<CategorySearchedListResponse[]>(
      `products/search?searchTerm=${searchTerm}&pageSize=${pageSize}&q=${Date.now()}`,
    ).pipe(map(result => {
        if (!result || !result.length) {
          return [];
        }
        return mapper.mapArray(result, CategorySearchedListResponse, CategorySearchedListViewModel);
      }));
  }

  getProduct(slug: string): Observable<ProductDetailsViewModel> {
    if (localStorage?.getItem(this.basketViewService.basketIdKey)) {
      return this.get<ProductDetailsResponse>(
        `products/${slug}/get?basketId=${localStorage?.getItem(this.basketViewService.basketIdKey)}&q=${Date.now()}`,
      ).pipe(map(product => {
        if (product) {
          return mapper.map(product, ProductDetailsResponse, ProductDetailsViewModel);
        }
        return product;
      }));
    }
    return this.get<ProductDetailsResponse>(
      `products/${slug}/get?q=${Date.now()}`,
    ).pipe(map(product => {
      if (product) {
        return mapper.map(product, ProductDetailsResponse, ProductDetailsViewModel);
      }
      return product;
    }));
  }

  getBrands(productIds?: string[]): Observable<BrandViewModel[]> {
    return this.post<BrandViewModel[]>(
      `products/get-brands`,
      productIds,
    ).pipe(map(brands => {
      if (!brands || !brands.length) {
        return [];
      }
      return mapper.mapArray(brands, BrandResponse, BrandViewModel);
    }));
  }

  getMostSoldProducts(): Observable<ProductListViewModel[]> {
    return this.get<ProductListResponse[]>(
      `products/most-sold`,
    ).pipe(map(products => {
      if (!products || !products.length) {
        return [];
      }
      return mapper.mapArray(products, ProductListResponse, ProductListViewModel);
    }));
  }

  getIdenticalProducts(productId: string): Observable<ProductListViewModel[]> {
    return this.get<ProductListResponse[]>(
      `products/${productId}/get-identical-products`,
    ).pipe(map(products => {
      if (!products || !products.length) {
        return [];
      }
      return mapper.mapArray(products, ProductListResponse, ProductListViewModel);
    }));
  }

  getRecommendedProducts(
    primaryProductId: string,
    primaryCategoryGroupId?: string,
  ): Observable<ProductListViewModel[]> {
    let queryParams = '';
    if (primaryCategoryGroupId) {
      queryParams += `primaryCategoryGroupId=${primaryCategoryGroupId}&`;
    }

    //if (localStorage?.getItem(this.basketViewService.basketIdKey)) {
    //    queryParams += `basketId=${localStorage?.getItem(this.basketViewService.basketIdKey)}`;
    //}

    return this.get<ProductListResponse[]>(
      `groups/list/${primaryProductId}?${queryParams}`,
    ).pipe(map(products => {
      if (!products || !products.length) {
        return [];
      }
      return mapper.mapArray(products, ProductListResponse, ProductListViewModel);
    }));
  }

  getSecondaryCategory(
    primaryCategoryId?: string,
  ): Observable<CategoryGroupSelectViewModel> {
    return this.get<CategoryGroupSelectResponse>(
      `groups/secondary-category/${primaryCategoryId}`,
    ).pipe(map(group => {
      if (group) {
        return mapper.map(group, CategoryGroupSelectResponse, CategoryGroupSelectViewModel);
      }
      return group;
    }));
  }
}
