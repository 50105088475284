import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService, CategoryListViewModel } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-category-third-card',
  templateUrl: './category-third-card.component.html',
  styleUrls: ['./category-third-card.component.scss']
})
export class BaseCategoryThirdCardComponent {
  @Input() categories: CategoryListViewModel[] = [];

  constructor(
    private router: Router,
    private categoriesService: CategoriesService) {}

  openCategory(category: CategoryListViewModel) {
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], { queryParams: { categoryId: category.id } });
  }

  getCategoryChildHref(category: CategoryListViewModel): string {
    return `/ps/c/${CategoryListViewModel.getSlug(category)}`;
  }

  getCategoryImageUrl(category: CategoryListViewModel): string {
    return this.categoriesService.getImageUrl(category.id);
  }
}