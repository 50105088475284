import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    data: { path: '/' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: '/',
    data: { path: '/' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'home',
    data: { noMeta: true, path: '/home' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'page',
    data: { noMeta: true, path: '/page' },
    loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule)
  },
  {
    path: 'products',
    data: { path: '/products' },
    loadChildren: () => import('./products/products.module').then(mod => mod.ProductsModule)
  },
  {
    path: 'product',
    data: { path: '/product' },
    loadChildren: () => import('./product/product.module').then(mod => mod.ProductModule)
  },
  {
    path: 'basket',
    data: { noMeta: true, path: '/basket' },
    loadChildren: () => import('./basket/basket.module').then(mod => mod.BasketModule)
  },
  {
    path: 'checkout',
    data: { noMeta: true, path: '/checkout' },
    loadChildren: () => import('./checkout/checkout.module').then(mod => mod.CheckoutModule),
  },
  {
    path: 'account',
    data: { noMeta: true, path: '/account' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule),
  },
  { path: '**', redirectTo: 'page/not-found' }
];
