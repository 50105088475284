<div class="containter mt-5">
    <svg version="1.1" x="0px" y="0px" width="206.5px" viewBox="0 0 326.5 592"
        style="enable-background:new 0 0 326.5 592;" xml:space="preserve">
        <defs>
        </defs>
        <g id="shadow_1_">
            <image style="overflow:visible;" width="192" height="103" src="../../../assets/images/page404/shadow.png"
                transform="matrix(1 0 0 1 66.2048 489)">
            </image>
        </g>
        <g id="robot_1_">
            <path class="st0" d="M152.7,89.2h-0.1v21.4h19.6V89.8c6.8-3.4,11.4-10.4,11.4-18.5c0-11.5-9.2-20.8-20.6-20.8
           c-11.4,0-20.6,9.3-20.6,20.8C142.4,79,146.6,85.6,152.7,89.2z" />
            <path class="st0" d="M162.2,466c23.3,0,43.1-14.9,50.6-35.8H111.5C119.1,451.1,138.9,466,162.2,466z" />
            <g>
                <path class="st0" d="M159.1,356c1,0.9,2.4,1.3,4.2,1.3c1.8,0,3.2-0.6,4.5-1.9c1.2-1.2,1.8-2.7,1.8-4.4v-21.4l-12.5,22.4
               C157.4,353.8,158.1,355.1,159.1,356z" />
                <polygon class="st0" points="213.7,338.4 213.7,314.8 194.8,338.4 		" />
                <path class="st0"
                    d="M163.3,313.3c-1.7,0-3.2,0.6-4.5,1.8s-1.9,2.7-1.9,4.4V341l12.5-22.5C168.6,315,166.6,313.3,163.3,313.3z" />
                <path class="st0" d="M283.5,262.5v-42.8c0,0-4.2-78-68.6-102.3c-19.1-7.2-36.6-10.1-51.7-10.7c-15.1,0.5-32.6,3.5-51.7,10.7
               C47.2,141.7,43,219.7,43,219.7v42.8l0,65.7v20.6c-0.1,1.7-0.3,3.3-0.3,5c0,1.8,0.1,3.6,0.3,5.4v0.5h0c2.7,34.3,29.3,61.7,63,65.4
               l0,0.5h57.3h57.3l0-0.5c33.7-3.7,60.3-31.2,63-65.4h0v-0.5c0.1-1.8,0.3-3.6,0.3-5.4c0-1.7-0.1-3.4-0.3-5v-20.6L283.5,262.5z
                M131.8,344.8h-6.3v18.9h-12.6v-18.9H87.6v-6.3l25.2-31.5h12.6v31.5h6.3V344.8z M182.2,351.1c0,3.4-1.2,6.4-3.7,8.9
               s-5.4,3.7-8.9,3.7H157c-3.4,0-6.4-1.2-8.9-3.7c-2.5-2.5-3.7-5.4-3.7-8.9v-31.6c0-3.4,1.2-6.4,3.7-8.9c2.5-2.5,5.4-3.7,8.9-3.7
               h12.6c3.4,0,6.4,1.2,8.9,3.7s3.7,5.4,3.7,8.9V351.1z M232.6,344.8h-6.3v18.9h-12.6v-18.9h-25.2v-6.3l25.2-31.5h12.6v31.5h6.3
               V344.8z M231,242.4c-7.7,2.5-24,1.2-31.4,0c-6.8-1.2-65.8-1.2-72.6,0c-7.4,1.2-23.7,2.5-31.4,0c0,0-22.2-1.9-22.2-30.5
               c0,0,0-31.7,22.2-51c21.5-18.7,53.9-22.8,66.6-23v0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0v0
               c12.7,0.2,45,4.3,66.6,23c22.2,19.3,22.2,51,22.2,51C253.2,240.6,231,242.4,231,242.4z" />
                <polygon class="st0" points="112.9,338.4 112.9,314.8 94,338.4 		" />
            </g>
            <g id="light_1_">
                <image style="overflow:visible;opacity:0.83;" width="142" height="143" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI4AAACPCAYAAAAsoeOtAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
    GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAADKhJREFUeNrsnQuP66oOhU2m//8f
    Tzi6umdLPWxsr2VMQlqQqj4mk+bxdfkBGJHddtttt91222233Xbbbbfddtttt9122+1jW9mX4L+t
    1tq9JqWUuq/Ol4KjQZF2Mb8IrrIh2SB9PTgrwPItIJUNy4boa8C5AJZ2/3VD9FBwkmHJPu/6bRCV
    LwDmrnOsnwxQ+UBgygX/U6+CaFWAyocAUxY615oN0YrwlIdDU5LObabi1CyIVgKoPBCYEVhmn2+d
    DdEq8JQHQRMBZqYJqwPbPB6g8oHAlETwMpWjZgN0JzzlodAgwJRJ8LA3uw4AtKz6lEWhQYEpifCM
    ghOBpmYBdDU85SHQWEBkwDQDHOb9iCrdAk/5EGBGwMrycaJgpAJ0FTyrgMPc3EKCVJKhQW9q9PUj
    4CkPgKYE4IlCZH2OmCgGCubvjD90CTzlRmi8Xz8KBwoOa74YtUEhqMHtQ+ozE57yIGgQeNDnEXgY
    aNhnRp1uhee1QO6oEL4M8jwTntps8/7+z2vrWcD9a/sVYl/P83EItfGgabdFgZkBz6jSaK+17VBl
    ukV1yiLQsOrCvtY+ywBnBBoUpuXgeS2gbig01uvIdkykxaqMZV4QU4OavXZfl5mxcpHalAtg6b1n
    wGGdY0ZhtM+sbVAlQpzndNUpF0ATNUsoGNbnHjgFNFeMmbKgQP4eMWOXw/O6WNVGoEEgyYQnojgo
    NJWMsjzzFTGH94NDmKhRaEYeGfBkQHM6P7I6AA90rzJU5zURGtTPyYLmSASIAYdRmfPf42RVyMrr
    aOBNVZ2ZpooNvSPQHEF4ZoDDQnOyv1EDDspkZajO6yK1QbLCEgTmcMA5bgDndJzed4hGAQqZrFUV
    h8kOZwLDADULnPPtJp5OhDQKkAbNdNW5KwGo+RpCQoM+M/B44TgDTfv6fPv/FprDgKcq1/K2fqww
    OGAkhfY9eX6OB432WoMIgcdTnN5Dg8bKvbxD04OnGiCjWeR01VmldxzN1ViwePAgjjOqOB44LTSl
    A5Ao0LTwlKDDvJ7iJKoN49d40KAw9fbdU0DtRtXG3GjgnG/77AHU3vx3aFCIiwHQVKBeN6uNgDCh
    0CAQWWbLOsaemToaJ/gdnBaYovgw59sxta/b4+mNBxqCJGquXpOg8Do1I10J7Y3vPX5ANfIUpzjQ
    tGpTFHAsYBCAeo4wY7ammS4anIECAYjaCJDgs4BBFchTHURxDsO5ZfarAWSZ+gp0WRQlGlu2kzNb
    bY7A4weEh/FzejmY2sBSHWCKk5Npz7vdr6c8IX8mYq5ek2DJUJsCwvQDvPbg8fwcz78pitoIoBZ/
    9mUpTw8aRHWmmavZznFEbRD1KYbKMPD0ANIill63QSWhEQOa6vxYWNWZChEFTmByXZbaoNFUFB7E
    H6kdxTlJaKxhGQcAD6o6083VK1lVED9HSLVBssY/BjysydJuuOffoObOcrQ99YkMr5hirq7uqyri
    91mhuZwD8HUsiNjIqqc2lfhfMfwkVHG0AV2Xm6tZeRzPRKEZZa/TEoXnx8j1tPv1FCeiNlZ3xaEo
    j6c6yFCKad0PMDjBkX4F8GuyFEeDR1MdrSvC801QtUH7t94d7ApeCwSepfM4hTRRUTPlDdTqwfNj
    vPecZE8tCmmm0IcGkAZFUZzlUPaYcZDv6B0XR9KZHM4RAAh1kDUA3sfWjABzKudWgYChGooioKoM
    qc8VIwA9aEamvxxgqO75OQdoqtpM7qncNGSw12GoDnItbi1CcFXmOBqme8lANs9jqY5mdmonkjmN
    bd9v4iH6gPVT+EH1nsm6zEF+TVYaZKBURG0OiQ25aFXHiqw05/gUvce7jb6ODjRWFwXblxcJu6/L
    HE9aWAyp8zc6v8obn+MlAXuRkQdNL8xGe9CRgf2WoniDvpZIAGYsC+TNOIiMDiyi90dZTjUKzil/
    d0pWxWc5jePuQSTCzcBIN0NoZHVXVDUabaGAHaAp00xVm6ltlcPrZ7LGE7Nz5WWCgxwG77UYGNFo
    K2rWrPyQdJziQ/FV2hzMSR6nCD7Pfon2ulldmAFPo8nDIv7Mh0P5rjZSQnMwSN0eAaCaqh6rgDNa
    8p7NOHt+EyP9At4wdL/ecbKKUu4G5v3Xc5cfE4GrAOpjOeFo+M/8DwIKAhITUIwGKI8EZzaMyJhn
    b1tGGTLL/zMgzVw68qPAYaQ9sk0ZuOEoWLKacnwDOFb9O29bZn/VSO5J4DvZNSA2ODdA5d24Cm4b
    XYwjXDqfBKhK3krEjwnH0RH66K+6AlAg5WKtSKUS+0DKztYAKEsp1etCMLwprNqvqgKgMKPtWLOH
    7DMCCnsMURV7DDijYCAXKFKDrzcQ64+59ip9iuhl2k7Ry7cxhbBFYiVtbwHodQMs2uh87yKK8MWm
    e0MbirFde4ztsZ0OLGfz2UlCHXX0v0px2IvkmQV0iGav3l7bjWB9fw+a01AiBCDkR4GY75Gg4v/O
    3QVjjlmfRTMDUSC0cS69DsgWIpF+1Sx0zPE7LKfyGas6qDm73b+BwfkfhRMGc0XMFVtPuDfEs+35
    fp/LhIw5rgo4GkSsLxTNR0X8xmVMlVUxSkSf/1yDSsMOyRTxp5v0ChqJ4RD/AvCcilrVATWKrte5
    VH0cTT0KAFYx8i0C+C4MNJr5KYDiVMURPjsA/SpARRzmUcf5UWtyekm+dnR+RHUsgFBotCm31g1q
    AfhV4DlBJToV3whxmr8uj+OF5ZFJbKdwZdmsyf4WOJqD/EvCg5bz95xmLeqcmvd5TVQW1M9hlQWB
    qfd9Gpin4BPyquMg/zqmKgqTZ6KGFrdnQ3EKHCKy8ipHaYtYoNB4c5FEiZ40NStNAhBxjlHV+RXO
    fLF5HxGs9z3drL0mmB9UjbRJ85bK9OZtM+XTLLVh54KfgOp4vg8TqjPQTPd/rvRxKmCukChKg0cA
    M2OpTQEUR3OQregKcZxZeGaE6ZeDEw27pUnvV8eHseDxoGHUBnGOe10NLDw1AE3vWFLM1NRytYCf
    g+ZzNICsGyTyd5l7Ebzmnqc2aDUtMczMGVCbaH7Hi6bWX8yVcJTFyNoikZa1mAYDjac2hVAcFp5f
    p2uC7exko6lHZY4zVMdaSbcEoDkFr6zuDd0YUZ6aEJ5fFk3NVBzLv0FVR4Rf9LSC3QSHE00xq8ec
    gL+Dmip2Xc+0bPIlq8eQPeUjaxBEV85lhmAgIX0F913BLoVIMlACavPIPM6o6vTCXxFu4VNv9Tpm
    +m8lVKdKrI8qmtdBZ248No+Dqk77vrdSrrfwaa9eTW/1uqMBhll2yFMezQFGgGEGeqFqk2amwuAY
    5korGV9A1bEuRFtfRlv4tFe3RlMaK39TjBuCglMBeCoJj5YikCyYVlQcKxlomS2vOJEFzeGYKLQI
    ALrmuPccTf6h5mnt5aMTVccr7qyZLg0iDRqkfg0S3iN5HRSiqE+DAOPC9JQF672VTzwz9t6D3Vsx
    tyRAw647jsLjAYOG36J8/6VqMxMcJMJifR4RbLioNPv31kjIBseatBedOqN1L9Q71GYYnMDsBw2a
    DIC0wkfI4mHZ4CDJvNGxx9HpzMs7xxYcmsliy5K10CBFp2UiOEKAk+kIM73kw2qTAk5wZKDmOKPw
    VPm74KOIX3gaKd6IguMpjwTgEeBzFJSpypM2yc6Axyt1htTLGy1NmwFNBjyRwVkieKWNeoXaXBVV
    IWYKVR6vhEoWNAXMakdNF6suKdAsqTiE6owojwheARSpBJqlOFEFEsHN0jA0WWqTDk4QnhkQSQAc
    xsdhwWFUSQagmW6i7k4AspnmSuzb2l/ETDHmSgDViJqjMDTLmypAdbxfuKcGEUVC1W0UnCg8SCZ4
    CJpstZkGzoC/EzFhkggNA84oPKyqLAPNXeAgN6wkgpQBTQY8WaBQI/seB84keFiQGCeYjapQp3kE
    lKFZC7OgmQ4OAA9juhAICgFgpqkSUCWif6cd4ZnQXAJOEjwsFCUIJhsljkA0RWWugGYlcNBffwSO
    EZWRCerD+C9LQnMZOBcCNKIwIz5OxLSkAnMlNJeDMwhPFiRXgTMK07LQ3AIOAQ97k0dN0ig4UXiG
    gLkDmtvASYInU1EyfJwMRVoemNvBuQAg9PyyoqoMuB4BzRLgkPCMKsfs850F0zLALAVOEKBM81OS
    AckAaVlolgMnCM8VZikTKPrmrwTMsuAkABQ5r1mKE4ZlZWiWBicJoDvPceiGrwrMY8BJBGjmeeeN
    5V0cmMeBMxEi69pc0+/zEFgeD84NEG1YPg2cJ0H0dFg+FpzVQPokUL4KnKtB+mRQvhqcEbi+CYrd
    dtttt91222233Xbbbbfddtttt9122+3L2z8CDAAGA9CHeMiJOgAAAABJRU5ErkJggg==" transform="matrix(1 0 0 1 92.2048 0)">
                </image>
                <g>
                    <circle class="st0" cx="163.1" cy="71.5" r="29.5" />
                </g>
            </g>
            <ellipse id="rightEye_1_" class="st0" cx="215.5" cy="206.9" rx="11.2" ry="16" />
            <ellipse id="leftEye_1_" class="st0" cx="108.7" cy="206.9" rx="11.2" ry="16" />
            <circle class="st0" cx="42.8" cy="294.2" r="32.4" />
            <circle class="st0" cx="283.8" cy="294.2" r="32.4" />
            <path id="leftArm_1_" class="st0" d="M32.8,388.4v-95.1c0-5-4-9-9-9H19c-5,0-9,4-9,9v95.1c-6,3.8-10,10.5-10,18.1
           c0,11.8,9.6,21.4,21.4,21.4s21.4-9.6,21.4-21.4C42.8,398.8,38.8,392.2,32.8,388.4z" />
            <path id="rightArm_1_" class="st0" d="M316.6,388.4v-95.1c0-5-4-9-9-9h-4.8c-5,0-9,4-9,9v95.1c-6,3.8-10,10.5-10,18.1
           c0,11.8,9.6,21.4,21.4,21.4s21.4-9.6,21.4-21.4C326.5,398.8,322.6,392.2,316.6,388.4z" />
        </g>
    </svg>
    <div class="container">
        <h1>Uups, izgleda da pojam nije pronađen</h1>
        <p>Pogledajte naše ostale proizvode</p>
    </div>
    <a class="error-button" routerLink="/">Početna strana</a>
</div>