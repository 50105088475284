<h3><span>Lista porudžbenica</span></h3>
<ng-container *ngIf="orders$ | async as orders">
    <div class="table-responsive" *ngIf="orders.items && orders.items.length > 0">
        <table class="cart-table">
            <thead>
            <tr>
                <th><span>Broj</span></th>
                <th><span>Ime</span></th>
                <th><span>Kompanija</span></th>
                <th><span>Status</span></th>
                <th><span>Način plaćanja</span></th>
                <th><span>Cena</span></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let order of orders.items | paginate: paginatorConfig">
                <td>{{ order.number }}</td>
                <td>{{ order.fullName }}</td>
                <td>{{ order.companyName }}</td>
                <td><strong>{{ OrderStatus2LabelMapping[order.status] }}</strong></td>
                <td>{{ PaymentMethod2LabelMapping[order.paymentMethod] }}</td>
                <td>{{ order.totalPrice | number:'1.2-2' }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <b2c-frontend-pagination
                [config]="paginatorConfig"
                [totalPages]="totalPages"
                (pageChanged)="onPageChanged($any($event))"></b2c-frontend-pagination>
        </div>
    </div>
    <p *ngIf="!orders.items || !orders.items.length"><span>Nema podataka</span></p>
</ng-container>
<p *ngIf="(orders$ | async) === null || (orders$ | async) === undefined"><span>Nema podataka</span></p>