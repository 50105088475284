<h3>Osnovni podaci korisnika</h3>
<div [formGroup]="userDataForm" (ngSubmit)="saveUserData()">
    <div class="row mb-3">
        <div class="col-6">
            <input type="text" placeholder="Unesite vaše ime" class="form-control main_input"
                formControlName="firstName" maxlength="100" >
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['firstName'].invalid &&
                (userDataForm.controls['firstName'].dirty ||
                userDataForm.controls['firstName'].touched) &&
                userDataForm.controls['firstName'].errors?.['required']">
                Ime je obavezno
            </small>
        </div>
        <div class="col-6">
            <input type="text" placeholder="Unesite vaše prezime" class="form-control main_input"
                formControlName="lastName" maxlength="100" >
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['lastName'].invalid &&
                (userDataForm.controls['lastName'].dirty ||
                userDataForm.controls['lastName'].touched) &&
                userDataForm.controls['lastName'].errors?.['required']">
                Prezime je obavezno
            </small>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <h5>Podaci o adresi:</h5>
        </div>
        <div class="col-6">
            <ng-select [items]="cities" (change)="onChanged()" (clear)="onCleared()" placeholder="Unesite grad"
                formControlName="city" ></ng-select>
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['city'].invalid &&
                (userDataForm.controls['city'].dirty ||
                userDataForm.controls['city'].touched) &&
                userDataForm.controls['city'].errors?.['required']">
                Grad je obavezan
            </small>
        </div>
        <div class="col-6">
            <input type="text" placeholder="Unesite ulicu" class="form-control main_input" formControlName="street"
                maxlength="255" >
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['street'].invalid &&
                (userDataForm.controls['street'].dirty ||
                userDataForm.controls['street'].touched) &&
                userDataForm.controls['street'].errors?.['required']">
                Ulica je obavezna
            </small>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <ng-select [items]="postalCodes" (change)="onChanged()" (clear)="onCleared()"
                placeholder="Unesite poštanski broj" formControlName="postalCode" ></ng-select>
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['postalCode'].invalid &&
                (userDataForm.controls['postalCode'].dirty ||
                userDataForm.controls['postalCode'].touched) &&
                userDataForm.controls['postalCode'].errors?.['required']">
                Poštanski broj je obavezan
            </small>
        </div>
        <div class="col-3">
            <input type="text" placeholder="Unesite broj ulice" class="form-control main_input"
                formControlName="streetNumber" maxlength="20" >
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['streetNumber'].invalid &&
                (userDataForm.controls['streetNumber'].dirty ||
                userDataForm.controls['streetNumber'].touched) &&
                userDataForm.controls['streetNumber'].errors?.['required']">
                Broj ulice je obavezan
            </small>
        </div>
        <div class="col-3">
            <input type="text" placeholder="Broj apartmana" class="form-control main_input"
                formControlName="apartmentNumber" maxlength="20" >
        </div>
        <div class="col-3">
            <input type="text" placeholder="Broj sprata" class="form-control main_input" formControlName="floor"
                maxlength="20" >
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <h5>Kontakt podaci:</h5>
        </div>
        <div class="col-4">
            <input type="text" placeholder="Unesite fiksni telefon" class="form-control main_input"
                formControlName="phoneNumber" maxlength="30" >
        </div>
        <div class="col-4">
            <input type="text" placeholder="Unesite mobilni telefon" class="form-control main_input"
                formControlName="mobilePhoneNumber" maxlength="30" >
        </div>
        <div class="col-4">
            <input type="email" placeholder="Unesite email" class="form-control main_input" formControlName="email"
                maxlength="64" >
            <small style="float: left;" class="text-danger" *ngIf="userDataForm.controls['email'].invalid &&
                (userDataForm.controls['email'].dirty ||
                userDataForm.controls['email'].touched) &&
                userDataForm.controls['email'].errors?.['email']">
                Email nije validan
            </small>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-6">
            <input type="text" placeholder="Kompanija" class="form-control main_input" formControlName="companyName"
                maxlength="100" >
        </div>
        <div class="col-6">
            <input type="text" placeholder="PIB firme" class="form-control main_input"
                formControlName="companyTaxNumber" maxlength="30" >
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button type="submit" class="main_button" [attr.disabled]="userDataForm.invalid ? 'disabled' : null"
                (click)="saveUserData()">Sačuvaj podatke</button>
        </div>
    </div>
</div>
<h3>Promena lozinke:</h3>
<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
    <div class="row mb-3">
        <div class="col-12">
            <input type="password" placeholder="Unesite trenutnu lozinku" class="form-control main_input"
                formControlName="currentPassword" maxlength="100" >
            <small style="float: left;" class="text-danger" *ngIf="changePasswordForm.controls['currentPassword'].invalid &&
                    (changePasswordForm.controls['currentPassword'].dirty ||
                    changePasswordForm.controls['currentPassword'].touched) &&
                    changePasswordForm.controls['currentPassword'].errors?.['required']">
                Trenutna lozinka je obavezna
            </small>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <input type="password" placeholder="Unesite novu lozinku" class="form-control main_input"
                formControlName="newPassword" maxlength="100" >
            <small style="float: left;" class="text-danger" *ngIf="changePasswordForm.controls['newPassword'].invalid &&
                    (changePasswordForm.controls['newPassword'].dirty ||
                    changePasswordForm.controls['newPassword'].touched) &&
                    changePasswordForm.controls['newPassword'].errors?.['required']">
                Nova lozinka je obavezna
            </small>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <input type="password" placeholder="Potvrdite novu lozinku" class="form-control main_input"
                formControlName="confirmNewPassword" maxlength="100" >
            <small style="float: left;" class="text-danger" *ngIf="changePasswordForm.controls['confirmNewPassword'].invalid &&
                    (changePasswordForm.controls['confirmNewPassword'].dirty ||
                    changePasswordForm.controls['confirmNewPassword'].touched) &&
                    changePasswordForm.controls['confirmNewPassword'].errors?.['notSame']">
                Potvrda lozinke nije ispravna
            </small>
        </div>
    </div>
    <button type="submit" class="main_button" [attr.disabled]="changePasswordForm.invalid ? 'disabled' : null"
       >Sačuvaj podatke</button>
</form>