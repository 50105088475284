import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'b2c-frontend-a',
  templateUrl: './a.component.html',
  styleUrls: ['./a.component.css']
})
export class AHtmlTagComponent implements OnInit {

    private window: any;

    @Input() relativeHref!: string;
    @Input() rel?: string | null;
    @Input() content!: TemplateRef<any>;

    constructor(private windowService: WindowService) {}

    ngOnInit(): void {
        this.window = this.windowService.getWindow();
    }

    getHref() {
      return `${this.window.location.protocol}//${this.window.location.host}${this.relativeHref}`;
    }
}