import { Component, ElementRef, Input, OnInit, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { EmailService } from '@b2c-frontend/http';


@Component({
  selector: 'lib-ui-template-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class BaseTextInputComponent implements OnInit, ControlValueAccessor {

  @ViewChild('input', {static: true}) input!: ElementRef;

  model: any;
  touched = false;
  validationMessage = "";
  @Input() type = 'text';
  @Input() label = "";
  
  constructor(private emailService: EmailService, @Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }

  ngOnInit() {
    const control = this.controlDir.control;
    if (control) {
      const validators = control.validator ? [control.validator] : [];
      const asyncValidators = control.asyncValidator ? [control.asyncValidator] : [];
  
      control.setValidators(validators);
      control.setAsyncValidators(asyncValidators);
      control.updateValueAndValidity();
    }
  }

  onChange($event: any) {
    const value = $event.target.value;
  }

  onTouched($event: any) {
    const value = $event.target.value;
  }

  onInputChanged() {
    if (!this.model) {
      this.validationMessage = this.label + ' ' + /*$localize*/`je obavezno polje`;
      return;
    }

    if (this.type === 'email' && !this.emailService.isEmailValid(this.model)) {
      this.validationMessage = /*$localize*/`Email nije validan`;
      return;
    }

    this.validationMessage = "";
  }

  writeValue(obj: any): void {
    this.touched = true;
    this.input.nativeElement.value = obj || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
