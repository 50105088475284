import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NgxPayPalModule } from 'ngx-paypal';
import { WindowService } from './lib/services/window.service';
import { PayPalComponent } from './lib/components/paypal/paypal.component';
import { DeviceDetectionComponent } from './lib/components/device/device-detection.component';

@NgModule({
  declarations: [PayPalComponent, DeviceDetectionComponent],
  imports: [CommonModule, NgxPayPalModule, RouterLink],
  providers: [WindowService],
  exports: [PayPalComponent, DeviceDetectionComponent]
})
export class FrontendCommonModule {}
