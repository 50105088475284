<div class="row">
  <span class="title">Izaberite način plaćanja</span>

<div class="methods">
  <!-- Method 1 -->
  <div class="select-method-div">
    <div class="button-wrapper" [ngClass]="basketViewService.orderRequest && basketViewService.orderRequest.personType === PersonType.Person ? 'button-border' : null">
      <div class="img_button" (click)="onPersonButtonClicked()">
        <svg class="img-person" width="100px" height="100px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" d="M288 320a224 224 0 1 0 448 0 224 224 0 1 0-448 0zm544 608H160a32 32 0 0 1-32-32v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 0 1-32 32z" />
        </svg>
        <span class="title-s">Kupujem kao fizičko lice</span>
        <span class="description">Plati gotovinski ili karticom</span>
      </div>
    </div>
  </div>

  <!-- Method 2 -->
  <div class="select-method-div">
    <div class="button-wrapper" [ngClass]="basketViewService.orderRequest && basketViewService.orderRequest.personType === PersonType.Company ? 'button-border' : null">
      <div class="img_button" (click)="onCompanyButtonClicked()">
        <svg class="img-company" width="100px" height="100px" viewBox="0 -1 32 32" margin-left="4px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
            <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-206.000000, -206.000000)" fill="#000000">
              <path d="M224,222 C224,221.634 223.895,221.296 223.723,221 L220.277,221 C220.105,221.296 220,221.634 220,222 C220,223.104 220.896,224 222,224 C223.104,224 224,223.104 224,222 L224,222 Z M226,222 C226,224.209 224.209,226 222,226 C219.791,226 218,224.209 218,222 C218,221.652 218.054,221.319 218.141,221 L206,221 L206,232 C206,234.209 207.791,236 210,236 L234,236 C236.209,236 238,234.209 238,232 L238,221 L225.859,221 C225.946,221.319 226,221.652 226,222 L226,222 Z M226,210 L218,210 L218,209 C218,208.448 218.448,208 219,208 L225,208 C225.552,208 226,208.448 226,209 L226,210 L226,210 Z M234,210 L228,210 L228,208 C228,206.896 227.104,206 226,206 L218,206 C216.896,206 216,206.896 216,208 L216,210 L210,210 C207.791,210 206,211.791 206,214 L206,219 L238,219 L238,214 C238,211.791 236.209,210 234,210 L234,210 Z" id="briefcase" sketch:type="MSShapeGroup"> </path>
            </g>
          </g>
        </svg>
        <span class="title-s">Kupujem kao firma</span>
        <span class="description">Plati virmanski ili karticom</span>
      </div>
    </div>
  </div>
</div>
<div class="row-title" *ngIf="basketViewService.orderRequest && basketViewService.orderRequest.personType === PersonType.Person">
  <span class="title">Korisnički podaci</span>
  <div class="row-content">
    <div class="col-6 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Ime'"
        [validationMessage]="'Ime je obavezno'"
        [order]="basketViewService.orderRequest"
        [param1]="'contact'"
        [param2]="'firstName'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Prezime'"
        [validationMessage]="'Prezime je obavezno'"
        [order]="basketViewService.orderRequest"
        [param1]="'contact'"
        [param2]="'lastName'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
      <lib-ui-template-text-input-checkout 
        [type]="'email'"
        [placeholder]="'Email adresa'"
        [validationMessage]="'Email adresa je obavezna'"
        [order]="basketViewService.orderRequest"
        [param1]="'contact'"
        [param2]="'email'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Mobilni telefon'"
        [order]="basketViewService.orderRequest"
        [validationMessage]="'Mobilni telefon je obavezan'"
        [param1]="'contact'"
        [param2]="'mobilePhoneNumber'"
        
        ></lib-ui-template-text-input-checkout>
    </div>

    <div class="col-3 mb-3">
      <lib-ui-template-ng-select-input
        [items]="cities"
        [placeholder]="'Grad'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'city'"
        (changed)="onChanged($event)"
        [validationMessage]="'Grad je obavezan'"
        (cleared)="onCleared($event)"
        >
      </lib-ui-template-ng-select-input>
    </div>
    <div class="col-9 mb-3">
      <lib-ui-template-text-input-checkout
        [placeholder]="'Ulica'"
        [validationMessage]="'Ulica je obavezna'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'street'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-1 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Broj'"
        [validationMessage]="'Broj je obavezan'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'number'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-2 mb-3">
      <lib-ui-template-ng-select-input
        [items]="postalCodes"
        [placeholder]="'PTT broj'"
        [validationMessage]="'PTT broj je obavezan'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'postalCode'"
        (changed)="onChanged($event)"
        (cleared)="onCleared($event)">
      </lib-ui-template-ng-select-input>
    </div>
    <div class="col-3 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Država'"
        [validationMessage]="'Država je obavezna'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [disabled]="true"
        [param2]="'state'"
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-1 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Sprat'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'floor'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-4 mb-3">
      <lib-ui-template-text-input-checkout 
        [placeholder]="'Br. stana'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'apartmentNumber'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
  </div>
</div>
<div class="row-title" *ngIf="basketViewService.orderRequest && basketViewService.orderRequest.personType === PersonType.Company">
  <span class="title">Korisnički podaci</span>
  <div class="row-content">
    <div class="col-12 mb-3">
        <lib-ui-template-text-input-checkout
          [placeholder]="'Firma'"
          [validationMessage]="'Firma je obavezna'"
          [order]="basketViewService.orderRequest"
          [param1]="'contact'"
          [param2]="'companyName'"
          
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-12 mb-3">
        <lib-ui-template-text-input-checkout 
          [placeholder]="'PIB broj'"
          [validationMessage]="'PIB broj je obavezan'"
          [order]="basketViewService.orderRequest"
          [param1]="'contact'"
          [param2]="'companyTaxNumber'"
          
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
        <lib-ui-template-text-input-checkout 
          [placeholder]="'Ime'"
          [validationMessage]="'Ime je obavezno'"
          [order]="basketViewService.orderRequest"
          [param1]="'contact'"
          [param2]="'firstName'"
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
        <lib-ui-template-text-input-checkout
          [placeholder]="'Prezime'"
          [validationMessage]="'Prezime je obavezno'"
          [order]="basketViewService.orderRequest"
          [param1]="'contact'"
          [param2]="'lastName'"
          
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
        <lib-ui-template-text-input-checkout
          [type]="'email'"
          [placeholder]="'Email adresa'"
          [validationMessage]="'Email adresa je obavezna'"
          [order]="basketViewService.orderRequest"
          [param1]="'contact'"
          [param2]="'email'"
          
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-6 mb-3">
        <lib-ui-template-text-input-checkout
          [placeholder]="'Telefon'"
          [order]="basketViewService.orderRequest"
          [validationMessage]="'Telefon je obavezan'"
          [param1]="'contact'"
          [param2]="'phoneNumber'"
          
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-3 mb-3">
      <lib-ui-template-ng-select-input
        [items]="cities"
        [placeholder]="'Grad'"
        [validationMessage]="'Grad je obavezan'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'city'"
        (changed)="onChanged($event)"
        (cleared)="onCleared($event)"
        
        >
      </lib-ui-template-ng-select-input>
    </div>
    <div class="col-9 mb-3">
      <lib-ui-template-text-input-checkout
        [placeholder]="'Ulica'"
        [validationMessage]="'Ulica je obavezna'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [param2]="'street'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-1 mb-3">
        <lib-ui-template-text-input-checkout
          [placeholder]="'Broj'"
          [validationMessage]="'Broj je obavezan'"
          [order]="basketViewService.orderRequest"
          [param1]="'address'"
          [param2]="'number'"
          
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-2 mb-3">
      <lib-ui-template-ng-select-input
          [items]="postalCodes"
          [placeholder]="'PTT broj'"
          [validationMessage]="'PTT broj je obavezan'"
          [order]="basketViewService.orderRequest"
          [param1]="'address'"
          [param2]="'postalCode'"
          (changed)="onChanged($event)"
          (cleared)="onCleared($event)"
          
          >
        </lib-ui-template-ng-select-input>
    </div>
    <div class="col-3 mb-3">
      <lib-ui-template-text-input-checkout
        [placeholder]="'Država'"
        [validationMessage]="'Država je obavezna'"
        [order]="basketViewService.orderRequest"
        [param1]="'address'"
        [disabled]="true"
        [param2]="'state'"
        
        ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-1 mb-3">
        <lib-ui-template-text-input-checkout
          [placeholder]="'Sprat'"
          [order]="basketViewService.orderRequest"
          [param1]="'address'"
          [param2]="'floor'"
          ></lib-ui-template-text-input-checkout>
    </div>
    <div class="col-4 mb-3">
        <lib-ui-template-text-input-checkout
          [placeholder]="'Br. stana'"
          [order]="basketViewService.orderRequest"
          [param1]="'address'"
          [param2]="'apartmentNumber'"
          ></lib-ui-template-text-input-checkout>
    </div>
  </div>
</div>
<div class="row-note" *ngIf="basketViewService.orderRequest && (basketViewService.orderRequest.personType === PersonType.Person || basketViewService.orderRequest.personType === PersonType.Company)">
    <div class="col-12">
        <textarea [placeholder]="'Dodatna napomena'" class="grey_textarea" [(ngModel)]="basketViewService.orderRequest.notes" cols="30" rows="10" ></textarea>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12 d-flex justify-content-end align-items-end">
        <button class="main_button next_step_button"
                (click)="onNextClicked()">Sledeći korak</button>
    </div>
</div>