import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CategoriesService,
  CompanyViewService,
  CategoryListViewModel,
} from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { DeviceDetectionComponent, WindowService } from '@b2c-frontend/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class BaseMenuDropdownComponent extends DeviceDetectionComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  categories: CategoryListViewModel[] = [];
  isTablet = false;

  @Input() dropdownMenuOpened = false;
  @Input() topPosition = '';
  @Output() dropdownOpen = new EventEmitter<boolean>();

  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    public companyViewService: CompanyViewService,
    windowService: WindowService,
  ) {
    super(windowService);
  }

  ngOnInit(): void {
    this.isTablet = this.window.innerWidth <= 1280;
    this.refreshCategories();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onMainCategoryMouseEnter(category: CategoryListViewModel) {
    this.openCategory(category);
  }

  onMainCategoryClicked(category: CategoryListViewModel) {
    if (this.isTablet) {
      this.onMainCategoryMouseEnter(category);
      return;
    }
    if (!category.open) {
      this.openCategory(category);
    } else {
      this.router.navigate(
        [`/ps/c/${CategoryListViewModel.getSlug(category)}`],
        { queryParams: { categoryId: category.id } },
      );
      this.dropdownMenuOpened = false;
      this.dropdownOpen.emit(this.dropdownMenuOpened);
    }
  }

  seeAllMainCategories(category: CategoryListViewModel) {
    this.dropdownMenuOpened = false;
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], {
      queryParams: { categoryId: category.id },
    });
    this.dropdownOpen.emit(this.dropdownMenuOpened);
    this.refreshCategories();
  }

  onSubCategoryClicked(category: CategoryListViewModel) {
    this.dropdownMenuOpened = false;
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], {
      queryParams: { categoryId: category.id },
    });
    this.dropdownOpen.emit(this.dropdownMenuOpened);
    this.refreshCategories();
  }

  onAllCategoriesClicked(category: CategoryListViewModel) {
    this.dropdownMenuOpened = false;
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], {
      queryParams: { categoryId: category.id },
    });
    this.dropdownOpen.emit(this.dropdownMenuOpened);
    this.refreshCategories();
  }

  getHref(category: CategoryListViewModel) {
    return `/ps/c/${CategoryListViewModel.getSlug(category)}`;
  }

  navigateTo(route: string, queryParams?: any) {
    this.router.navigate([route], { queryParams: queryParams });
    this.dropdownMenuOpened = false;
    this.dropdownOpen.emit(this.dropdownMenuOpened);
  }

  private openCategory(category: CategoryListViewModel) {
    this.categories.forEach((c) => (c.open = false));
    category.open = true;
  }

  private refreshCategories() {
    this.categoriesService
    .getMainCategories()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (categories) => {
        this.categories = categories;
        this.categories
          .filter((cat) => cat.children && cat.children.length)
          .forEach((category) => {
            this.categoriesService
              .getCategories(category.id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (children) => {
                  category.children = children;
                },
              });
          });
      },
    });
  }
}
