import { Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, retry, shareReplay } from 'rxjs';

export abstract class BaseClient {
  constructor(
    protected http: HttpClient,
    @Inject('BASE_API_URL') protected baseApiUrl: string
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    /*params: new HttpParams({
      fromObject: {
        culture: String(localStorage.getItem(Constants.DefaultLanguageKey))
      }
    })*/
  };

  protected delete<T>(url: string): Observable<T> {
    if (!this.baseApiUrl) {
      return EMPTY;
    }
    return this.http.delete<T>(`${this.baseApiUrl}/${url}`, this.httpOptions);
  }

  protected get<T>(url: string): Observable<T> {
    if (!this.baseApiUrl) {
      return EMPTY;
    }
    return this.http.get<T>(`${this.baseApiUrl}/${url}`, this.httpOptions);
  }

  protected post<T>(url: string, body: any): Observable<T> {
    if (!this.baseApiUrl) {
      return EMPTY;
    }
    return this.http.post<T>(`${this.baseApiUrl}/${url}`, body, this.httpOptions);
  }

  protected put<T>(url: string, body: any): Observable<T> {
    if (!this.baseApiUrl) {
      return EMPTY;
    }
    return this.http.put<T>(`${this.baseApiUrl}/${url}`, body, this.httpOptions);
  }
}
