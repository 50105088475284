import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, EMPTY, map } from 'rxjs';
import { BaseClient } from '../base-client';
import { CategoryListResponse, CategoryProductResponse } from '../http-models';
import { mapper } from '../mappings/mapper';
import { CategoryListViewModel, CategoryProductViewModel } from '../view-models';

@Injectable()
export class CategoriesService extends BaseClient {

    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getCategories(parentId = ""): Observable<CategoryListViewModel[]> {
        return this.get<CategoryListResponse[]>(`categories?parentId=${parentId}`)
                   .pipe(map(categories => { 
                        if (!categories || !categories.length) {
                            return [];
                        }
                        return mapper.mapArray(categories, CategoryListResponse, CategoryListViewModel);
                    }));
    }

    getCategory(id: string): Observable<CategoryProductViewModel> {
        if (!id) {
            return EMPTY;
        }
        return this.get<CategoryProductResponse>(`categories/${id}/get`)
        .pipe(map(category => {
            if (category) {
                return mapper.map(category, CategoryProductResponse, CategoryProductViewModel);
            }
            return category;
        }));
    }

    getMainCategories(): Observable<CategoryListViewModel[]> {
        return this.get<CategoryListResponse[]>(`categories/get-main`)
        .pipe(map(categories => {
            if (!categories || !categories.length) {
                return [];
            }
            return mapper.mapArray(categories, CategoryListResponse, CategoryListViewModel);
        }));
    }

    getCategoriesByProductIds(productIds?: string[], parentId = ""): Observable<CategoryListViewModel[]> {
        return this.post<CategoryListResponse[]>(`categories/get-by-productIds?parentId=${parentId}`, productIds)
                   .pipe(map(categories => {
                        if (!categories || !categories.length) {
                            return [];
                        }
                        return mapper.mapArray(categories, CategoryListResponse, CategoryListViewModel);
                    }));
    }

    getImageUrl(categoryId: string) {
        return `${this.baseApiUrl}/categories/image/${categoryId}`;
    }
}
