<div class="container">
    <div class="col-md-12">
        <div class="products-row">
            <div class="d-flex justify-content-between gap-1" *ngIf="isMobile">
                <button class="filter-button" (click)="showFilters = !showFilters">
                    <span>Filteri</span>
                    <img src="../../../assets/images/product-card/filter-icon.svg" alt="filter-icon">
                </button>
                <select class="form-select" (change)="onSortingChanged($event)">
                    <option value="" selected>Sortiraj</option>
                    <option [value]="Sorting.PriceUp" [selected]="sorting === '1'">Cena rastuća</option>
                    <option [value]="Sorting.PriceDown" [selected]="sorting === '2'">Cena opadajuća</option>
                </select>
            </div>
            <div class="col-md-3 filters-cont">
                <div *ngIf="showFilters || !isMobile">
                    <div class="filter-views">
                        <div class="reset-filters d-flex justify-content-between" *ngIf="isMobile">
                            <div class="filteri">
                                <span>Filteri proizvoda</span>
                                <img src="../../../assets/images/header/x-button.svg" alt="x-button"
                                    class="close-button" (click)="showFilters = false">
                            </div>
                        </div>
                        <div class="reset-filters d-flex">
                            <span>Vrati unazad</span>
                            <svg class="reset-button" xmlns="http://www.w3.org/2000/svg" height="1.5em"
                                viewBox="0 0 512 512" (click)="back()"
                                style="cursor: pointer; opacity: .8;margin-top:5px">
                                <path
                                    d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
                            </svg>
                        </div>
                        <div class="row custom-row">
                            <div class="price-filter">
                                <div class="price-filter-top d-flex">
                                    <span>Cena</span>
                                </div>
                                <div class="price-curency d-flex justify-content-center">
                                    <div class="price-filter-bottom d-flex justify-content-between align-items-center"
                                        *ngIf="companyViewService.company">
                                        <div class="input-group">
                                            <div class="filter-inputs-wrapper d-flex align-items-center">
                                                <input class="price_input filter-input" [(ngModel)]="minPrice"
                                                    (change)="onMinPriceChanged($event)" />
                                                <span>{{companyViewService.company.defaultCurrency}}</span>
                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div class="filter-inputs-wrapper d-flex align-items-center">
                                            <input class="price_input filter-input" [(ngModel)]="maxPrice"
                                                (change)="onMaxPriceChanged($event)" />
                                            <span>{{companyViewService.company.defaultCurrency}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="d-flex justify-content-start gap-0">
                                        <input type="range" class="form-range" min="0" [max]="maxPriceMinRange"
                                            step="1000" [(ngModel)]="minPrice" (change)="onMinPriceChanged($event)"
                                            style="margin-right: -1.5px;" />
                                        <input type="range" class="form-range" [min]="maxPriceMinRange"
                                            [max]="maxPriceMaxRange" step="1000" [(ngModel)]="maxPrice"
                                            (change)="onMaxPriceChanged($event)" style="margin-left: -1.5px;" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="collapsible-wrap"
                                    *ngIf="categoriesViewModel && categoriesViewModel.length > 0">
                                    <div class="collapsible" (click)="onOpenCategory()">
                                        <span>Kategorija</span>
                                        <img src="../../../assets/images/banner/arrow-down.svg" alt="arrow-down"
                                            [class.arrow-rotate]="categoryOpened">
                                    </div>
                                    <div class="collapsible-row" *ngIf="categoryOpened">
                                        <lib-ui-template-select-category *ngFor="let category of categoriesViewModel"
                                            [category]="category" [currentCategoryIds]="categoryIds"
                                            (categorySelected)="onCategorySelected(category)">
                                        </lib-ui-template-select-category>
                                    </div>
                                </div>
                                <lib-ui-template-products-brands [productIds]="productIds" [brands]="brands"
                                    (brandChanged)="onBrandChanged($event)"></lib-ui-template-products-brands>
                            </div>
                            <div class="specifications-container"
                                *ngIf="specificationsViewModel && specificationsViewModel.length > 1">
                                <div class="collapsible-wrap" *ngFor="let spec of specificationsViewModel">
                                    <div class="collapsible" (click)="onOpenSpec(spec)" *ngIf="!spec.hide">
                                        <span>{{spec.name}}</span>
                                        <img src="../../../assets/images/banner/arrow-down.svg" alt="arrow-down"
                                            [class.arrow-rotate]="spec.isOpened">
                                    </div>
                                    <div class="collapsible-row1" *ngIf="spec.isOpened">
                                        <div class="col-12" *ngFor="let value of spec.values">
                                            <ng-container *ngIf="getSpecificationHref(value)">
                                                <ng-template #specificationTemplate>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox"
                                                            [value]="value.mappingName"
                                                            id="spec-cb-{{value.mappingName}}"
                                                            [attr.checked]="isSpecSelected(value) ? 'checked' : null"
                                                            (click)="onSpecChanged(spec.name, value)">
                                                        <label class="form-check-label"
                                                            for="spec-cb-{{value.mappingName}}"
                                                            (click)="onSpecChanged(spec.name, value)">
                                                            <span>{{value.mappingName}}</span>
                                                        </label>
                                                    </div>
                                                </ng-template>
                                                <b2c-frontend-a [relativeHref]="getSpecificationHref(value)"
                                                    [content]="specificationTemplate">
                                                </b2c-frontend-a>
                                            </ng-container>
                                            <ng-container *ngIf="!getSpecificationHref(value)">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        [value]="value.mappingName" id="spec-cb-{{value.mappingName}}"
                                                        [attr.checked]="isSpecSelected(value) ? 'checked' : null"
                                                        (click)="onSpecChanged(spec.name, value)">
                                                    <label class="form-check-label" for="spec-cb-{{value.mappingName}}"
                                                        (click)="onSpecChanged(spec.name, value)">
                                                        <span>{{value.mappingName}}</span>
                                                    </label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="confirmed-button" *ngIf="isMobile" (click)="showFilters = !showFilters">
                                <span style="font-size:14px;font-weight: 700;">Primeni filtere</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shop-products-div"> <!-- col-md-9 -->
                <div class="action-title">
                    <h1>{{productsTitle}}</h1>
                    <div *ngIf="aboveDescription">
                        <div class="short-desc"></div>
                        <span class="btn-desc">Detaljnije</span>
                        <a class="btn btn-primary" role="button" aria-controls="category-above-description"
                            (click)="toggleAboveDescription()">
                            <img src="../assets/images/banner/chevron-down-solid.svg" alt="arrow-down">
                        </a>
                    </div>
                </div>
                <div *ngIf="aboveDescription" class="mt-3"
                    [ngClass]="showAboveDescription ? 'show-with-transition' : 'hide-with-transition'"
                    id="category-above-description" ngSkipHydration>
                    <div [innerHtml]="aboveDescription" class="card card-body"></div>
                </div>

                <lib-ui-template-category-third-card
                    [categories]="categoriesViewModel"></lib-ui-template-category-third-card>
                <div class="shop-products-div-top"> <!-- row mt-4 mb-3 wider-row -->
                    <div class="col-md-4" *ngIf="!isMobile">
                        <select class="form-select" (change)="onSortingChanged($event)">
                            <option value="" selected>Sortiraj</option>
                            <option [value]="Sorting.PriceUp" [selected]="sorting === '1'">Cena rastuća</option>
                            <option [value]="Sorting.PriceDown" [selected]="sorting === '2'">Cena opadajuća</option>
                        </select>
                    </div>
                    <div class="col-md-8 d-flex align-items-start justify-content-end up-pagination">
                        <span>Ukupno proizvoda: {{totalCount}}</span>
                        <b2c-frontend-pagination [config]="paginatorConfig" [totalPages]="totalPages"
                            (pageChanged)="onPageChanged($event)"></b2c-frontend-pagination>
                    </div>
                </div>
                <div class="shop-products-bottom"> <!-- filter-page__main__content -->
                    <div class="filterpage__main__content__products filterpage__main__content__products--grid"
                        *ngIf="!showLoader && products && products.length > 0">
                        <lib-ui-template-product-card *ngFor="let product of products | paginate: paginatorConfig"
                            [product]="product"></lib-ui-template-product-card>
                    </div>
                    <p *ngIf="!showLoader && (!products || !products.length)">Nema proizvoda</p>
                    <p *ngIf="showLoader"><span class="global-loader"></span></p>
                    <div class="container" *ngIf="belowDescription">
                        <lib-ui-template-html-sanitizer [htmlText]="belowDescription"></lib-ui-template-html-sanitizer>
                    </div>
                </div>
            </div>
        </div>
        <div class="down-pagination">
            <b2c-frontend-pagination [config]="paginatorConfig" [totalPages]="totalPages"
                (pageChanged)="onPageChanged($event)"></b2c-frontend-pagination>
        </div>
    </div>
</div>