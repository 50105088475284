import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SeoModule } from '@b2c-frontend/seo';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseProductsComponent } from './products.component';
import { BaseProductBrandsComponent } from './brands/brands.component';
import { BaseProductBrandComponent } from './brands/brand/brand.component';
import { ProductsRoutingModule } from './product-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BaseSharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BaseProductsComponent,
    BaseProductBrandsComponent,
    BaseProductBrandComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SeoModule,
    NgxPaginationModule,
    RouterLink,
    ProductsRoutingModule,
    BaseSharedModule
  ],
  exports: [
    BaseProductsComponent,
    BaseProductBrandsComponent,
    BaseProductBrandComponent
  ]
})
export class BaseProductsModule { }
