<div class="row" *ngIf="basketViewService.orderRequest && !errorMessage">
    <div class="col-12">
        <lib-ui-template-basket-table [basket]="basket" [canDelete]="false"></lib-ui-template-basket-table>

        <div class="d-flex justify-content-end align-items-center">
            <a [routerLink]="['/checkout/second']">
                <img src="../../../assets/images/checkout/left-arrow.svg" alt="Leva strelica" />
                <span>Nazad na prethodnu stranicu</span>
            </a>
            <button *ngIf="currentPaymentMethod === PaymentMethod.CashOnDelivery || currentPaymentMethod === PaymentMethod.BankTransfer"
                    class="main_button next_step_button" (click)="createOrder()">
                    <span>Potvrdi kupovinu</span>
            </button>
            <ng-container *ngIf="companyViewService.company">
                <b2c-frontend-paypal *ngIf="currentPaymentMethod === PaymentMethod.PayPal"
                            [payPalClientId]="companyViewService.company.payPalClientId"
                            [payPalCurrency]="companyViewService.company.payPalCurrency"
                            [isPayPalEnabled]="companyViewService.company.enablePayPalApi"
                            [basketId]="basketViewService.basketId"
                            (payPalAuthCanceled)="canceled($event)"
                            (payPalAuthError)="onError($event)"
                            (payPalPaymentCompleted)="onPayPalPaymentCompleted($event)">
                </b2c-frontend-paypal>
            </ng-container>
            <ng-container *ngIf="companyViewService.company">
                <form *ngIf="currentPaymentMethod === PaymentMethod.CreditCard || currentPaymentMethod === PaymentMethod.OnlinePaymentCard"
                      name="pay"
                      (ngSubmit)="onSubmit($event)"
                      [action]="companyViewService.company.onlinePaymentUrl"
                      method="POST">
                    <input type="hidden" name="ShopID" [value]="companyViewService.company.onlinePaymentShopId">
                    <input type="hidden" name="ShoppingCartID" [value]="basketViewService.orderRequest.basketId">
                    <input type="hidden" name="Version" value="2.0">
                    <input type="hidden" name="TotalAmount" [value]="basketViewService.orderRequest.totalAmount | number:'1.2-2':'sr'">
                    <input type="hidden" name="Signature" [value]="signature">
                    <input type="hidden" name="ReturnURL" [value]="returnUrl">
                    <input type="hidden" name="CancelURL" [value]="returnUrl">
                    <input type="hidden" name="ReturnErrorURL" [value]="returnUrl">
                    <input type="hidden" name="CustomerFirstName" [value]="basketViewService.orderRequest.contact.firstName">
                    <input type="hidden" name="CustomerLastName" [value]="basketViewService.orderRequest.contact.lastName">
                    <input type="hidden" name="CustomerEmail" [value]="basketViewService.orderRequest.contact.email">
                    <input type="hidden" name="CustomerAddress" [value]="AddressRequest.streetAndNumber(basketViewService.orderRequest.address)">
                    <input type="hidden" name="CustomerCity" [value]="basketViewService.orderRequest.address.city">
                    <input type="hidden" name="CustomerZIP" [value]="basketViewService.orderRequest.address.postalCode">
                    <input type="hidden" name="CustomerCountry" [value]="basketViewService.orderRequest.address.state">
                    <input type="hidden" name="CustomerPhone" [value]="basketViewService.orderRequest.contact.mobilePhoneNumber">
                    <input type="hidden" name="IntCurrency" [value]="companyViewService.company.defaultCurrency">
                    <button class="main_button next_step_button" type="submit">Potvrdi kupovinu</button>
                </form>
            </ng-container>
        </div>
    </div>
</div>

<div class="row" *ngIf="!basketViewService.orderRequest && !errorMessage">
    <div class="col-14">
        <img src="../../../assets/images/checkout/Success.gif" alt="Success gif" />
        <div style="text-align: center; font-size: 20px;">
            <p>Hvala na kupovini</p>
            <p>Vaša porudžbenica je uspešno kreirana</p>
        </div>
    </div>
</div>
