import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseTextInputComponent } from './text-input/text-input.component';
import { BaseCategoryCardComponent } from './category-card/category-card.component';
import { SeoModule } from '@b2c-frontend/seo';
import { RouterLink } from '@angular/router';
import { BaseProductCardComponent } from './product-card/product-card.component';
import { BaseFrameCardComponent } from './frame-card/frame-card.component';
import { BaseFrameImageComponent } from './frame-image/frame-image.component';
import { BaseCategorySecondCardComponent } from './category-second-card/category-second-card.component';
import { BaseCategoryThirdCardComponent } from './category-third-card/category-third-card.component';
import { BaseHtmlSanitizerComponent } from './html-sanitizer/html-sanitizer.component';
import { BaseMenuDropdownComponent } from './menu-dropdown/menu-dropdown.component';
import { BaseSelectCategoryComponent } from './select-category/select-category.component';
import { BaseNgSelectInputComponent } from './ng-select-input/ng-select-input.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    BaseTextInputComponent,
    BaseCategoryCardComponent,
    BaseCategorySecondCardComponent,
    BaseCategoryThirdCardComponent,
    BaseHtmlSanitizerComponent,
    BaseMenuDropdownComponent,
    BaseNgSelectInputComponent,
    BaseSelectCategoryComponent,
    BaseProductCardComponent,
    BaseFrameCardComponent,
    BaseFrameImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeoModule,
    RouterLink,
    NgSelectModule
  ],
  exports: [
    BaseTextInputComponent,
    BaseCategoryCardComponent,
    BaseCategorySecondCardComponent,
    BaseCategoryThirdCardComponent,
    BaseHtmlSanitizerComponent,
    BaseMenuDropdownComponent,
    BaseNgSelectInputComponent,
    BaseSelectCategoryComponent,
    BaseProductCardComponent,
    BaseFrameCardComponent,
    BaseFrameImageComponent
  ]
})
export class BaseSharedModule { }
