import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CategoriesService, CategoryListResponse, CategoryListViewModel, mapper } from '@b2c-frontend/http';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.scss']
})
export class BaseSelectCategoryComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  showChildren = false;
  
  @Input() currentCategoryIds?: string[];
  @Input() category!: CategoryListViewModel;
  @Output() categorySelected = new EventEmitter<CategoryListViewModel>();

  constructor(private categoriesService: CategoriesService, private location: Location, private activatedRoute: ActivatedRoute) { }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  onCategoryChanged(category: CategoryListViewModel) {
    this.categorySelected.emit(category);
  }

  isCategorySelected(categoryId: string) {
    return this.currentCategoryIds?.includes(categoryId);
  }

  showTheChildren(parentId: string) {
    if (this.showChildren) {
      this.showChildren = false;
      return;
    }

    this.categoriesService.getCategories(parentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (items: CategoryListViewModel[]) => {
        if (!items || items.length <= 0) {
          this.showChildren = false;
          return;
        }

        this.category.children = items;
        this.showChildren = true;
      }
    });
  }

  getHref() {
    const path = this.location.path();
    if (path.includes('/ps/b/')) {
      const brand = this.activatedRoute.snapshot.params['brand'];
      this.category.path = `/ps/b/${brand}/${this.category.slug}`;
      return this.category.path;
    }
    this.category.path = `/ps/c/${this.category.slug}`;
    return this.category.path;
  }
}
