<div class="outside-container" (click)="onOutsideClick()">
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center register-wrapper" (click)="$event.stopPropagation()">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="text-center">
                        <div class="d-flex justify-content-between align-items-center">
                            <h1 class="h3 mb-3 font-weight-normal">Registruj se</h1>
                            <img class="close-button" (click)="onOutsideClick()"
                                src="../../../assets/images/header/x-button.svg" alt="Zatvori"  />
                        </div>
                        <p><span>Već imaš nalog</span>? <span (click)="onOpenLogin()">Prijavi se</span></p>
                    </div>

                    <lib-ui-template-text-input formControlName="email" [label]="'Email adresa'"  [type]="'email'">
                    </lib-ui-template-text-input>

                    <lib-ui-template-text-input formControlName="password" [label]="'Lozinka'"  [type]="'password'">
                    </lib-ui-template-text-input>

                    <lib-ui-template-text-input formControlName="confirmPassword" [label]="'Potvrda lozinke'" 
                        [type]="'password'">
                    </lib-ui-template-text-input>

                    <lib-ui-template-text-input formControlName="firstName" [label]="'Ime'"  [type]="'text'">
                    </lib-ui-template-text-input>

                    <lib-ui-template-text-input formControlName="lastName" [label]="'Prezime'"  [type]="'text'">
                    </lib-ui-template-text-input>

                    <lib-ui-template-text-input formControlName="phoneNumber" [label]="'Broj telefona'" 
                        [type]="'text'">
                    </lib-ui-template-text-input>


                    <div class="form-check">
                        <input class="form-check-input" id="terms-and-conditions" type="checkbox"
                            formControlName="termsAndConditions">
                        <label class="form-check-label" for="terms-and-conditions">
                            Slažem se sa uslovima korišćenja
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" id="promo-info" type="checkbox"
                            formControlName="subscribeToPromo">
                        <label class="form-check-label" for="promo-info">
                            Želim da primam obaveštenja o najnovijim akcijama, promocijama
                        </label>
                    </div>
                    <br />
                    <button class="main_button submit-button" type="submit">Registruj se</button>
                </form>
            </div>
        </div>
    </div>
</div>