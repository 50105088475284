import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import { SpecificationListResponse, SpecificationValueListResponse } from '../http-models';
import { mapper } from '../mappings/mapper';
import { SpecificationListViewModel, SpecificationValueListViewModel } from '../view-models';

@Injectable()
export class SpecificationsService extends BaseClient {

    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getSpecifications(categoryId?: string, productIds: string[] = []): Observable<SpecificationListViewModel[]> {
        return this.post<SpecificationListResponse[]>(`specifications?categoryId=${categoryId}`, productIds)
                   .pipe(map(specs => {
                        if (!specs || !specs.length) {
                            return [];
                        }
                        return mapper.mapArray(specs, SpecificationListResponse, SpecificationListViewModel);
                    }));
    }

    getValues(name: string, categoryId: string, originalNames: string, productIds: string[] = []): Observable<SpecificationValueListViewModel[]> {
        return this.post<SpecificationValueListResponse[]>(`specifications/${name}/values?categoryId=${categoryId}&originalNames=${originalNames}`, productIds)
                   .pipe(map(values => {
                        if (!values || !values.length) {
                            return [];
                        }
                        return mapper.mapArray(values, SpecificationValueListResponse, SpecificationValueListViewModel);
                    }));
    }
}
