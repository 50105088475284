<div class="container" *ngIf="!showLoader && ((product$ | async) === null || (product$ | async) === undefined)">
  <h4>Ovaj proizvod trenutno nije dostupan</h4>
</div>

<div class="container" *ngIf="showLoader">
  <h4>
    <span class="global-loader"></span>
  </h4>
</div>

<div class="container" *ngIf="(product$ | async) !== null && (product$ | async) !== undefined">
  <ng-container *ngIf="product$ | async as product">
    <div class="col-md-12 col-xs-12 col-sm-12 product-info">
      <lib-ui-template-sticker-frame [stickerFrames]="product.stickerFrames"></lib-ui-template-sticker-frame>
      <lib-ui-template-product-images class="images-class" [images]="images" [productName]="product.name"
        [productId]="product.id"></lib-ui-template-product-images>

      <!-- SECOND -->
      <div class="product-info justify-content-between col-6">
        <div class="col-lg-7 col-md-12 col-xs-12 col-sm-12">
          <div class="gift"></div>
          <div class="rate-wrapper">
            <div class="rate-left">
              <h6 *ngIf="product.isNew" class="yellow">Novo</h6>
              <h6 *ngIf="!product.isNew" class="black">Preporuka</h6>
            </div>
            <div class="rate-right" *ngIf="authService.isAuthenticated()">
              <img class="favorite-img" src="../../../assets/images/header/hearth-black.svg" alt="hearth-black"
                (click)="addToWish()" />
            </div>
          </div>

          <!-- Data -->
          <h1 class="single-product-title">{{ product.name }}</h1>
          <div class="product-price-info">
            <div class="product-id">
              <h5>ID: {{ product.productId }}</h5>
            </div>
            <div class="product-ident">
              <h5>Ean: {{ product.barCode }}</h5>
              <h5>Brend: {{ product.supplierName }}</h5>
            </div>
            <hr />
            <div class="product-price">
              <div class="price-container">
                <div class="rate-left-red">
                  <h6 class="red" *ngIf="product.isPromoPriceActive && product.discountInPercentage">
                    <span>Uštedi</span> {{ product.discountInPercentage }}%
                  </h6>
                  <div class="top-price"
                    [ngClass]="{ 'top-price-border': product.isPromoPriceActive && product.promoPrice && product.price !== product.promoPrice }">
                    {{ product.price | number: '1.2-2' }} <span>{{ companyViewService.company?.defaultCurrency }}</span>
                  </div>
                </div>
                <div class="product-price-left">
                  <div class="regular-price"
                    *ngIf="product.isPromoPriceActive && product.promoPrice && product.price !== product.promoPrice">
                    {{ product.promoPrice | number: '1.2-2' }} <span> {{ companyViewService.company?.defaultCurrency }}</span>
                  </div>
                  <div class="save-price" *ngIf="product.saving">
                    <span>Ušteda {{ product.saving }} {{ companyViewService.company?.defaultCurrency }}</span>
                  </div>
                  <div class="description-price">
                    Online cena prilikom poručivanja
                  </div>
                </div>
              </div>
              <div class="product-price-right" *ngIf="product.installmentsPrice">
                <div class="text-price">Kupovina na rate</div>
                <div class="rate-price">
                  {{ product.installmentsPrice | number: '1.2-2' }} <span>{{ companyViewService.company?.defaultCurrency }}</span>
                </div>
                <div class="text-price">Iznos mesečne rate</div>
              </div>
            </div>

            <p class="time-span"
              *ngIf="product.isPromoPriceActive && product.promoPriceStartDate && product.promoPriceEndDate">
              <span>Akcijska ponuda važi od</span>&nbsp;
              <strong>{{ product.promoPriceStartDate | date: 'dd.MM.yyyy' : 'UTC' }}</strong>&nbsp;
              <span>do</span>&nbsp;
              <strong>{{ product.promoPriceEndDate | date: 'dd.MM.yyyy' : 'UTC' }}</strong>
            </p>
            <hr />
            <div class="identic-products" *ngIf="(identicalProducts$ | async) as identicalProducts">
              <lib-ui-template-identical-product-card *ngFor="let product of identicalProducts"
                [product]="product"></lib-ui-template-identical-product-card>
            </div>

            <!-- desc -->
            <div class="description-container" *ngIf="product.description" #descriptionContainer>
              <h5>Opis proizvoda:</h5>
              <ng-container *ngIf="descriptionContainer.offsetHeight > 250; else plainText">
                <lib-ui-template-html-sanitizer [htmlText]="product.description"></lib-ui-template-html-sanitizer>
              </ng-container>
              <ng-template #plainText>
                <div [innerHTML]="product.description"></div>
              </ng-template>
            </div>
          </div>
          <!-- button -->
        </div>

        <div class="col-lg-4 col-md-12 col-xs-12 col-sm-12 last-column">
          <div class="top-price" 
          *ngIf="!product.isPromoPriceActive || !product.promoPrice || product.price === product.promoPrice"
          [ngClass]="{ 'top-price-border': product.isPromoPriceActive && product.promoPrice && product.price !== product.promoPrice }">
          {{ product.price | number: '1.2-2' }} <span>{{ companyViewService.company?.defaultCurrency }}</span>
        </div>
        <div class="regular-price"
          *ngIf="product.isPromoPriceActive && product.promoPrice && product.price !== product.promoPrice">
          {{ product.promoPrice | number: '1.2-2' }} <span>{{ companyViewService.company?.defaultCurrency }}</span>
        </div>  

          <div class="product-id-right">
            <p *ngIf="product.availability && product.availability !== '0' && !product.transportCost">
              Besplatna dostava
              <a [routerLink]="['/nacin-isporuke']">
                <span tabindex="0" data-toggle="tooltip" data-placement="top" title="Više o načinima isporuke">
                  <br><span>Rok isporuke</span>: 2-5 <span>radnih dana</span>
                </span>
              </a>
            </p>
            <div *ngIf="product.availability && product.availability !== '0'"></div>
            <p *ngIf="product.availability && product.availability !== '0'">Na stanju</p>
            <p *ngIf="!product.availability || product.availability === '0'" style="color: red">Trenutno nema na stanju
            </p>
          </div>

          <div class="col-6" *ngIf="product.availability && product.availability !== '0'" class="add-to-card-wrapper">
            <div class="quantity-wrapper">
              Količina:
              <p (click)="decrementQuantity()">-</p>
              <p>{{ quantity }}</p>
              <p (click)="incrementQuantity()">+</p>
            </div>
            <div class="purchase-wrapper" (click)="addItemToBasket()">Dodaj u korpu</div>
            <div class="purchase-button" (click)="finishOrder()">Završi kupovinu</div>
            <div class="refund-text"></div>
            <div class="col-md-12 col-xs-12 col-sm-12 declaration">
              <div class="return">
                <span>Povrat</span>:
                <div class="return-info">
                  <span>Ispunjava uslove za povrat</span>
                </div>
              </div>
              <div class="payment mt-3">
                <a [routerLink]="['/nacin-placanja']">
                  <span tabindex="0" data-toggle="tooltip" data-placement="top"
                    title="Više o načinima plaćanja">Plaćanje</span>
                </a>
                <div class="payment-info ms-4">
                  <span>Gotovinski pouzećem</span><br>
                  <span>Platnom karticom</span><br>
                  <span>Virmansko preko računa</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lib-ui-template-bonus-products *ngIf="(product$ | async) as product"
      [product]="product"></lib-ui-template-bonus-products>
    <div class="url-open" *ngIf="product && product.url && product.url.length > 0">
      <div (click)="onOpenUrl()" class="url-title">
        <span>Opis proizvoda</span>
        <img
          [src]="openUrl ? '../../../assets/images/product-details/minus-icon.svg' : '../../../assets/images/product-details/plus-icon.svg'"
          alt="toggle url" />
      </div>
      <div class="url" *ngIf="openUrl">
        <iframe [src]="safeUrl" width="100%" height="1000px" style="border: 0" loading="lazy"></iframe>
      </div>
    </div>
    <div class="product-specs">
      <h3 class="col-md-8 similar-title">Informacije o proizvodu</h3>
      <div class="row">
        <div class="col-md-9">
          <div class="specs-table">
            <div (click)="onOpenSpecifications()" class="specs-table-title">
              <span>Specifikacije</span>
              <img
                [src]="openSpecifications ? '../../../assets/images/product-details/minus-icon.svg' : '../../../assets/images/product-details/plus-icon.svg'"
                alt="toggle specifications" />
            </div>
            <div class="tr-table"
              *ngIf="product && product.groupedSpecifications && product.groupedSpecifications.length">
              <div class="table-responsive" *ngIf="openSpecifications">
                <table class="table" *ngFor="let grouped of product.groupedSpecifications">
                  <thead>
                    <tr>
                      <th>{{ grouped.name }}</th>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let spec of grouped.specifications">
                      <td class="td-background">{{ spec.name }}</td>
                      <td>
                        <div [innerHtml]="sanitizer.bypassSecurityTrustHtml(spec.value ?? '')"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p>
            U cilju pružanja što preciznijih i tačnih informacija, trudimo se da sve specifikacije, opisi i informacije o proizvodima na našem sajtu budu tačni i ažurni. <br>
            Iako činimo sve napore da redovno ažuriramo podatke, ne možemo garantovati apsolutnu tačnost, potpunost ili ažurnost svih informacija u vezi sa svakim proizvodom.
            Sve specifikacije, funkcionalnosti i karakteristike proizvoda prikazane na ovom sajtu služe kao opisi proizvoda u svrhu informisanja kupaca. </p>
        </div>

        <div class="col-md-3 similar-title">
          <div class="decl-table">
            <div (click)="onOpenDeclarations()" class="specs-table-title">
              <span>Deklaracije</span>
              <img
                [src]="openDeclarations ? '../../../assets/images/product-details/minus-icon.svg' : '../../../assets/images/product-details/plus-icon.svg'"
                alt="toggle declarations" />
            </div>
            <div class="tr-table">
              <div class="table-responsive" *ngIf="openDeclarations">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Naziv robe:</td>
                      <td>{{ product.name }}</td>
                    </tr>
                    <tr>
                      <td>Vrsta robe:</td>
                      <td *ngIf="product.categories && product.categories.length">
                        {{ product.categories[product.categories.length - 1].name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Uvoznik:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Zemlja porekla:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Prava potrošača:</td>
                      <td>Zagarantovana prava</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="featured container mt-3"
      *ngIf="(similarProducts$ | async) !== null && (similarProducts$ | async) !== undefined">
      <div class="row">
        <div class="d-flex section-title">
          <h3 class="col-md-12 similar-title">Slični proizvodi</h3>
        </div>
        <div class="d-flex action-offer justify-content-center action-swiper">
          <owl-carousel-o [options]="sliderCustomOptions">
            <ng-template carouselSlide *ngFor="let similarProduct of similarProducts$ | async" [id]="'similar-pruduct-' + similarProduct.id">
              <lib-ui-template-product-card [product]="similarProduct"></lib-ui-template-product-card>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </section>
  </ng-container>
</div>