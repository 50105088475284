import { Component, Input } from '@angular/core';
import { BasketItemViewModel, BasketViewService, BasketViewModel } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-basket-table',
  templateUrl: './basket-table.component.html',
  styleUrls: ['./basket-table.component.scss']
})
export class BaseBasketTableComponent {

  @Input() basket?: BasketViewModel;
  @Input() canDelete = false;

  constructor(private basketViewService: BasketViewService) {}

  decreaseQuantity(product: BasketItemViewModel) {
    if (product.quantity > 1) {
      product.quantity--;
      this.basketViewService.addItem({
        productId: product.productId,
        productName: product.productName,
        productUniqueId: product.productUniqueId,
        price: product.price,
        isPromoPriceActive: product.isPromoPriceActive,
        promoPrice: product.promoPrice,
        productPictureUrl: product.productPictureUrl,
        availability: "1",
        linkedProductId: product.linkedProductId,
        quantity: product.quantity
      });
    }
  }

  increaseQuantity(product: BasketItemViewModel) {
    this.basketViewService.addItem({
      productId: product.productId,
      productName: product.productName,
      productUniqueId: product.productUniqueId,
      price: product.price,
      isPromoPriceActive: product.isPromoPriceActive,
      promoPrice: product.promoPrice,
      productPictureUrl: product.productPictureUrl,
      availability: "1",
      linkedProductId: product.linkedProductId,
      quantity: ++product.quantity
    });
  }

  removeRow(product: BasketItemViewModel) {
    if (!product) {
      return;
    }
    this.basketViewService.deleteItem(product.productId);
  }
}
