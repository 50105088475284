import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BasketViewService, CompanyViewService, BasketItemViewModel, BasketViewModel } from '@b2c-frontend/http';
import { WindowService } from '@b2c-frontend/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-ui-template-basket-side-view',
  templateUrl: './basket-side-view.component.html',
  styleUrls: ['./basket-side-view.component.scss']
})
export class BaseBasketSideViewComponent implements OnInit {
  private window: any;

  basket$?: Observable<BasketViewModel | undefined>;
  isSlideIn = false;
  isMobile = false;
  
  @Input() marginRight = '0';
  @Input() opened = false;
  @Output() basketSideClosed = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private basketViewService: BasketViewService,
    private windowService: WindowService,
    public companyViewService: CompanyViewService) {}

  ngOnInit(): void {
    this.window = this.windowService.getWindow();
    this.basket$ = this.basketViewService.basketObservable;
    this.isMobile = this.window.innerWidth < 800;
    this.basketViewService.getBasket();
  }

  onOpenBasketPage() {
    this.router.navigate(['/basket']);
    this.basketViewService.showBasketSide = false;
  }

  closeBasketSide() {
    this.basketSideClosed.emit(true);
  }

  onOpenCheckoutPage() {
    this.router.navigate(['/checkout/first']);
    this.basketViewService.showBasketSide = false;
  }

  onRemoveProductFromBasket(product: BasketItemViewModel) {
    if (!product) {
      return;
    }

    this.basketViewService.deleteItem(product.productId);
  }
}
