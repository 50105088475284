import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ImageService {

  constructor(@Inject('BASE_API_URL') private baseApiUrl: string) {}

  getImageUrl(fileName?: string) {
    if (!fileName) {
      return "";
    }
    return `${this.baseApiUrl}/file/${fileName}`;
  }

  checkImage(url: string, onfulfilled: any, onrejected: any): void {
    if (!url) {
        return;
    }
    this.testImage(url).then(onfulfilled, onrejected);
  }

  private testImage(url: string, timeoutT?: number) {
    return new Promise((onfulfilled, onrejected) => {
      const timeout = timeoutT || 5000;
      const img = new Image();
      let timer: any = undefined;
      img.onerror = img.onabort = () => {
        if (timer) {
            clearTimeout(timer);
        }
        return onrejected("error");
      };
      img.onload = () => {
        if (timer) {
            clearTimeout(timer);
        }
        return onfulfilled("success");
      };
      timer = setTimeout(() => {
        return onrejected("timeout");
      }, timeout);
      img.src = url;
    });
  }
}
