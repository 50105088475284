import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import { enableProdMode, LOCALE_ID } from '@angular/core';
import { environment } from './environments/environment';

if (process.env['NODE_ENV'] === 'production') {
  enableProdMode();
}

export function getBaseApiUrl() {
  return !process.env['NX_API_URL'] ? environment.apiUrl : process.env['NX_API_URL'];
}

export function getOriginUrl() {
  return !process.env['NX_ORIGIN_URL'] ? environment.originUrl : process.env['NX_ORIGIN_URL'];
}

export function getLocaleId() {
  return !process.env['NX_LOCALE_ID'] ? environment.defaultLanguage : process.env['NX_LOCALE_ID'];
}

const providers = [
  { provide: 'BASE_API_URL', useFactory: getBaseApiUrl, deps: [] },
  { provide: 'ORIGIN_URL', useFactory: getOriginUrl, deps: [] },
  { provide: LOCALE_ID, useFactory: getLocaleId, deps: [] }
];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
