import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import { CategoryListResponse, FrameResponse, PaginationResponse, ProductListResponse } from '../http-models';
import { FrameType } from '../enums';
import { CategoryListViewModel, FrameViewModel, ProductListViewModel } from '../view-models';
import { mapper } from '../mappings/mapper';

@Injectable()
export class FramesService extends BaseClient {

    private pageSize = 10;

    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getFrames(page = 1, pageSize = this.pageSize, searchTerm = "", frameType = FrameType.Slider): Observable<PaginationResponse<FrameViewModel>> {
        return this.get<PaginationResponse<FrameResponse>>(`frames?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&frameType=${frameType}`)
                   .pipe(map(result => {
                        if (!result || !result.items || !result.items.length) {
                            return new PaginationResponse<FrameViewModel>();
                        }
                        const pagination = new PaginationResponse<FrameViewModel>();
                        pagination.items = mapper.mapArray(result.items ?? [], FrameResponse, FrameViewModel);
                        pagination.pageSize = result.pageSize;
                        pagination.totalCount = result.totalCount;
                        pagination.totalPages = result.totalPages;

                        return pagination;
                    }));
    }

    getByCode(code: string): Observable<FrameViewModel> {
        return this.get<FrameResponse>(`frames/${code}/by-code`)
                   .pipe(map(frame => {
                        if (frame) {
                            return mapper.map(frame, FrameResponse, FrameViewModel);
                        }
                        return frame;
                    }));
    }

    getById(id: string): Observable<FrameViewModel> {
        return this.get<FrameResponse>(`frames/${id}/get`)
                .pipe(map(frame => {
                    if (frame) {
                        return mapper.map(frame, FrameResponse, FrameViewModel);
                    }
                    return frame;
                }));
    }

    getProducts(frameId: string, pageSize = this.pageSize): Observable<ProductListViewModel[]> {
        return this.get<ProductListResponse[]>(`frames/${frameId}/products?pageSize=${pageSize}`)
                   .pipe(map(products => {
                        if (!products || !products.length) {
                            return [];
                        }
                        return mapper.mapArray(products, ProductListResponse, ProductListViewModel);
                    }));
    }

    getCategories(frameId: string, pageSize = this.pageSize): Observable<CategoryListViewModel[]> {
        return this.get<CategoryListResponse[]>(`frames/${frameId}/categories?pageSize=${pageSize}`)
                   .pipe(map(categories => {
                        if (!categories || !categories.length) {
                            return [];
                        }
                        return mapper.mapArray(categories, CategoryListResponse, CategoryListViewModel);
                    }));
    }

    getImageUrl(frameId: string) {
        return `${this.baseApiUrl}/frames/image/${frameId}`;
    }
}
