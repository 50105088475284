import { NgModule } from '@angular/core';
import { BaseHomeComponent } from './home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { BaseSharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { SeoModule } from '@b2c-frontend/seo';

@NgModule({
  declarations: [
    BaseHomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CarouselModule,
    BaseSharedModule,
    HomeRoutingModule,
    SeoModule
  ],
  exports: [
    BaseHomeComponent
  ]
})
export class BaseHomeModule { }
