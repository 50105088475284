import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UsersService } from './users.service';
import { UserViewModel } from '../view-models';

@Injectable()
export class UserViewService implements OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    public messageSource = new Subject<UserViewModel>();

    constructor(private usersService: UsersService) {
        
    }

    getUser() {
        this.usersService.getUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: user => {
                this.messageSource.next(user);
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
