<div class="header-search-wrapper" [ngClass]="{'z-index-999': showSearchDropdown}">
  <div class="header-search" [ngClass]="{'no-hover': showSearchDropdown}">
    <input placeholder="Pretraži proizvode" type="text" [(ngModel)]="searchTerm" (keyup)="onSearchTermChanged($event)"
      (keyup.enter)="showAllProducts()" />
    <div class="search-loader-delete-icons">
      <div *ngIf="!showLoader && showSearchDropdown" class="search-close-button" tabindex="0" data-toggle="tooltip" data-placement="top"
        title="Poništi pretragu" (click)="closeSearchDropdown()">
        <img src="../../../assets/images/header/x-button.svg" class="search-close-button-scroll" alt="x-button">
        <img src="../../../assets/images/header/x-button-red.svg" class="search-close-button-scroll-red" alt="x-button-red">
    </div>
      <span *ngIf="showLoader" class="search-loader"></span>
      <div class="search-icon">
        <svg (click)="onSearchIconClicked()" width="18" height="18" viewBox="0 0 34 34"
          xmlns="http://www.w3.org/2000/svg">
          <path style="fill: #222;"
            d="M27.6263 13.8105C27.6263 16.8581 26.6368 19.6733 24.97 21.9573L33.3774 30.3698C34.2075 31.1997 34.2075 32.5476 33.3774 33.3775C32.5473 34.2075 31.1992 34.2075 30.3691 33.3775L21.9616 24.9651C19.6771 26.6383 16.8614 27.621 13.8132 27.621C6.18272 27.621 0 21.4394 0 13.8105C0 6.18152 6.18272 0 13.8132 0C21.4436 0 27.6263 6.18152 27.6263 13.8105ZM13.8132 23.3716C15.069 23.3716 16.3125 23.1243 17.4728 22.6438C18.633 22.1633 19.6872 21.459 20.5752 20.5712C21.4632 19.6834 22.1676 18.6294 22.6482 17.4694C23.1288 16.3093 23.3761 15.0661 23.3761 13.8105C23.3761 12.5549 23.1288 11.3116 22.6482 10.1516C22.1676 8.9916 21.4632 7.93759 20.5752 7.04976C19.6872 6.16193 18.633 5.45766 17.4728 4.97717C16.3125 4.49668 15.069 4.24938 13.8132 4.24938C12.5573 4.24938 11.3138 4.49668 10.1536 4.97717C8.99335 5.45766 7.93914 6.16193 7.05114 7.04976C6.16313 7.93759 5.45873 8.9916 4.97814 10.1516C4.49756 11.3116 4.25021 12.5549 4.25021 13.8105C4.25021 15.0661 4.49756 16.3093 4.97814 17.4694C5.45873 18.6294 6.16313 19.6834 7.05114 20.5712C7.93914 21.459 8.99335 22.1633 10.1536 22.6438C11.3138 23.1243 12.5573 23.3716 13.8132 23.3716Z"
            fill="white" />
        </svg>
      </div>
    </div>
  </div>
  <div class="search-dropdown" *ngIf="showSearchDropdown">
    <ng-container *ngIf="filteredProducts.length">
      <div class="search-categories">
        <div class="title-category-button d-flex justify-content-between">
          <h3 class="dd-title">Kategorije</h3>
        </div>
        <ul class="search-droprown-ul">
          <li class="search-droprown-li" [class.search-droprown-li-selected]="category.selected"
            *ngFor="let category of categories" (click)="onCategorySelected(category)">
            <span *ngIf="category.name">{{category.name}}</span>
            <span *ngIf="!category.name">Bez kategorije</span>
          </li>
        </ul>
        <button class="search-button" (click)="showAllProducts()">Prikaži</button>
      </div>
      <div class="search-products">
        <div class="title-close-button d-flex justify-content-between">
          <h3 class="product-title">Proizvodi</h3>
        </div>
        <div class="search-dropdown-products">
          <div *ngFor="let product of filteredProducts">
            <lib-ui-template-product-card (click)="closeSearchDropdown()" [product]="product" [showAddToBasket]="false"></lib-ui-template-product-card>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>