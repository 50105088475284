import { Component, Input } from '@angular/core';
import { FramesService, FrameViewModel } from '@b2c-frontend/http';
@Component({
  selector: 'lib-ui-template-sticker-frame',
  templateUrl: './sticker-frame.component.html',
  styleUrls: ['./sticker-frame.component.scss']
})

export class StickerFrameComponent {

    @Input() stickerFrames?: FrameViewModel[] = [];
    zIndex = 1;

    constructor(
      private framesService: FramesService) {}

    getStickerFrameImageUrl(frameId: string) {
        return this.framesService.getImageUrl(frameId);
    }
}