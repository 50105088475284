<div class="first-section-footer">
    <div class="container">
        <div class="row" *ngIf="companyViewService.company">
            <div class="d-flex align-items-center col-md-3 col-xs-12 col-sm-12 first-section-footer-item">
                <div class="img-wrapper">
                    <img src="../../assets/images/footer/track.svg"  alt="track">
                </div>
                <span class="first-section-footer-text">
                    Besplatna dostava<br/>
                    {{companyViewService.company.transportCostLimit | number:'1.2-2'}}
                    {{companyViewService.company.defaultCurrency}}
                </span>
            </div>
            <div class="d-flex align-items-center col-md-3 col-xs-12 col-sm-12 first-section-footer-item">
                <div class="img-wrapper">
                    <a href="tel:{{companyViewService.company.mobilePhoneNumber}}">
                        <img src="../../assets/images/footer/phones.svg"  alt="phones">
                    </a>
                </div>
                <span class="first-section-footer-text">Pozovi naše prodajne savetnike<br>{{companyViewService.company.mobilePhoneNumber}}</span>
            </div>
            <div class="d-flex align-items-center col-md-3 col-xs-12 col-sm-12 first-section-footer-item">
                <div class="img-wrapper">
                    <img src="../../assets/images/footer/arrows.svg"  alt="arrows">
                </div>
                <span class="first-section-footer-text">Pravo na povrat robe</span>
            </div>
            <div class="d-flex align-items-center col-md-3 col-xs-12 col-sm-12 first-section-footer-item">
                <div class="img-wrapper">
                    <img src="../../assets/images/footer/bank.svg"  alt="bank">
                </div>
                <span class="first-section-footer-text">Odloženo plaćanje do 24 rate bez kamate</span>
            </div>
        </div>
    </div>
</div>
<div class="newsletter">
    <div class="container">
        <div class="newsletter-wrapper d-flex align-items-start">
            <div class="col-md-6 col-xs-12 col-sm-12">
                <p class="newsletter-title">Budi u toku!</p>
                <p class="newsletter-text">Prijavi se za naš newsletter, jer je dobro raspoloženje na popustu!</p>
            </div>
            <div class="col-md-6 col-xs-12 col-sm-12">
                <form [formGroup]="promoEmailForm" (ngSubmit)="onSubmit()">
                    <div class="input-wrapper">
                        <input formControlName="promoEmail" type="email"
                            placeholder="Unesi e-mail adresu">
                        <button type="submit" class="main_button"
                            [attr.disabled]="promoEmailForm.invalid ? '' : null">Prijavi se</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
        </div>
    </div>
</div>
<div class="links-section-footer">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-xs-12 col-sm-12">
                <p class="link-title">Korisnički servis</p>
                <ul *ngIf="companyViewService.company">
                    <li><a [routerLink]="['/page/static-page/' + AboutUsCode]">O nama</a></li>
                    <li><a routerLink="/page/contact">Kontakt forma</a></li>
                    <li><a routerLink="/page/blogs">Blogovi</a></li>
                    <li><a routerLink="/page/brands">Brendovi</a></li>
                    <br>
                    <li> <a
                            href="tel:{{companyViewService.company.phoneNumber}}">{{companyViewService.company.phoneNumber}}</a>
                    </li>
                    <li><a href="mailto:{{companyViewService.company.email}}">{{companyViewService.company.email}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3 col-xs-12 col-sm-12">
                <p class="link-title">Online kupovina</p>
                <ul>
                    <li><a [routerLink]="['/page/static-page/' + TermsAndConditionsCode]">Opšti uslovi prodaje</a></li>
                    <li><a [routerLink]="['/page/static-page/' + PrivacyPolicyCode]">Politika privatnosti</a></li>
                    <li><a [routerLink]="['/page/static-page/' + CookiePolicyCode]">Politika kolačića</a></li>
                    <li><a [routerLink]="['/page/static-page/' + RightOfWithdrawalCode]">Obaveštenje o pravima</a></li>
                    <li><a [routerLink]="['/page/static-page/' + ComplaintsCode]">Reklamacije</a></li>
                </ul>
            </div>
            <div class="col-md-3 col-xs-12 col-sm-12">
                <p class="link-title">Plaćanje i isporuka</p>
                <ul>
                    <li><a [routerLink]="['/page/static-page/' + PaymentMethodCode]">Način plaćanja</a></li>
                    <li><a [routerLink]="['/page/static-page/' + WayOfDeliveryCode]">Način isporuke</a></li>
                </ul>
            </div>
            <div class="col-md-3 col-xs-12 col-sm-12" *ngIf="companyViewService.company">
                <p class="link-title">Društvene mreže</p>
                <div class="social-media">
                    <a target="_blank" [href]="companyViewService.company.facebookUrl">
                        <img src="../../assets/images/footer/facebook.svg" 
                            [alt]="companyViewService.company.facebookUrl">
                    </a>
                    <a class="ms-4" target="_blank" [href]="companyViewService.company.instagramUrl">
                        <img src="../../assets/images/footer/instagram.svg" 
                            [alt]="companyViewService.company.instagramUrl">
                    </a>
                    <a class="ms-4" target="_blank" href="https://www.tiktok.com/&#64;ipsilon.rs">
                        <img src="../../assets/images/footer/tiktok.svg"  alt="tiktok">
                    </a>
                    <div width="100%">
                        <img class="footer-logo" [src]="companyViewService.company.logo" 
                            [alt]="companyViewService.company.name">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="additional-footer-container">
    <div class="container d-flex justify-content-center bank-cards">
        <img src="../../assets/images/footer/cards/masterCardSC.svg"  alt="masterCardSC">
        <img src="../../assets/images/footer/cards/verifiedbyvisa.svg"  alt="verifiedbyvisa">
        <img src="../../assets/images/footer/cards/ms_acc_opt_70_1.svg"  alt="ms_acc_opt_70_1">
        <img src="../../assets/images/footer/cards/bancaIntesa.svg"  alt="bancaIntesa">
        <img src="../../assets/images/footer/cards/ms_acc_opt_70_2.svg"  alt="ms_acc_opt_70_2">
        <img src="../../assets/images/footer/cards/dina.svg"  alt="dina">
        <img src="../../assets/images/footer/cards/visa_pos_fc.svg"  alt="visa_pos_fc">
        <img src="../../assets/images/footer/cards/american.svg"  alt="american">
    </div>
</div>
<div class="additional-footer-container">
    <div class="container footer-copyright" *ngIf="companyViewService.company">
        <p class="company-details">{{companyViewService.company.name}} | {{companyViewService.company.fullAddress}} |
            <span>MB</span>: {{companyViewService.company.identificationNumber}} | <span>PIB</span>: {{companyViewService.company.taxNumber}} |
            {{companyViewService.company.currentBankAccounts}}</p>
        <p class="copyright">Cene na sajtu su iskazane u dinarima sa uračunatim porezom, a plaćanje se vrši isključivo u dinarima.
            {{companyViewService.company.mobilePhoneNumber}} (po ceni lokalnog poziva)</p>
    </div>
</div>