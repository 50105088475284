import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-ui-template-server-error',
  templateUrl: './server-error.component.html'
})
export class BaseServerErrorComponent {
  error: any;

  constructor(private router: Router) { 
    const navigation = this.router.getCurrentNavigation();
    this.error = navigation && navigation.extras && navigation.extras.state && navigation.extras.state['error'];
  }

}
