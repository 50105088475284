import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FrameType, FrameViewModel } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-frame-card',
  templateUrl: './frame-card.component.html',
  styleUrls: ['./frame-card.component.scss']
})
export class BaseFrameCardComponent {
  @Input() frame!: FrameViewModel;
  @Input() pagePrefix = "";

  constructor(private router: Router) {}

  navigateToDetails() {
    const pageToNavigate = FrameType[this.frame.frameType].toLowerCase();
    let navigateTo = `/${pageToNavigate}/${this.frame.slug}`;
    if (this.pagePrefix) {
      navigateTo = `/${this.pagePrefix}${navigateTo}`;
    }
    this.router.navigate([navigateTo]);
  }

  getHref() {
    const pageToNavigate = FrameType[this.frame.frameType].toLowerCase();
    let navigateTo = `/${pageToNavigate}/${this.frame.slug}`;
    if (this.pagePrefix) {
      navigateTo = `/${this.pagePrefix}${navigateTo}`;
    }
    return navigateTo;
  }
}
