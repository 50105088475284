import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  AddressRequest,
  BasketViewModel,
  BasketViewService,
  CompanyService,
  CompanyViewService,
  CreateOrderRequest,
  EmailService,
  PaymentMethod,
} from '@b2c-frontend/http';
import { ToastrService } from 'ngx-toastr';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.scss'],
})
export class BaseThirdStepComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  errorMessage?: string;
  currentPaymentMethod!: PaymentMethod;
  signature!: string;
  returnUrl!: string;
  basket?: BasketViewModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private router: Router,
    private emailService: EmailService,
    private toastr: ToastrService,
    public basketViewService: BasketViewService,
    public location: Location,
    public companyViewService: CompanyViewService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe), shareReplay(1)).subscribe({
      next: (params) => {
        const success = params['Success'];
        if (!success) {
          return;
        }

        if (success === '0') {
          this.errorMessage = /*$localize*/`Vaša uplata je odbijena. Molimo da proverite vaše podatke`;
          this.toastr.warning(this.errorMessage, /*$localize*/`Notifikacija`);
        } else {
          this.createOrder();
        }
      },
    });

    if (
      !this.basketViewService.orderRequest ||
      !CreateOrderRequest.isValid(
        this.basketViewService.orderRequest,
        this.emailService,
      )
    ) {
      this.router.navigate(['/checkout/first']);
      return;
    } else if (!this.basketViewService.orderRequest.paymentMethod) {
      this.router.navigate(['/checkout/second']);
      return;
    }

    this.basketViewService.basketObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (basket) => {
        this.basket = basket;
        if (!basket || !basket.items) {
          return;
        }

        if (this.currentPaymentMethod === PaymentMethod.OnlinePaymentCard ||
            this.currentPaymentMethod === PaymentMethod.CreditCard) {
              this.companyService
              .getOnlinePaymentSignature(
                this.companyViewService.company?.onlinePaymentShopId ?? '',
                this.basketViewService.orderRequest?.basketId ?? '',
                this.basketViewService.orderRequest?.totalAmount ?? 0,
              )
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (signature) => {
                  if (!signature) {
                    this.signature = '';
                  }
                  this.signature = signature;
                },
              });
            }
      },
    });
    this.basketViewService.getBasket();

    this.currentPaymentMethod = this.basketViewService.orderRequest.paymentMethod;
    this.returnUrl = `${window.location.origin}${this.location.path()}`;

    this.basketViewService.orderObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (result) => {
        if (result) {
          this.errorMessage = '';
          this.basketViewService.orderRequest = null;

          this.toastr.success(/*$localize*/`Hvala na kupovini`, /*$localize*/`Notifikacija`);

          this.router.navigate([`/checkout/third`], {
            relativeTo: this.activatedRoute,
            queryParams: { 'completed-purchase': true },
            queryParamsHandling: 'merge',
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  createOrder() {
    this.basketViewService.createOrder();
  }

  onSubmit(e: any) {
    e.target.submit();
  }

  get PaymentMethod() {
    return PaymentMethod;
  }

  get AddressRequest() {
    return AddressRequest;
  }

  canceled($event: any) {
    this.toastr.warning(/*$localize*/`Plaćanje preko PayPal sistema je otkazano`, /*$localize*/`Notifikacija`);
    this.basketViewService.setPaymentMethod(undefined);
  }

  onError($event: any) {
    this.toastr.error(/*$localize*/`Dogodila se greška prilikom korišćenja PayPal sistema`, /*$localize*/`Notifikacija`);
    this.basketViewService.setPaymentMethod(undefined);
  }

  onPayPalPaymentCompleted($event: any) {
    this.basketViewService.setNotes(
      `PAYPAL PAYMENT ID: ${$event.id}, CART: ${$event.cart}`,
    );
    this.basketViewService.createOrder();
  }
}
