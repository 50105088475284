import { NgModule } from '@angular/core';
import { BaseCheckoutComponent } from './checkout.component';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { BaseFirstStepComponent } from './first-step/first-step.component';
import { BaseSecondStepComponent } from './second-step/second-step.component';
import { BaseThirdStepComponent } from './third-step/third-step.component';
import { BasePaymentMethodComponent } from './payment-method/payment-method.component';
import { FormsModule } from '@angular/forms';
import { BaseTextInputCheckoutComponent } from './text-input/text-input.component';
import { FrontendCommonModule } from '@b2c-frontend/common';
import { CommonModule } from '@angular/common';
import { BaseBasketModule } from '../basket/basket.module';
import { BaseSharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BaseCheckoutComponent,
    BaseFirstStepComponent,
    BaseSecondStepComponent,
    BaseThirdStepComponent,
    BasePaymentMethodComponent,
    BaseTextInputCheckoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FrontendCommonModule,
    CheckoutRoutingModule,
    BaseBasketModule,
    BaseSharedModule
  ],
  exports: [
    BaseCheckoutComponent,
    BaseFirstStepComponent,
    BaseSecondStepComponent,
    BaseThirdStepComponent,
    BasePaymentMethodComponent,
  ]
})
export class BaseCheckoutModule { }
