import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import { BasketResponse } from '../http-models';
import { BasketViewModel } from '../view-models';

@Injectable()
export class BasketService extends BaseClient {

  constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
    super(http, baseApiUrl);
  }

  addItem(basketId: string, request: any): Observable<BasketViewModel> {
    return this.post<BasketResponse>(`baskets/${basketId}/add-item`, request)
               .pipe(map(basket => {
                  if (basket) {
                    return this.mapBasketResponseToBasketViewModel(basket);
                  }
                  return basket;
                }));
  }

  deleteItem(basketId: string, productId: string): Observable<BasketViewModel> {
    return this.delete<BasketResponse>(`baskets/${basketId}/delete-item/${productId}`)
              .pipe(map(basket => {
                if (basket) {
                  return this.mapBasketResponseToBasketViewModel(basket);
                }
                return basket;
              }));
  }

  getBasket(basketId: string): Observable<BasketViewModel> {
    return this.get<BasketResponse>(`baskets/${basketId}/get?q=${Date.now()}`)
              .pipe(map(basket => {
                if (basket) {
                  return this.mapBasketResponseToBasketViewModel(basket);
                }
                return basket;
              }));
  }

  private mapBasketResponseToBasketViewModel(basket: BasketResponse): BasketViewModel {
    const basketVm = new BasketViewModel();
    basketVm.basketId = basket.basketId;
    basketVm.items = basket.items;
    basketVm.itemsCount = basket.itemsCount;
    basketVm.total = basket.total;
    basketVm.totalSaving = basket.totalSaving;
    basketVm.transportCost = basket.transportCost;

    return basketVm;
  }
}
