import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { SeoModule } from '@b2c-frontend/seo';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseSharedModule } from '../shared/shared.module';
import { BaseContactComponent } from './contact/contact.component';
import { BaseBlogComponent } from './blog/blog.component';
import { BaseBannerBlogComponent } from './banner-blog/banner-blog.component';
import { BaseRecommendationBannerComponent } from './recommendation-banner/recommendation-banner.component';
import { BaseBrandsComponent } from './brands/brands.component';
import { BaseStaticPageComponent } from './static-page/static-page.component';
import { PagesRoutingModule } from './pages-routing.module';
import { BaseEmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { BaseRedirectComponent } from './redirect/redirect.component';
import { BaseNotFoundComponent } from './not-found/not-found.component';
import { BaseServerErrorComponent } from './server-error/server-error.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BaseContactComponent,
    BaseEmailUnsubscribeComponent,
    BaseBlogComponent,
    BaseBannerBlogComponent,
    BaseRecommendationBannerComponent,
    BaseBrandsComponent,
    BaseStaticPageComponent,
    BaseRedirectComponent,
    BaseNotFoundComponent,
    BaseServerErrorComponent,
  ],
  imports: [
    CommonModule,
    BaseSharedModule,
    FormsModule,
    ReactiveFormsModule,
    SeoModule,
    NgxPaginationModule,
    RouterLink,
    PagesRoutingModule
  ],
  exports: [
    BaseContactComponent,
    BaseEmailUnsubscribeComponent,
    BaseBlogComponent,
    BaseBannerBlogComponent,
    BaseRecommendationBannerComponent,
    BaseBrandsComponent,
    BaseStaticPageComponent,
    BaseRedirectComponent,
    BaseNotFoundComponent,
    BaseServerErrorComponent,
  ]
})
export class BasePagesModule { }
