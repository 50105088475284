import { NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProductRoutingModule } from './product-routing.module';
import { BaseProductDetailsComponent } from './product-details.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { IdenticalProductComponent } from './identical-product-card/identical-product-card.component';
import { ProductImagesComponent } from './vertical-images/product-images.component';
import { StickerFrameComponent } from './vertical-images/sticker-frame/sticker-frame.component';
import { BonusProductsComponent } from './bonus-products/bonus-products.component';
import { SeoModule } from '@b2c-frontend/seo';
import { CommonModule } from '@angular/common';
import { BaseSharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BaseProductDetailsComponent,
    IdenticalProductComponent,
    ProductImagesComponent,
    StickerFrameComponent,
    BonusProductsComponent
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    CarouselModule,
    PopoverModule,
    SeoModule,
    BaseSharedModule
  ],
  exports: [
    BaseProductDetailsComponent,
    IdenticalProductComponent,
    ProductImagesComponent,
    StickerFrameComponent,
    BonusProductsComponent
  ]
})
export class BaseProductModule { }
