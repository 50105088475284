<div class="shop-category">
    <ul>
        <li *ngFor="let child of categories" (click)="openCategory(child)">
            <ng-template #openCategoryChildTemplate>
                <div class="cat-img">
                    <img loading="lazy" [src]="getCategoryImageUrl(child)" [alt]="child.name" decoding="async">
                
                <p>{{child.name}}</p></div>
            </ng-template>
   
            <b2c-frontend-a [relativeHref]="getCategoryChildHref(child)"
                [content]="openCategoryChildTemplate">
            </b2c-frontend-a>

        </li>
    </ul>
</div>