import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@b2c-frontend/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CompanyViewService, UsersService } from '@b2c-frontend/http';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class BaseFooterComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  promoEmailForm = new FormGroup({
    promoEmail: new FormControl('', [Validators.email, Validators.required, Validators.maxLength(100)]),
  });

  languages: string[] = [];

  constructor(
    private toastr: ToastrService,
    private usersService: UsersService,
    public companyViewService: CompanyViewService) {}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

  onSubmit() {
    if (!this.promoEmailForm.value.promoEmail) {
      return;
    }

    const request = {
      email: this.promoEmailForm.value.promoEmail
    };

    this.usersService.addPromoEmail(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (result: string) => {
        if (!result) {
          return;
        }

        this.toastr.success(/*$localize*/`Uspešno ste se preplatili na Newsletter`, /*$localize*/`notifikacija`);
        this.promoEmailForm.reset();
      }
    });
  }

  get AboutUsCode(): string {
    return Constants.AboutUsCode;
  }

  get ComplaintsCode(): string {
    return Constants.ComplaintsCode;
  }

  get CookiePolicyCode(): string {
    return Constants.CookiePolicyCode;
  }

  get PaymentMethodCode(): string {
    return Constants.PaymentMethodCode;
  }

  get RightOfWithdrawalCode(): string {
    return Constants.RightOfWithdrawalCode;
  }

  get PrivacyPolicyCode(): string {
    return Constants.PrivacyPolicyCode;
  }

  get TermsAndConditionsCode(): string {
    return Constants.TermsAndConditionsCode;
  }

  get WayOfDeliveryCode(): string {
    return Constants.WayOfDeliveryCode;
  }
}
