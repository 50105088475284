
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService, CategoryListViewModel } from '@b2c-frontend/http';

@Component({
  selector: 'lib-ui-template-category-second-card',
  templateUrl: './category-second-card.component.html',
  styleUrls: ['./category-second-card.component.scss']
})
export class BaseCategorySecondCardComponent {
  @Input() category!: CategoryListViewModel;

  constructor(private router: Router, private categoriesService: CategoriesService) {

  }

  openCategory() {
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(this.category)}`], { queryParams: { categoryId: this.category.id } });
  }

  getImageUrl() {
    return this.categoriesService.getImageUrl(this.category.id);
  }

  getHref() {
    return `/ps/c/${CategoryListViewModel.getSlug(this.category)}`;
  }
}