import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UsersService } from '@b2c-frontend/http';
import { BaseLoginComponent } from '../login/login.component';

@Component({
  selector: 'lib-ui-template-wihslist-login',
  templateUrl: './wishlist-login.component.html',
  styleUrls: ['./wishlist-login.component.scss'],
})
export class BaseWishlistLoginComponent extends BaseLoginComponent {
  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    usersService: UsersService,
    authService: AuthService) {
    super(router, activatedRoute, usersService, authService);
  }
}
