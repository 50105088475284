import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  WishesViewService,
  ProductListViewModel,
  WishItemViewModel,
} from '@b2c-frontend/http';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-wishlist-account',
  templateUrl: './wishlist-account.component.html',
  styleUrls: ['./wishlist-account.component.scss'],
})
export class BaseWishlistAccountComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  products: ProductListViewModel[] = [];

  constructor(private wishesViewService: WishesViewService) {}

  productId!: string;
    productName!: string;
    price!: number;
    promoPrice?: number;
    productPictureUrl?: string;

  ngOnInit(): void {
    this.wishesViewService.messageSource
      .pipe(takeUntil(this.ngUnsubscribe), shareReplay(1))
      .subscribe({
        next: (wish) => {
          this.products = wish.items?.map(item => ({
            id: item.productId,
            uniqueId: item.productId,
            name: item.productName,
            price: item.price,
            slug: '',
            transportCost: 0,
            isNew: false,
            saving: 0,
            isPromoPriceActive: false,
            isInWishList: false,
            promoPrice: item.promoPrice,
            recommendedPrice: 0,
            pictureUrls: [],
            mainPictureUrl: item.productPictureUrl,
            discountInPercentage: 0,
            selected: false,
            availability: '1',
            stickerFrames: [],
            categories: [],
            insignificantSpecification: undefined
          }));
        },
      });

    this.wishesViewService.getWishList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
