import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import { CompanyResponse, PlacesResponse } from '../http-models';
import { CompanyViewModel, PlacesViewModel } from '../view-models';
import { mapper } from '../mappings/mapper';

@Injectable()
export class CompanyService extends BaseClient {
    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getPlaces(postCode = "", city = "", street = ""): Observable<PlacesViewModel[]> {
        const queryParams = `?postCode=${postCode}&city=${city}&street=${street}`;
        return this.get<PlacesResponse[]>(`company/get-places${queryParams}`)
                   .pipe(map(places => {
                        if (!places || !places.length) {
                            return [];
                        }
                        return mapper.mapArray(places, PlacesResponse, PlacesViewModel);
                    }));
    }

    sendEmail(request: any): Observable<boolean> {
        return this.post<boolean>(`company/send-email`, request);
    }

    getCompany(): Observable<CompanyViewModel> {
        return this.get<CompanyResponse>(`company/get`)
                   .pipe(map(company => {
                        if (company) {
                            return mapper.map(company, CompanyResponse, CompanyViewModel);
                        }
                        return company;
                    }));
    }

    getOnlinePaymentSignature(shopId: string, shoppingCartId: string, totalAmount: number): Observable<string> {
        return this.get<string>(`company/get-signature?shopId=${shopId}&shoppingCartId=${shoppingCartId}&totalAmount=${totalAmount}`);
    }
}
