import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  WishesViewService,
  ProductListViewModel,
  mapper,
  WishItemViewModel,
} from '@b2c-frontend/http';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-wishlist-account',
  templateUrl: './wishlist-account.component.html',
  styleUrls: ['./wishlist-account.component.scss'],
})
export class BaseWishlistAccountComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  products: ProductListViewModel[] = [];

  constructor(private wishesViewService: WishesViewService) {}

  ngOnInit(): void {
    this.wishesViewService.messageSource
      .pipe(takeUntil(this.ngUnsubscribe), shareReplay(1))
      .subscribe({
        next: (wish) => {
          this.products = mapper.mapArray(
            wish.items ?? [],
            WishItemViewModel,
            ProductListViewModel,
          );
        },
      });

    this.wishesViewService.getWishList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
