import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appRoutes } from './app.routes';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AccountModule } from './account/account.module';
import { BasketModule } from './basket/basket.module';
import { CheckoutModule } from './checkout/checkout.module';
import { PagesModule } from './pages/pages.module';
import { registerLocaleData } from '@angular/common';
import { ProductModule } from './product/product.module';
import { ProductsModule } from './products/products.module';

import localeSr from '@angular/common/locales/sr';
import { HomeModule } from './home/home.module';
import { UiTemplateModule } from '@b2c-frontend/ui-template';
registerLocaleData(localeSr, 'sr');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top', scrollOffset: [0, 0] }),
    BrowserAnimationsModule,
    HomeModule,
    AccountModule,
    BasketModule,
    FormsModule,
    CheckoutModule,
    PagesModule,
    ProductsModule,
    ProductModule,
    UiTemplateModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
