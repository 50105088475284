import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '@b2c-frontend/http';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss']
})
export class BaseEmailUnsubscribeComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    emailUnsibscribeForm!: FormGroup;

    constructor(private toastr: ToastrService, private usersService: UsersService) { }

    ngOnInit(): void {
        this.emailUnsibscribeForm = new FormGroup({
            email: new FormControl('', [
              Validators.required,
              Validators.email,
            ])
        });
    }

    ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    onSubmit() {
        const values = this.emailUnsibscribeForm.value;

        const isValid = values.email.target && values.email.target.value;
    
        if (!isValid) {
          return;
        }

        this.usersService.removePromoEmail(values.email.target.value)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (result) => {
              this.toastr.success(/*$localize*/`Uspešno ste se odjavili sa newslettera`, /*$localize*/`notifikacija`);
            }
        });
    }
}