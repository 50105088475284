<div class="title">Odaberite način plaćanja</div>
<div class="payment-card-wrapper" *ngIf="companyViewService.company">
    <div style="padding: 0 !important;" *ngFor="let paymentCard of paymentCards">
        <lib-ui-template-payment-method (paymentSelected)="onSelectPaymentCard($event)" [paymentData]="paymentCard"></lib-ui-template-payment-method>
    </div>
</div>
<div class="d-flex justify-content-end align-items-center">
    <a [routerLink]="['/checkout/first']">
        <img src="../../../assets/images/checkout/left-arrow.svg" alt="Leva strelica" />
        <span>Nazad na prethodnu stranicu</span>
    </a>
    <button class="main_button next_step_button" (click)="onNextClicked()">Sledeći korak</button>
</div>
