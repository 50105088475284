<div class="container">
  <div class="user-data-wrapper">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="first-column">
          <a class="account-button" routerLink="user-data" routerLinkActive="active-account-button">
            <i class="fa fa-regular fa-address-card"></i>
            <span class="account-button-text">Tvoji podaci i lozinka</span>
            <span class="account-right-arrow"><i class="fa fa-solid fa-chevron-right"></i></span>
          </a>
          <a class="account-button" routerLink="wishlist" routerLinkActive="active-account-button">
            <i class="fa fa-solid fa-heart"></i>
            <span class="account-button-text">Tvoja lista želja</span>
            <span class="account-right-arrow"><i class="fa fa-solid fa-chevron-right"></i></span>
          </a>
          <a class="account-button" routerLink="orders" [queryParams]="{status: OrderStatus.Completed}" routerLinkActive="active-account-button">
            <i class="fa fa-solid fa-cart-shopping"></i>
            <span class="account-button-text">Istorija kupovina</span>
            <span class="account-right-arrow"><i class="fa fa-solid fa-chevron-right"></i></span>
          </a>
          <a class="account-button" routerLink="orders" [queryParams]="{status: OrderStatus.Created}" routerLinkActive="active-account-button">
            <i class="fa fa-solid fa-location-arrow"></i>
            <span class="account-button-text">Status pošiljke</span>
            <span class="account-right-arrow"><i class="fa fa-solid fa-chevron-right"></i></span>
          </a>
          <div class="remember-me">
            <input type="checkbox">&nbsp;&nbsp;
            <span class="account-button-text">Zapamti me</span>
          </div>
          <div class="logout">
            <i class="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;
            <span (click)="logout()" class="account-button-text">Odjavi se</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="second-column">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="col-lg-3 col-md-12" *ngIf="user$ | async as user">
        <div class="account-column">
          <div class="field-right field-right-first">Moji podaci</div>
          <div class="account-field-right" *ngIf="user.fullName"><span>Ime i prezime</span>: <span>{{user.firstName}}</span><span>{{user.lastName}}</span></div>
          <div class="account-field-mail" *ngIf="user.email"><span>E-mail</span>: <span>{{user.email}}</span></div>
          <div class="account-field-right" *ngIf="user.street && user.number"><span>Ulica i broj</span>: <span>{{user.street}} {{user.number}}</span></div>
          <div class="account-field-right" *ngIf="user.postalCode && user.city"><span>PTT i adresa</span>: <span>{{user.postalCode}}</span><span>{{user.city}}</span></div>
          <div class="account-field-right" *ngIf="user.state"><span>Država</span>: <span>{{user.state}}</span></div>
          <div class="account-field-right" *ngIf="user.apartmentNumber"><span>Broj apartmana</span>: <span>{{user.apartmentNumber}}</span></div>
          <div class="account-field-right" *ngIf="user.floor"><span>Broj sprata</span>: <span>{{user.floor}}</span></div>
          <div class="account-field-right" *ngIf="user.phoneNumber"><span>Fiksni telefon</span>: <span>{{user.phoneNumber}}</span></div>
          <div class="account-field-right" *ngIf="user.mobilePhoneNumber"><span>Mobilni telefon</span>: <span>{{user.mobilePhoneNumber}}</span></div>
          <div class="account-field-right" *ngIf="user.companyName"><span>Kompanija</span>: <span>{{user.companyName}}</span></div>
          <div class="account-field-right" *ngIf="user.companyTaxNumber"><span>PIB</span>: <span>{{user.companyTaxNumber}}</span></div>
        </div>
      </div>
    </div>
  </div>
</div>
