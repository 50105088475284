import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { FrontendCommonModule } from '@b2c-frontend/common';
import { CookieConsentModule } from '@b2c-frontend/cookie-consent';
import { CommonModule } from '@angular/common';
import { BaseBasketModule } from '../basket/basket.module';
import { BaseNavBarComponent } from './nav-bar/nav-bar.component';
import { BaseSectionHeaderComponent } from './section-header/section-header.component';
import { BaseFooterComponent } from './footer/footer.component';
import { BaseSharedModule } from '../shared/shared.module';
import { BaseAccountModule } from '../account/account.module';
import { BaseSearchInputComponent } from './nav-bar/search-input/search-input.component';

@NgModule({
  declarations: [
    BaseFooterComponent,
    BaseNavBarComponent,
    BaseSectionHeaderComponent,
    BaseSearchInputComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    FrontendCommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BaseAccountModule,
    BaseBasketModule,
    BaseSharedModule,
    CookieConsentModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    })
  ],
  exports: [
    BaseFooterComponent,
    BaseNavBarComponent,
    BaseSectionHeaderComponent
  ]
})
export class BaseCoreModule { }
