import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  BasketViewService,
  CategoryGroupSelectViewModel,
  CompanyViewService,
  ProductDetailsViewModel,
  ProductListViewModel,
  ProductsService,
} from '@b2c-frontend/http';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WindowService } from '@b2c-frontend/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-ui-template-bonus-products',
  templateUrl: './bonus-products.component.html',
  styleUrls: ['./bonus-products.component.scss'],
})
export class BonusProductsComponent implements OnChanges, OnInit, OnDestroy {
  private window: any;
  private timers: any = [];

  isMobile!: boolean;
  popDiscountHtmlContent = '';
  recommendedProducts$?: Observable<ProductListViewModel[]>;
  secondaryCategory$?: Observable<CategoryGroupSelectViewModel>;
  selectedProducts: ProductListViewModel[] = [];
  totalPrice!: number;

  sliderCustomOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    startPosition: 0,
    nav: true,
    navText: [
      '<img src="../../assets/images/landing/left-arrow.svg"></img>',
      '<img src="../../assets/images/landing/right-arrow.svg"></img>',
    ],
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      800: {
        items: 4,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    },
  };

  @Input() product!: ProductDetailsViewModel;
  productFromList!: ProductListViewModel;

  constructor(
    private windowService: WindowService,
    private productsService: ProductsService,
    private basketViewService: BasketViewService,
    public companyViewService: CompanyViewService,
  ) {}

  ngOnInit(): void {
    this.window = this.windowService.getWindow();
    this.isMobile = this.window.innerWidth < 1200;
    this.popDiscountHtmlContent = /*$localize*/`<div class='product-group-popup'><h5>Uputstvo</h5><h6>Čekiranjem proizvoda stičete pravo na kupovinu dodatnog proizvoda po znatno nižoj ceni.<br><br>Bonus proizvode možete pregledati u sekciji ispod.</h6></div>`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.productFromList = {
      id: this.product.id,
      uniqueId: this.product.productId,
      name: this.product.name,
      price: this.product.price,
      slug: this.product.slug,
      transportCost: this.product.transportCost,
      isNew: this.product.isNew,
      saving: this.product.saving,
      isPromoPriceActive: this.product.isPromoPriceActive,
      isInWishList: false,
      promoPrice: this.product.promoPrice,
      pictureUrls: this.product.imageUrls?.map((img) => img.url),
      mainPictureUrl: this.product.mainPictureUrl,
      discountInPercentage: this.product.discountInPercentage,
      selected: false,
      availability: '',
    };
    this.refreshData();
  }

  ngOnDestroy(): void {
      for (let i = 0; i < this.timers.length; i++) {
        const timer = this.timers[i];
        clearTimeout(timer);
      }
  }

  onCheckboxChanged(product: ProductListViewModel) {
    if (product.selected === true) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
    }

    let totalPrice = 0;

    for (let i = 0; i < this.selectedProducts.length; i++) {
      const selectedProduct = this.selectedProducts[i];
      totalPrice +=
        selectedProduct.recommendedPrice ?? selectedProduct.promoPrice ?? 0;
    }

    this.totalPrice =
      totalPrice +
      (this.product.isPromoPriceActive
        ? (this.product.promoPrice ?? 0)
        : this.product.price);
  }

  addItemsToBasket() {
    if (
      !this.product ||
      !this.selectedProducts ||
      !this.selectedProducts.length
    ) {
      return;
    }

    let timeout = 50;

    for (let i = 0; i < this.selectedProducts.length; i++) {
      const product = this.selectedProducts[i];
      timeout += timeout * (i + 1);
      this.timers.push(setTimeout(() => {
        this.basketViewService.addItem({
          productId: product.id,
          productName: product.name,
          productUniqueId: product.uniqueId,
          price: product.price,
          isPromoPriceActive: product.isPromoPriceActive,
          promoPrice: product.promoPrice,
          productPictureUrl: product.mainPictureUrl,
          availability: product.availability,
          quantity: 1,
          linkedProductId: this.product.id,
        });
      }, timeout));
    }

    this.timers.push(setTimeout(() => {
      this.basketViewService.addItem({
        productId: this.product.id,
        productName: this.product.name,
        productUniqueId: this.product.productId,
        price: this.product.price,
        isPromoPriceActive: this.product.isPromoPriceActive,
        promoPrice: this.product.promoPrice,
        productPictureUrl: this.product.mainPictureUrl,
        availability: this.product.availability,
        quantity: 1,
      });
    }, timeout + 400));
  }

  private refreshData() {
    const categories = this.product.categories;
    if (!categories) {
      return;
    }

    const firstCategory = categories[0];
    if (!firstCategory) {
      return;
    }

    this.secondaryCategory$ = this.productsService.getSecondaryCategory(firstCategory.categoryId);
    this.recommendedProducts$ = this.productsService.getRecommendedProducts(this.product.id, firstCategory.categoryId);
  }
}
