import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CategoriesService,
  FramesService,
  ProductsService,
  Sorting,
  SpecificationsService,
  CategoryListViewModel,
  FrameViewModel,
  ProductListViewModel,
  SpecificationListViewModel,
  SpecificationValueListViewModel,
  CategoryProductViewModel,
  CompanyViewService
} from '@b2c-frontend/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  WindowService,
} from '@b2c-frontend/common';
import { PaginationInstance } from 'ngx-pagination';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MetaService, MetaTagComponent } from '@b2c-frontend/seo';
import { shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-frontend-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class BaseProductsComponent extends MetaTagComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  products: ProductListViewModel[] = [];
  categoriesViewModel: CategoryListViewModel[] = [];
  specificationsViewModel: SpecificationListViewModel[] = [];
  frame?: FrameViewModel;
  totalCount = 0;
  totalPages = 1;
  title = 'Proizvodi';
  productsTitle = 'Proizvodi';
  productIds: string[] | undefined = [];

  showFilters = false;
  showLoader = true;

  page = 1;
  sorting = '';
  maxPriceMinRange = 100000;
  maxPriceMaxRange = 1000000;
  minPrice: number | undefined = 0;
  maxPrice: number | undefined = undefined;
  categoryId?: string;
  showAboveDescription = false;
  aboveDescription?: string;
  belowDescription?: string;
  specifications?: string[] = [];
  specificationMappingNameWithValue?: string;
  brands?: string[] = [];

  public paginatorConfig: PaginationInstance = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    private specificationsService: SpecificationsService,
    private framesService: FramesService,
    private location: Location,
    private breadcrumbService: BreadcrumbService,
    public companyViewService: CompanyViewService,
    metaService: MetaService,
    windowService: WindowService) {
      super(metaService, windowService);
    }

  ngOnInit(): void {
    const brand = this.activatedRoute.snapshot.params['brand'];
    const specificationMapping = this.activatedRoute.snapshot.params['specification'];
    const category = this.activatedRoute.snapshot.params['category'];

    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe), shareReplay(1)).subscribe({
      next: (params) => {

        const frameId = params['frameId'] ? params['frameId'] : '';
        if (frameId) {
          this.getFrame(frameId);
          return;
        }

        const showWithPromoPrice = params['withPromoPrice'];
        const showNewProducts = params['showNewProducts'];
        const searchTerm = params['searchTerm'];

        this.page = params['page'] ? Number(params['page']) : 1;
        this.brands = !params['brands'] ? (brand ? [brand] : []) : params['brands'];
        this.specificationMappingNameWithValue = !params['specificationMapping'] ? (!specificationMapping ? params['specifications'] : '') : params['specificationMapping'];

        this.sorting = params['sorting'];
        this.minPrice = params['minPrice'] ?? 0;
        this.maxPrice = params['maxPrice'] ?? this.maxPriceMaxRange;
        this.categoryId = params['categoryId'] ? params['categoryId'] : category;

        this.specifications = <Array<string>>params['specifications'];
        if (!this.specifications || !this.specifications.length) {
          this.specificationsViewModel = [];
        }

        if (showNewProducts === 'true') {
          this.title = /*$localize*/`Novi proizvodi`;
        } else if (showWithPromoPrice === 'true') {
          this.title = /*$localize*/`Proizvodi na akciji`;
        } else if (brand) {
          this.title = brand;
        } else if (searchTerm) {
          this.title = searchTerm;
        } else {
          this.title = /*$localize*/`Proizvodi`;
        }

        this.productsTitle = this.title;

        this.aboveDescription = "";
        this.belowDescription = "";

        this.breadcrumbService.set('@productsTitle', this.productsTitle);
        this.setMetaData(this.title, 'Products', '', this.title, this.page);

        let queryParams = `page=${this.page}&needProductIds=true`;

        if (this.brands && this.brands.length) {
          if (typeof this.brands === 'string') {
            this.brands = [this.brands];
            queryParams += `&brands=${this.brands}`;
          } else {
            for (let i = 0; i < this.brands.length; i++) {
              const brand = this.brands[i];
              queryParams += `&brands=${brand}`;
            }
          }
          this.breadcrumbService.set('@brandName', this.brands[this.brands.length - 1]);
        }

        if (this.categoryId) {
          queryParams += `&categoryIds=${this.categoryId}`;
        }

        if (this.specifications && this.specifications.length) {
          if (typeof this.specifications === 'string') {
            this.specifications = [this.specifications];
          }
          for (let i = 0; i < this.specifications.length; i++) {
            const specification = this.specifications[i];
            queryParams += `&specifications=${specification}`;
          }
        }

        Object.getOwnPropertyNames(params)
          .filter(
            (p) =>
              p !== 'page' &&
              p !== 'needProductIds' &&
              p !== 'specifications' &&
              p !== 'brands' &&
              p !== 'categoryId',
          )
          .forEach((paramName) => {
            queryParams += `&${paramName}=${params[paramName]}`;
          });

        this.refreshCategory(queryParams);
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleAboveDescription() {
    this.showAboveDescription = !this.showAboveDescription;
  }

  onOpenSpec(spec: SpecificationListViewModel) {
    spec.isOpened = !spec.isOpened;

    this.getSpecificationValues(spec);
  }

  categoryOpened = false;

  onOpenCategory() {
    this.categoryOpened = !this.categoryOpened;
  }

  onPageChanged(page: number) {
    this.paginatorConfig.currentPage = page;
    this.navigateToThisRoute({ page: page });
  }

  onSortingChanged(event: any) {
    this.navigateToThisRoute({ page: 1, sorting: event.target.value });
  }

  onMinPriceChanged(event: any) {
    this.navigateToThisRoute({
      page: 1,
      minPrice: event.target.value,
      maxPrice: this.maxPrice,
    });
  }

  onMaxPriceChanged(event: any) {
    this.navigateToThisRoute({
      page: 1,
      maxPrice: event.target.value,
      minPrice: this.minPrice,
    });
  }

  get Sorting() {
    return Sorting;
  }

  onCategorySelected(category: CategoryListViewModel) {
    this.page = 1;
    this.specificationsViewModel = [];
    this.breadcrumbService.set('Second-Level', category.name);
    this.navigateToThisRoute(
      {
        page: this.page,
        categoryId: category.id,
        specifications: null,
        brands: null,
      },
      category.path,
    );
  }

  onBrandChanged(data: any) {
    this.page = 1;
    this.specificationsViewModel = [];

    if (!this.activatedRoute.snapshot.queryParams) {
      return;
    }

    const queryParams = JSON.parse(
      JSON.stringify(this.activatedRoute.snapshot.queryParams),
    );
    if (!queryParams.brands) {
      queryParams.brands = [];
    } else if (typeof queryParams.brands === 'string') {
      queryParams.brands = [queryParams.brands];
    }

    if (data.checked) {
      queryParams.brands?.push(data.value);
    } else {
      const index = this.brands?.indexOf(data.value);
      if (index !== undefined && index > -1) {
        queryParams.brands?.splice(index, 1);
      }
    }

    queryParams.page = this.page;
    this.navigateToThisRoute(queryParams);
  }

  onSpecChanged(specName: string, value: SpecificationValueListViewModel) {
    this.page = 1;

    if (!this.activatedRoute.snapshot.queryParams) {
      return;
    }

    const queryParams = JSON.parse(
      JSON.stringify(this.activatedRoute.snapshot.queryParams),
    );

    if (!queryParams.specifications) {
      queryParams.specifications = [];
    } else if (typeof queryParams.specifications === 'string') {
      queryParams.specifications = [queryParams.specifications];
      this.specificationMappingNameWithValue = `${specName}: ${value.mappingName}`;
      queryParams.specificationMapping = `${specName}: ${value.mappingName}`;
    }

    const checked = this.specifications?.includes(value.originalNames);

    if (!checked) {
      queryParams.specifications?.push(value.originalNames);
      this.specificationMappingNameWithValue = `${specName}: ${value.mappingName}`;
      queryParams.specificationMapping = `${specName}: ${value.mappingName}`;
    } else {
      const index = this.specifications?.indexOf(value.originalNames);
      if (index !== undefined && index > -1) {
        queryParams.specifications?.splice(index, 1);
        queryParams.specificationMapping = null;
      }
    }

    if (!queryParams.specifications || !queryParams.specifications.length) {
      this.specificationMappingNameWithValue = '';
      queryParams.specificationMapping = null;
    }

    queryParams.page = this.page;
    this.navigateToThisRoute(queryParams);
  }

  getSpecificationHref(value: SpecificationValueListViewModel): string {
    let path = this.location.path();
    path = path.substring(0, path.indexOf('?'));
    if (path.includes('/ps/c/')) {
      const regExp = new RegExp(/^[a-zA-Z0-9:; ]+$/i);
      if (!regExp.test(value.originalNames)) {
        return '';
      }
      path += `/s/${value.originalNames.replaceAll(' ', '-').replaceAll(':', '_').replaceAll(';', '---')}`;
      return path;
    }

    return '';
  }

  isSpecSelected(value: SpecificationValueListViewModel) {
    return this.specifications?.includes(value.originalNames);
  }

  back() {
    this.location.back();
  }

  get categoryIds(): string[] {
    return !this.categoryId ? [] : [this.categoryId];
  }

  private refreshProducts(queryParams: string, category?: CategoryProductViewModel) {
    this.productsService.getProductsWithQueryParams(queryParams).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        this.products = response.items;
        this.totalCount = response.totalCount;
        this.totalPages = response.totalPages;

        this.paginatorConfig.totalItems = this.totalCount;
        this.paginatorConfig.itemsPerPage = response.pageSize;

        this.showLoader = false;

        if (response.productIds && response.productIds.length) {
          this.productIds = response.productIds;
          this.getCategories(response.productIds);
          if (category && category.parentId && response.productIds.length > 1) {
            this.getSpecifications(this.productIds);
          }
        }
      },
      error: result => {
        this.totalCount = 0;
        this.totalPages = 1;

        this.paginatorConfig.totalItems = 0;
        this.showLoader = false;
        this.products = [];
        this.categoriesViewModel = [];
        this.productIds = [];
      }
    });
  }

  private refreshCategory(queryParams: string) {
    if (this.categoryIds && this.categoryIds.length) {
      if (typeof this.categoryIds === 'string') {
        const categoryId = this.categoryIds;
        this.getCategory(categoryId, queryParams);
      } else {
        for (let i = 0; i < this.categoryIds.length; i++) {
          const categoryId = this.categoryIds[i];
          this.getCategory(categoryId, queryParams);
        }
      }
    } else {
      this.refreshProducts(queryParams);
    }
  }

  private navigateToThisRoute(queryParams: any, relativePath?: string) {
    this.showLoader = true;
    if (!relativePath) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
      return;
    }

    this.router.navigate([relativePath], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private getCategories(productIds?: string[]) {
    if (this.categoryId) {
      this.categoriesViewModel = [];
      this.getCategoriesFromServer(this.categoryId);
      return;
    }

    this.categoriesService.getCategoriesByProductIds(productIds).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (categories: CategoryListViewModel[]) => {
        this.categoriesViewModel = categories;
        this.categoryOpened = true;
      },
    });
  }

  private getCategoriesFromServer(categoryId: string) {
    this.categoriesService.getCategories(categoryId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (categories: CategoryListViewModel[]) => {
        this.categoriesViewModel.push(...categories);
        this.categoryOpened = true;
      },
    });
  }

  private getSpecifications(productIds?: string[]) {
    this.specificationsViewModel = [];
    this.specificationsService
      .getSpecifications(this.categoryId, productIds)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (specifications) => {
          this.specificationsViewModel = specifications;
          this.specificationsViewModel.forEach((spec) => {
            this.getSpecificationValues(spec);
          });
        },
      });
  }

  private getSpecificationValues(spec: SpecificationListViewModel) {
    if (spec.areValuesLoaded) {
      return;
    }

    this.specificationsService
      .getValues(
        spec.name,
        this.categoryId ?? '',
        spec.originalNames,
        this.productIds,
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (values) => {
          spec.values = values;
          spec.areValuesLoaded = true;
          spec.hide = !spec.values || !spec.values.length;
          spec.isOpened = !!spec.values.find((v) => this.specifications?.includes(v.originalNames));

          if ((!this.specifications || !this.specifications.length) && this.specificationMappingNameWithValue) {
            const originalNames = this.specificationsViewModel.find(s => s.name === this.specificationMappingNameWithValue)?.originalNames;
            this.specifications = originalNames ? [originalNames] : [];
          }
        },
      });
  }

  private getCategory(categoryId: string, queryParams: string) {
    this.categoriesService.getCategory(categoryId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (category) => {
        if (!category || !category.categoryId) {
          //const category = this.activatedRoute.snapshot.params['category'];
          //this.getFrame(category);
          return;
        }

        if (category.description?.includes('<hr><hr>')) {
          this.aboveDescription = category.description?.split('<hr><hr>')[0];
          this.belowDescription = category.description?.split('<hr><hr>')[1];
        } else {
          this.aboveDescription = '';
          this.belowDescription = category.description;
        }

        const categoryTitle = category.title || category.name;
        this.title = categoryTitle;
        this.productsTitle = category.name;
        let metaDescription = category.shortDescription || category.description;

        if (this.brands && this.brands.length) {
          const lastBrand = this.brands[this.brands.length - 1];
          metaDescription = `${lastBrand} ${categoryTitle} ${!category.shortDescription ? '| ' + category.description : '| ' + category.shortDescription}`;
          this.title = `${lastBrand} ${categoryTitle}`;
          this.productsTitle = `${lastBrand} ${category.name}`;

          if (this.specificationMappingNameWithValue) {
            const mappedName = this.specificationMappingNameWithValue.includes(';') ? this.specificationMappingNameWithValue.split(';')[0] : this.specificationMappingNameWithValue;
            this.title = `${lastBrand} ${categoryTitle} ${mappedName}`;
            metaDescription = `${lastBrand} ${categoryTitle} ${mappedName} ${!category.shortDescription ? '| ' + category.description : '| ' + category.shortDescription}`;
            this.productsTitle = `${lastBrand} ${category.name} ${mappedName}`;
          }
        } else if (this.specificationMappingNameWithValue) {
          const mappedName = this.specificationMappingNameWithValue.includes(';') ? this.specificationMappingNameWithValue.split(';')[0] : this.specificationMappingNameWithValue;
          this.title = `${categoryTitle} ${mappedName}`;
          metaDescription = `${categoryTitle} ${mappedName} ${!category.shortDescription ? '| ' + category.description : '| ' + category.shortDescription}`;
          this.productsTitle = `${category.name} ${mappedName}`;
        }

        this.breadcrumbService.set('@productsTitle', this.productsTitle);
        this.setMetaData(this.title, 'Category', this.categoriesService.getImageUrl(category.categoryId), metaDescription, this.page);
        this.metaService.setDateMetaTag('date.created', category.createdAt);
        this.metaService.setDateMetaTag('date.updated', category.updatedAt ?? category.createdAt);

        this.refreshProducts(queryParams, category);
      },
      error: result => {
        console.log('getting frame', result);
        const category = this.activatedRoute.snapshot.params['category'];
        this.getFrame(category);
      }
    });
  }

  private getFrame(frameId: string) {
    this.framesService.getById(frameId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (frame) => {
        this.showLoader = false;
        if (!frame) {
          return;
        }

        this.frame = frame;

        this.title = frame.title;
        this.breadcrumbService.set('@productsTitle', this.title);
        let queryParams = `page=${this.page}&needProductIds=true`;

        if (this.frame.description?.includes('<hr><hr>')) {
          this.aboveDescription = this.frame.description?.split('<hr><hr>')[0];
          this.belowDescription = this.frame.description?.split('<hr><hr>')[1];
        } else {
          this.aboveDescription = '';
          this.belowDescription = this.frame.description;
        }

        if (this.brands && this.brands.length) {
          if (typeof this.brands === 'string') {
            this.brands = [this.brands];
          }
          for (let i = 0; i < this.brands.length; i++) {
            const brand = this.brands[i];
            queryParams += `&brands=${brand}`;
          }

          const lastBrand = this.brands[this.brands.length - 1];
          this.title = `${lastBrand} ${frame.title}`;

          if (this.specificationMappingNameWithValue) {
            const mappedName = this.specificationMappingNameWithValue.includes(';') ? this.specificationMappingNameWithValue.split(';')[0] : this.specificationMappingNameWithValue;
            this.title = `${lastBrand} ${frame.title} ${mappedName}`;
          }
        } else if (this.specificationMappingNameWithValue) {
          const mappedName = this.specificationMappingNameWithValue.includes(';') ? this.specificationMappingNameWithValue.split(';')[0] : this.specificationMappingNameWithValue;
          this.title = `${frame.title} ${mappedName}`;
        }

        this.productsTitle = this.title;
        this.breadcrumbService.set('@productsTitle', this.productsTitle);
        this.setMetaData(this.title, 'Frame', this.framesService.getImageUrl(this.frame.id), this.frame.shortDescription || this.frame.description, this.page);

        this.framesService.getCategories(frameId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: (categories) => {
            if (!categories || !categories.length) {
              queryParams += `&frameId=${frameId}`;
            } else {
              const categoryIds = categories.map(
                (c) => `&categoryIds=${c.id}`,
              );
              queryParams = `${categoryIds}`;
            }

            this.refreshProducts(queryParams);
          },
        });
      },
      error: result => {
        this.totalCount = 0;
        this.totalPages = 1;

        this.paginatorConfig.totalItems = 0;
        this.showLoader = false;
        this.products = [];
        this.categoriesViewModel = [];
        this.productIds = [];
      }
    });
  }
}
