import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseProductDetailsComponent } from './product-details.component';

const routes: Routes = [
  {
    path: 'p',
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: ':id',
        component: BaseProductDetailsComponent,
        data: { breadcrumb: { data: 'Third-Level', label: 'Proizvod', alias: 'productName' } },
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
