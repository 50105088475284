import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import { CategoryListResponse, FrameResponse, PaginationResponse, ProductListResponse } from '../http-models';
import { FrameType } from '../enums';
import { CategoryListViewModel, FrameViewModel, ProductListViewModel } from '../view-models';
import { Mappings } from './common.mappings';

@Injectable()
export class FramesService extends BaseClient {

    private pageSize = 10;

    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getFrames(page = 1, pageSize = this.pageSize, searchTerm = "", frameType = FrameType.Slider): Observable<PaginationResponse<FrameViewModel>> {
        return this.get<PaginationResponse<FrameResponse>>(`frames?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&frameType=${frameType}`)
                   .pipe(map(result => {
                        if (!result || !result.items || !result.items.length) {
                            return new PaginationResponse<FrameViewModel>();
                        }
                        const pagination = new PaginationResponse<FrameViewModel>();
                        pagination.items = result.items.map(frame => Mappings.mapFrameResponseToFrameViewModel(frame));
                        pagination.pageSize = result.pageSize;
                        pagination.totalCount = result.totalCount;
                        pagination.totalPages = result.totalPages;

                        return pagination;
                    }));
    }

    getByCode(code: string): Observable<FrameViewModel> {
        return this.get<FrameResponse>(`frames/${code}/by-code`)
                   .pipe(map(frame => {
                        if (frame) {
                            return Mappings.mapFrameResponseToFrameViewModel(frame);
                        }
                        return frame;
                    }));
    }

    getById(id: string): Observable<FrameViewModel> {
        return this.get<FrameResponse>(`frames/${id}/get`)
                .pipe(map(frame => {
                    if (frame) {
                        return Mappings.mapFrameResponseToFrameViewModel(frame);
                    }
                    return frame;
                }));
    }

    getProducts(frameId: string, pageSize = this.pageSize): Observable<ProductListViewModel[]> {
        return this.get<ProductListResponse[]>(`frames/${frameId}/products?pageSize=${pageSize}`)
                   .pipe(map(products => {
                        if (!products || !products.length) {
                            return [];
                        }

                        const productsVm: ProductListViewModel[] = [];

                        for (let i = 0; i < products.length; i++) {
                            const product = products[i];
                            productsVm.push(this.mapProductListToProductListViewModel(product));
                        }

                        return productsVm;
                    }));
    }

    getCategories(frameId: string, pageSize = this.pageSize): Observable<CategoryListViewModel[]> {
        return this.get<CategoryListResponse[]>(`frames/${frameId}/categories?pageSize=${pageSize}`)
                   .pipe(map(categories => {
                        if (!categories || !categories.length) {
                            return [];
                        }
                        const categoriesVm: CategoryListViewModel[] = [];

                        for (let i = 0; i < categories.length; i++) {
                            const categoryResponse = categories[i];
                            categoriesVm.push(this.mapCategoryListResponseToCategoryListViewModel(categoryResponse));
                        }

                        return categoriesVm;
                    }));
    }

    getImageUrl(frameId: string) {
        return `${this.baseApiUrl}/frames/image/${frameId}`;
    }

    private mapCategoryListResponseToCategoryListViewModel(category: CategoryListResponse): CategoryListViewModel {
        const categoryVm = new CategoryListViewModel();
        categoryVm.children = category.children?.map(child => ({
            hasCategories: child.hasCategories,
            hasProducts: child.hasProducts,
            id: child.id,
            imageUrl: child.imageUrl,
            name: child.name,
            parentId: child.parentId,
            parentName: child.parentName,
            parentSlug: child.parentSlug,
            priority: child.priority,
            shortDescription: child.shortDescription,
            slug: child.slug,
            urlQueryParameters: child.urlQueryParameters,
            path: '',
            open: false
        }));
        categoryVm.hasCategories = category.hasCategories;
        categoryVm.hasProducts = category.hasProducts;
        categoryVm.id = category.id;
        categoryVm.imageUrl = category.imageUrl;
        categoryVm.name = category.name;
        categoryVm.parentId = category.parentId;
        categoryVm.parentName = category.parentName;
        categoryVm.parentSlug = category.parentSlug;
        categoryVm.priority = category.priority;
        categoryVm.shortDescription = category.shortDescription;
        categoryVm.slug = category.slug;
        categoryVm.urlQueryParameters = category.urlQueryParameters;
        return categoryVm;
    }

    private mapProductListToProductListViewModel(product: ProductListResponse): ProductListViewModel {
        const productVm = new ProductListViewModel();
        productVm.availability = product.availability;
        productVm.categories = product.categories?.map(category => Mappings.mapCategorProductResponseToCategoryProductViewModel(category));
        productVm.discountInPercentage = product.discountInPercentage;
        productVm.id = product.id;
        productVm.insignificantSpecification = product.insignificantSpecification;
        productVm.isInWishList = product.isInWishList;
        productVm.isNew = product.isNew;
        productVm.isPromoPriceActive = product.isPromoPriceActive;
        productVm.mainPictureUrl = product.mainPictureUrl;
        productVm.name = product.name;
        productVm.pictureUrls = product.pictureUrls;
        productVm.price = product.price;
        productVm.promoPrice = product.promoPrice;
        productVm.recommendedPrice = product.recommendedPrice;
        productVm.slug = product.slug;
        productVm.stickerFrames = product.stickerFrames?.map(frame => ({
            code: frame.code,
            description: frame.description,
            frameType: frame.frameType,
            id: frame.id,
            imageUrl: frame.imageUrl,
            shortDescription: frame.shortDescription,
            slug: frame.slug,
            title: frame.title,
        }));
        productVm.transportCost = product.transportCost;
        productVm.uniqueId = product.uniqueId;

        return productVm;
    }
}
