<header #mainHeader>
  <!-- TOP BAR -->
  <div class="header-top-bar container">
    <div class="d-flex" *ngIf="companyViewService.company">
      <div class="phone">
        <i class="fa fa-solid fa-mobile-screen-button"></i>&nbsp;&nbsp;
        <a class="header-top-a"
          href="tel:{{companyViewService.company.phoneNumber}}">{{companyViewService.company.phoneNumber}}</a>
      </div>
      <div class="mail">
        <i class="fa fa-regular fa-envelope"></i>&nbsp;&nbsp;
        <a class="header-top-a" href="mailto:{{companyViewService.company.email}}">Pisi nam</a>
      </div>
    </div>
    <div class="status">
      <div class="status" (click)="onOpenLogin()">
        <i class="fa fa-solid fa-truck-moving"></i>&nbsp;&nbsp;<a class="header-top-a"
          routerLink="account/orders">Status porudžbine</a>
      </div>
    </div>
  </div>
  <!-- CENTRAL HEADER -->
  <div class="container header-center">
    <div class="logo-image">
    <a routerLink="/" *ngIf="companyViewService.company">
      <img [src]="companyViewService.company.logo" [alt]="companyViewService.company.name" width="123px">
    </a>
  </div>
    <div class="header-search-wrapper" [ngClass]="{'z-index-99': showSearchDropdown}">
      <div class="header-search" [ngClass]="{'no-hover': showSearchDropdown}" #searchDropdown>
        <input placeholder="Pretraži proizvode" type="text" [(ngModel)]="searchTerm"
          (keyup)="onSearchTermChanged($event)" (keyup.enter)="showAllProducts()" />
          <div class="search-loader-delete-icons">
            <span *ngIf="!showLoader && showSearchDropdown" class="search-close-button" tabindex="0" data-toggle="tooltip" data-placement="top"
            title="Poništi pretragu">
            <img (click)="closeSearchDropdown()" class="search-close-button-scroll"
              src="../../../assets/images/header/x-button.svg" alt="x-button">
            <img (click)="closeSearchDropdown()" class="search-close-button-scroll-red"
              src="../../../assets/images/header/x-button-red.svg" alt="x-button-red">
          </span>
          <span *ngIf="showLoader" class="search-loader"></span>
          <div class="search-icon">
            <svg (click)="onSearchIconClicked()" width="18" height="18" viewBox="0 0 34 34"
              xmlns="http://www.w3.org/2000/svg">
              <path style="fill: #222;"
                d="M27.6263 13.8105C27.6263 16.8581 26.6368 19.6733 24.97 21.9573L33.3774 30.3698C34.2075 31.1997 34.2075 32.5476 33.3774 33.3775C32.5473 34.2075 31.1992 34.2075 30.3691 33.3775L21.9616 24.9651C19.6771 26.6383 16.8614 27.621 13.8132 27.621C6.18272 27.621 0 21.4394 0 13.8105C0 6.18152 6.18272 0 13.8132 0C21.4436 0 27.6263 6.18152 27.6263 13.8105ZM13.8132 23.3716C15.069 23.3716 16.3125 23.1243 17.4728 22.6438C18.633 22.1633 19.6872 21.459 20.5752 20.5712C21.4632 19.6834 22.1676 18.6294 22.6482 17.4694C23.1288 16.3093 23.3761 15.0661 23.3761 13.8105C23.3761 12.5549 23.1288 11.3116 22.6482 10.1516C22.1676 8.9916 21.4632 7.93759 20.5752 7.04976C19.6872 6.16193 18.633 5.45766 17.4728 4.97717C16.3125 4.49668 15.069 4.24938 13.8132 4.24938C12.5573 4.24938 11.3138 4.49668 10.1536 4.97717C8.99335 5.45766 7.93914 6.16193 7.05114 7.04976C6.16313 7.93759 5.45873 8.9916 4.97814 10.1516C4.49756 11.3116 4.25021 12.5549 4.25021 13.8105C4.25021 15.0661 4.49756 16.3093 4.97814 17.4694C5.45873 18.6294 6.16313 19.6834 7.05114 20.5712C7.93914 21.459 8.99335 22.1633 10.1536 22.6438C11.3138 23.1243 12.5573 23.3716 13.8132 23.3716Z"
                fill="white" />
            </svg>
          </div>
          </div>
      </div>
      <div class="search-dropdown-new" *ngIf="showSearchDropdown">
        <div style="margin-top: 40px;" *ngIf="!filteredProducts.length">
          <h2>Nema proizvoda</h2>
        </div>
        <ng-container *ngIf="filteredProducts.length">
          <div class="search-categories">
            <div class="title-category-button d-flex justify-content-between">
              <h3 class="dd-title">Kategorije</h3>
            </div>
            <ul class="search-droprown-ul">
              <li class="search-droprown-li" [class.search-droprown-li-selected]="category.selected"
                *ngFor="let category of categories" (click)="onCategorySelected(category)">
                <span *ngIf="category.name">{{category.name}}</span>
                <span *ngIf="!category.name">Bez kategorije</span>
              </li>
            </ul>
            <button class="main_button search-button" (click)="showAllProducts()">Prikaži</button>
          </div>
          <div class="search-products-new">
            <div class="title-close-button d-flex justify-content-between">
              <h3 class="product-title">Proizvodi</h3>
            </div>
            <div class="search-dropdown-products">
              <div *ngFor="let product of filteredProducts">
                <lib-ui-template-product-card class="search-product" (click)="closeSearchDropdown()" [product]="product"
                  [showAddToBasket]="false"></lib-ui-template-product-card>
  
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="basket-wrapper">
      <div class="d-flex icons-wrapper">
        <div class="wishlist" (click)="onOpenWishlistLogin()" *ngIf="!authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <div class="header-icon-number yellow-black" *ngIf="wishItemsCount">{{wishItemsCount}}</div>
            <img src="../../../assets/images/header/hearth.svg" alt="hearth">
          </div>
          <span class="icon-text">Lista želja</span>
        </div>
        <div class="account" (click)="onOpenLogin()" *ngIf="!authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <img src="../../../assets/images/header/account.svg" alt="account">
          </div>
          <span class="icon-text" style="align-self: center;">Prijava</span>
        </div>
        <div class="account" routerLink="/account/user-data" *ngIf="authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <img src="../../../assets/images/header/account.svg" alt="account">
          </div>
          <span class="icon-text" style="align-self: center;">{{authSertice.firstName}}</span>
        </div>
        <div class="basket" style="position: relative !important;">
          <div class="header-icon-wrapper" (click)="onOpenBasketSide()">
            <div class="header-icon-number" *ngIf="basketItemsCount">{{basketItemsCount}}</div>
            <img src="../../../assets/images/header/basket-white.svg" alt="basket-white">
          </div>
          <span style="align-self: center;" (click)="onOpenBasketSide()">Korpa</span>
          <div class="basket-side-wrapper-absolute">
            <lib-ui-template-basket-side-view [opened]="basketViewService.showBasketSide && !stickyVisible"
              (basketSideClosed)="closeBasketSide()"></lib-ui-template-basket-side-view>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-bottom">
    <div class="container d-flex align-items-center">
      <div class="header-bottom-left">
        <div class="hamburger-wrapper" (click)="onMenuToggle($event)">
          <ng-container *ngIf="!dropdownMenuOpened; else closeIcon">
            <div class="hamburger-line">
              <div class="line"></div>
              <div class="line-shorter"></div>
              <div class="line"></div>
            </div>
          </ng-container>
          <ng-template #closeIcon>
            <img src="../../../assets/images/header/x-button.svg" alt="x-button">
          </ng-template>
          <span style="margin-left: 10px; font-weight: bold; color: #222;">Sve kategorije</span>
          <lib-ui-template-menu-dropdown
            [dropdownMenuOpened]="dropdownMenuOpened && !stickyVisible"></lib-ui-template-menu-dropdown>
        </div>
      </div>
      <div class="header-bottom-right">
        <ul class="vertical-center">
          <li>
            <a routerLink="/ps" [queryParams]="{withPromoPrice: true}"
              routerLinkActive="active-header-li">Akcije</a>
          </li>
          <li>
            <a routerLink="/ps" [queryParams]="{showNewProducts: true}" routerLinkActive="active-header-li">Novo u
              ponudi</a>
          </li>
          <li>
            <a routerLink="/page/blogs" routerLinkActive="active-header-li">Blogovi</a>
          </li>
          <li>
            <a routerLink="/page/contact" routerLinkActive="active-header-li">Kontakt</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<header class="scrolled-header" [ngClass]="{'visible':stickyVisible}">
  <div class="container-scrolled">
    <div *ngIf="!mainHeaderVisible">
      <div class="header-bottom-scrolled">
        <div class="container d-flex align-items-center">
          <div class="hamburger-header d-flex align-items-center">
            <div class="hamburger-wrapper me-3" (click)="onMenuToggle($event)">
              <ng-container *ngIf="!dropdownMenuOpened; else closeIcon">
                <div class="hamburger-line-white">
                  <div class="line"></div>
                  <div class="line-shorter"></div>
                  <div class="line"></div>
                </div>
              </ng-container>
              <ng-template #closeIcon>
                <img src="../../../assets/images/header/x-button-white.svg" alt="x-button-white">
              </ng-template>
              <lib-ui-template-menu-dropdown [dropdownMenuOpened]="dropdownMenuOpened && stickyVisible"
                [positionLeft]="'214px'"></lib-ui-template-menu-dropdown>
            </div>
            <a routerLink="/" *ngIf="companyViewService.company" class="mt-2">
              <img [src]="companyViewService.company.logo" [alt]="companyViewService.company.name" class="logo-image-scroll">
            </a>
          </div>
          <!-- MOBILE ONLY -->
          <div class="header-search-wrapper" [ngClass]="{'z-index-99': showSearchDropdown}">
            <div class="header-search" [ngClass]="{'no-hover': showSearchDropdown}" #searchDropdown>
              <input placeholder="Pretraži proizvode" type="text" [(ngModel)]="searchTerm"
                (keyup)="onSearchTermChanged($event)" (keyup.enter)="showAllProducts()" />
                <div class="search-loader-delete-icons">
                  <span *ngIf="!showLoader && showSearchDropdown" class="search-close-button" tabindex="0" data-toggle="tooltip" data-placement="top"
                  title="Poništi pretragu">
                  <img (click)="closeSearchDropdown()" class="search-close-button-scroll"
                    src="../../../assets/images/header/x-button.svg" alt="x-button">
                  <img (click)="closeSearchDropdown()" class="search-close-button-scroll-red"
                    src="../../../assets/images/header/x-button-red.svg" alt="x-button-red">
                  </span>
    
                  <span *ngIf="showLoader" class="search-loader"></span>
                  <div class="search-icon">
                    <svg (click)="onSearchIconClicked()" width="18" height="18" viewBox="0 0 34 34"
                      xmlns="http://www.w3.org/2000/svg">
                      <path style="fill: #222;"
                        d="M27.6263 13.8105C27.6263 16.8581 26.6368 19.6733 24.97 21.9573L33.3774 30.3698C34.2075 31.1997 34.2075 32.5476 33.3774 33.3775C32.5473 34.2075 31.1992 34.2075 30.3691 33.3775L21.9616 24.9651C19.6771 26.6383 16.8614 27.621 13.8132 27.621C6.18272 27.621 0 21.4394 0 13.8105C0 6.18152 6.18272 0 13.8132 0C21.4436 0 27.6263 6.18152 27.6263 13.8105ZM13.8132 23.3716C15.069 23.3716 16.3125 23.1243 17.4728 22.6438C18.633 22.1633 19.6872 21.459 20.5752 20.5712C21.4632 19.6834 22.1676 18.6294 22.6482 17.4694C23.1288 16.3093 23.3761 15.0661 23.3761 13.8105C23.3761 12.5549 23.1288 11.3116 22.6482 10.1516C22.1676 8.9916 21.4632 7.93759 20.5752 7.04976C19.6872 6.16193 18.633 5.45766 17.4728 4.97717C16.3125 4.49668 15.069 4.24938 13.8132 4.24938C12.5573 4.24938 11.3138 4.49668 10.1536 4.97717C8.99335 5.45766 7.93914 6.16193 7.05114 7.04976C6.16313 7.93759 5.45873 8.9916 4.97814 10.1516C4.49756 11.3116 4.25021 12.5549 4.25021 13.8105C4.25021 15.0661 4.49756 16.3093 4.97814 17.4694C5.45873 18.6294 6.16313 19.6834 7.05114 20.5712C7.93914 21.459 8.99335 22.1633 10.1536 22.6438C11.3138 23.1243 12.5573 23.3716 13.8132 23.3716Z"
                        fill="white" />
                    </svg>
                  </div>
                </div>
            </div>
            <div class="search-dropdown-new" *ngIf="showSearchDropdown">
              <div style="margin-top: 40px;" *ngIf="!filteredProducts.length">
                <h2>Nema proizvoda</h2>
              </div>
              <ng-container *ngIf="filteredProducts.length">
                <div class="search-categories">
                  <div class="title-category-button d-flex justify-content-between">
                    <h3 class="dd-title">Kategorije</h3>
                  </div>
                  <ul class="search-droprown-ul">
                    <li class="search-droprown-li" [class.search-droprown-li-selected]="category.selected"
                      *ngFor="let category of categories" (click)="onCategorySelected(category)">
                      <span *ngIf="category.name">{{category.name}}</span>
                      <span *ngIf="!category.name">Bez kategorije</span>
                    </li>
                  </ul>
                  <button class="main_button search-button" (click)="showAllProducts()">Prikaži</button>
                </div>
                <div class="search-products-new">
                  <div class="title-close-button d-flex justify-content-between">
                    <h3 class="product-title">Proizvodi</h3>
                  </div>
                  <div class="search-dropdown-products">
                    <div *ngFor="let product of filteredProducts">
                      <lib-ui-template-product-card class="search-product" (click)="closeSearchDropdown()"
                        [product]="product" [showAddToBasket]="false"></lib-ui-template-product-card>
  
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="d-flex icons-wrapper">
            <div class="wishlist" (click)="onOpenWishlistLogin()" *ngIf="!authSertice.isAuthenticated()">
              <div class="header-icon-wrapper">
                <div class="header-icon-number yellow-black" *ngIf="wishItemsCount">{{wishItemsCount}}</div>
                <img src="../../../assets/images/header/hearth.svg" alt="hearth">
              </div>
              <span class="icon-text">Lista želja</span>
            </div>
            <div class="account" (click)="onOpenLogin()" *ngIf="!authSertice.isAuthenticated()">
              <div class="header-icon-wrapper">
                <img src="../../../assets/images/header/account.svg" alt="account">
              </div>
              <span class="icon-text" style="align-self: center;">Prijava</span>
            </div>
            <div class="account" routerLink="/account/user-data" *ngIf="authSertice.isAuthenticated()">
              <div class="header-icon-wrapper">
                <img src="../../../assets/images/header/account.svg" alt="account">
              </div>
              <span class="icon-text" style="align-self: center;">{{authSertice.firstName}}</span>
            </div>
            <div class="basket" style="position: relative !important;">
              <div class="header-icon-wrapper" (click)="onOpenBasketSide()">
                <div class="header-icon-number" *ngIf="basketItemsCount">{{basketItemsCount}}</div>
                <img src="../../../assets/images/header/basket-white.svg" alt="basket-white">
              </div>
              <span style="align-self: center;" (click)="onOpenBasketSide()">Korpa</span>
              <div class="basket-side-wrapper-absolute">
                <lib-ui-template-basket-side-view [opened]="basketViewService.showBasketSide && stickyVisible"
                  (basketSideClosed)="closeBasketSide()"></lib-ui-template-basket-side-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- MOBILE -->
<header class="mobile-header-container">
  <!-- MOBILE TOP -->
  <div class="header-mobile-top">
    <div class="header-mobile-top-left">
      <div class="hamburger-wrapper" (click)="onMenuToggle($event)">
        <ng-container *ngIf="!dropdownMenuOpened; else closeIcon">
          <div class="hamburger-line">
            <div class="line"></div>
            <div class="line-shorter"></div>
            <div class="line"></div>
          </div>
        </ng-container>
        <ng-template #closeIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm175 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>
        </ng-template>
        <lib-ui-template-menu-dropdown [dropdownMenuOpened]="dropdownMenuOpened"></lib-ui-template-menu-dropdown>
      </div>
      <a class="logo-mobile mt-2" routerLink="/" *ngIf="companyViewService.company">
        <img [src]="companyViewService.company.logo" [alt]="companyViewService.company.name">
      </a>
    </div>
    <div class="mobile-icons-wrapper">
      <div class="account" (click)="onOpenLogin()" *ngIf="!authSertice.isAuthenticated()">
        <div class="header-icon-wrapper">
          <img src="../../../assets/images/header/account.svg" alt="account">
        </div>
      </div>
      <div class="account" routerLink="/account/user-data" *ngIf="authSertice.isAuthenticated()">
        <div class="header-icon-wrapper">
          <img src="../../../assets/images/header/account.svg" alt="account">
        </div>
      </div>
      <div class="wishlist" (click)="onOpenWishlistLogin()" *ngIf="!authSertice.isAuthenticated()">
        <div class="header-icon-wrapper">
          <div class="header-icon-number yellow-black" *ngIf="wishItemsCount">{{wishItemsCount}}</div>
          <img src="../../../assets/images/header/hearth.svg" alt="hearth">
        </div>
      </div>
      <div class="wishlist" routerLink="/account/wishlist" *ngIf="authSertice.isAuthenticated()">
        <div class="header-icon-wrapper">
          <div class="header-icon-number yellow-black" *ngIf="wishItemsCount">{{wishItemsCount}}</div>
          <img src="../../../assets/images/header/hearth.svg" alt="hearth">
        </div>
      </div>
      <div class="basket">
        <div class="header-icon-wrapper" (click)="onOpenBasketSide()">
          <div class="header-icon-number" *ngIf="basketItemsCount">{{basketItemsCount}}</div>
          <img src="../../../assets/images/header/basket-white.svg" alt="basket-white">
        </div>
        <div class="basket-side-wrapper-absolute-mob">
          <lib-ui-template-basket-side-view [opened]="basketViewService.showBasketSide"
            (basketSideClosed)="closeBasketSide()"></lib-ui-template-basket-side-view>
        </div>
      </div>
    </div>
  </div>
  <!-- MOBILE SEARCH -->
  <div class="header-search-center">
    <div class="header-search" #searchDropdown>
      <input placeholder="Pretraži proizvode" type="text" [(ngModel)]="searchTerm"
        (keyup)="onSearchTermChanged($event)" />
        <div class="search-loader-delete-icons">
          <div *ngIf="!showLoader && showSearchDropdown"  class="search-close-button">
            <img (click)="closeSearchDropdown()" class="search-close-button-scroll"
              src="../../../assets/images/header/x-button.svg" alt="x-button">
            <img (click)="closeSearchDropdown()" class="search-close-button-scroll-red"
              src="../../../assets/images/header/x-button-red.svg" alt="x-button-red">
          </div>
          <span *ngIf="showLoader" class="search-loader"></span>
          <div class="search-icon">
            <svg (click)="onSearchIconClicked()" width="18" height="18" viewBox="0 0 34 34"
              xmlns="http://www.w3.org/2000/svg">
              <path style="fill: #222;"
                d="M27.6263 13.8105C27.6263 16.8581 26.6368 19.6733 24.97 21.9573L33.3774 30.3698C34.2075 31.1997 34.2075 32.5476 33.3774 33.3775C32.5473 34.2075 31.1992 34.2075 30.3691 33.3775L21.9616 24.9651C19.6771 26.6383 16.8614 27.621 13.8132 27.621C6.18272 27.621 0 21.4394 0 13.8105C0 6.18152 6.18272 0 13.8132 0C21.4436 0 27.6263 6.18152 27.6263 13.8105ZM13.8132 23.3716C15.069 23.3716 16.3125 23.1243 17.4728 22.6438C18.633 22.1633 19.6872 21.459 20.5752 20.5712C21.4632 19.6834 22.1676 18.6294 22.6482 17.4694C23.1288 16.3093 23.3761 15.0661 23.3761 13.8105C23.3761 12.5549 23.1288 11.3116 22.6482 10.1516C22.1676 8.9916 21.4632 7.93759 20.5752 7.04976C19.6872 6.16193 18.633 5.45766 17.4728 4.97717C16.3125 4.49668 15.069 4.24938 13.8132 4.24938C12.5573 4.24938 11.3138 4.49668 10.1536 4.97717C8.99335 5.45766 7.93914 6.16193 7.05114 7.04976C6.16313 7.93759 5.45873 8.9916 4.97814 10.1516C4.49756 11.3116 4.25021 12.5549 4.25021 13.8105C4.25021 15.0661 4.49756 16.3093 4.97814 17.4694C5.45873 18.6294 6.16313 19.6834 7.05114 20.5712C7.93914 21.459 8.99335 22.1633 10.1536 22.6438C11.3138 23.1243 12.5573 23.3716 13.8132 23.3716Z"
                fill="white" />
            </svg>
          </div>
        </div>
      <!-- Search dropdown -->
      <div class="search-dropdown-new" *ngIf="showSearchDropdown">
        <div style="margin-top: 40px;" *ngIf="!filteredProducts.length">
          <h2>Nema proizvoda</h2>
        </div>
        <ng-container *ngIf="filteredProducts.length">
          <!-- Categories-->
          <div class="search-categories-new-scroll">
            <div class="title-close-button d-flex justify-content-between">
              <h3 class="dd-title">Kategorije</h3>
            </div>
            <ul class="search-droprown-ul">
              <li class="search-droprown-li" [class.search-droprown-li-selected]="category.selected"
                *ngFor="let category of categories" (click)="onCategorySelected(category)">
                <span *ngIf="category.name">{{category.name}}</span>
                <span *ngIf="!category.name">Bez kategorije</span>
              </li>
            </ul>
          </div>
          <!-- Products -->
          <div class="search-products-new">
            <h3 class="dd-title">Proizvodi</h3>
            <div class="search-dropdown-products">
              <div *ngFor="let product of filteredProducts">
                <lib-ui-template-product-card (click)="closeSearchDropdown()"
                  [product]="product"></lib-ui-template-product-card>
              </div>
            </div>
            <button class="main_button search-button" (click)="showAllProducts()">Prikaži</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</header>
<div class="header-mobile-bottom">
  <ul>
    <li>
      <a routerLink="/ps" [queryParams]="{withPromoPrice: true}" routerLinkActive="active-header-li">Akcije</a>
    </li>
    <li>
      <a routerLink="/ps" [queryParams]="{showNewProducts: true}" routerLinkActive="active-header-li">Novo u
        ponudi</a>
    </li>
    <li>
      <a routerLink="/page/blogs" routerLinkActive="active-header-li">Blogovi</a>
    </li>
    <li>
      <a routerLink="/page/contact" routerLinkActive="active-header-li">Kontakt</a>
    </li>
    <li>
      <!-- <a routerLink="/upustvo-za-kupovinu" routerLinkActive="active-header-li">Odloženo plaćanje</a> -->
    </li>
  </ul>
</div>
<div *ngIf="dropdownMenuOpened" (click)="onCloseMenu()" class="outside-container"></div>
<div class="search-dropdown-wrapper" *ngIf="showSearchDropdown || basketViewService.showBasketSide"
  (click)="closeSearchDropdown()"></div>
<lib-ui-template-login (registerClick)="onOpenRegister()" (outsideClick)="this.loginOpened = false"
  [showDialog]="loginOpened"></lib-ui-template-login>
<lib-ui-template-register (loginClick)="onOpenLogin()" (outsideClick)="this.registerOpened = false"
  *ngIf="registerOpened"></lib-ui-template-register>
<lib-ui-template-wihslist-login (wishlistLoginClick)="onOpenWishlistLogin()"
  (outsideClick)="this.wishlistLoginOpened = false" [showDialog]="wishlistLoginOpened"></lib-ui-template-wihslist-login>