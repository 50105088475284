<div class="basket-product-item">
  <div class="basket-flex">
    <div class="basket-product-left" >
      <div class="img-wrapper">
        <img [src]="product.productPictureUrl">
      </div>
      <div class="product-quantity">
        <a (click)="decreaseQuantity()">-</a>
        {{ product.quantity }}
        <a (click)="increaseQuantity()">+</a>
      </div>
    </div>
    <div class="basket-product-right">
      <div>
        <span class="product-title">{{product.productName}}</span>
        <div class="prices">
          <span class="regular-price {{!!product.promoPrice ? 'crossed' : ''}}">{{product.price | number:'1.2-2'}}</span>
          <span *ngIf="!!product.promoPrice" class="action-price">{{product.promoPrice | number:'1.2-2'}}</span>
        </div>
      </div>
      <i class="fa fa-solid fa-xmark remove-from-basket" (click)="removeFromBasket()"></i>
    </div>
  </div>
</div>