import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  OrderStatus,
  OrderStatus2LabelMapping,
  OrdersService,
  PaymentMethod2LabelMapping,
  OrderListViewModel,
  PaginationViewModel,
} from '@b2c-frontend/http';
import { ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Observable, shareReplay, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class BaseOrdersComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  currentOrderStatus: any;

  orders$!: Observable<PaginationViewModel<OrderListViewModel>>;
  totalCount = 0;
  totalPages = 1;

  public PaymentMethod2LabelMapping = PaymentMethod2LabelMapping;
  public OrderStatus2LabelMapping = OrderStatus2LabelMapping;

  public paginatorConfig: PaginationInstance = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private ordersService: OrdersService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.ngUnsubscribe),
        shareReplay(1))
      .subscribe({
        next: (params) => {
          const orderStatus = params['status'];
          this.currentOrderStatus = orderStatus;
          this.refresh(1, orderStatus);
        },
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onPageChanged(page: number) {
    this.paginatorConfig.currentPage = page;
    this.refresh(page, this.currentOrderStatus);
  }

  private refresh(page: number, status?: OrderStatus | null) {
    if (status && OrderStatus[status] === OrderStatus[OrderStatus.Created]) {
      status = null;
    }

    this.orders$ = this.ordersService.getOrders(page, 20, '', status);
    this.orders$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.totalCount = result?.totalCount ?? 0;
          this.totalPages = result?.totalPages ?? 1;

          this.paginatorConfig.totalItems = this.totalCount;
          this.paginatorConfig.itemsPerPage = result.pageSize;
        },
      });
  }

  get OrderStatus() {
    return OrderStatus;
  }
}
