import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseClient } from '../base-client';
import { map, Observable } from 'rxjs';
import { WishResponse } from '../http-models';
import { WishViewModel } from '../view-models';

@Injectable()
export class WishesService extends BaseClient {
  
  constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
    super(http, baseApiUrl);
  }

  addItem(request: any): Observable<WishViewModel> {
    return this.post<WishResponse>(`wishes/add-item`, request)
               .pipe(map(wish => {
                  if (wish) {
                    return this.mapWishResponseToWishViewModel(wish);
                  }
                  return wish;
                }));
  }

  deleteItem(productId: string): Observable<WishViewModel> {
    return this.delete<WishResponse>(`wishes/delete-item/${productId}`)
              .pipe(map(wish => {
                if (wish) {
                  return this.mapWishResponseToWishViewModel(wish);
                }
                return wish;
              }));
  }

  getWishList(): Observable<WishViewModel> {
    return this.get<WishResponse>(`wishes/get?q=${Date.now()}`)
              .pipe(map(wish => {
                if (wish) {
                  return this.mapWishResponseToWishViewModel(wish);
                }
                return wish;
              }));
  }

  private mapWishResponseToWishViewModel(wish: WishResponse): WishViewModel {
    const wishVm = new WishViewModel();
    wishVm.items = wish.items?.map(item => ({
      productId: item.productId,
      productName: item.productName,
      price: item.price,
      promoPrice: item.promoPrice,
      productPictureUrl: item.productPictureUrl
    }));
    wishVm.userId = wish.userId;
    wishVm.wishId = wish.wishId;

    return wishVm;
  }
}
