import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductsService } from '@b2c-frontend/http';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-products-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
})
export class BaseProductBrandsComponent implements OnInit, OnChanges, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  
  brandsViewModel: string[] = [];
  isBrandsOpened = false;

  @Input() productIds?: string[] = [];
  @Input() brands?: string[] = [];
  @Output() brandChanged = new EventEmitter<any>();

  constructor(private productsService: ProductsService) {}

  ngOnInit(): void {
    this.getBrands();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productIds']) {
      this.getBrands();
    }
    if (changes['brands']) {
      if (!this.brands || !this.brands.length) {
        this.brandsViewModel = [];
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onOpenBrands() {
    this.isBrandsOpened = !this.isBrandsOpened;
  }

  isBrandSelected(value: string) {
    return this.brands?.includes(value);
  }

  onBrandChanged(brand: string) {
    this.brandChanged.emit({ checked: !this.isBrandSelected(brand), value: brand });
  }

  private getBrands() {
    this.brandsViewModel = [];

    if (this.brands && this.brands.length) {
      return;
    }

    if (!this.productIds || !this.productIds.length) {
      return;
    }
    
    this.productsService.getBrands(this.productIds).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (brands) => {
        this.brandsViewModel = brands?.map((b) => b.brand) ?? [];
        this.isBrandsOpened = true;
      },
    });
  }
}
