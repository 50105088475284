<section class="container">
    <div class="wrapper">
        <h3>Unesite email adresu za odjavu sa newslettera</h3>
        <form [formGroup]="emailUnsibscribeForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <lib-ui-template-text-input
                        formControlName="email"
                        [label]="'Email adresa'"
                        [type]="'email'"
                        ></lib-ui-template-text-input>
                </div>
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <button type="submit" class="main_button submit-button">Potvrdi</button>
                </div>
            </div>
        </form>
    </div>
</section>
