import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-ui-template-ng-select-input',
  templateUrl: './ng-select-input.component.html',
  styleUrls: ['./ng-select-input.component.scss']
})
export class BaseNgSelectInputComponent {

  touched = false;
  @Input() items: string[] = [];
  @Input() validationMessage = "";
  @Input() placeholder = "";
  @Input() order!: any;
  @Input() param1!: string;
  @Input() param2!: string;

  @Output() changed = new EventEmitter<any>();
  @Output() cleared = new EventEmitter<any>();

  onInputFocused() {
    this.touched = true;
  }

  onChange() {
    this.changed.emit(true);
  }

  onClear() {
    this.cleared.emit(true);
  }
}
