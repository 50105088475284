<section class="container" *ngIf="brands$ | async as brands">
    <div class="wrapper">
        <div class="row brand-row">
            <ng-container *ngFor="let brand of brands">
                <ng-template #brandTemplate>
                    <div class="col-1 brand" (click)="navigateToBrand(brand)">
                        <strong>{{brand.brand}}</strong>
                    </div>
                  </ng-template>
                  <b2c-frontend-a [relativeHref]="getHref(brand)" [content]="brandTemplate">
                  </b2c-frontend-a>
            </ng-container>
        </div>
    </div>
  </section>
  