<section class="container" *ngIf="frame">
    <div class="wrapper">
        <div class="container-description">
            <div class="title">
                <h1>{{frame.title}}</h1>
                <div class="title-line"></div>
            </div>
            <div [innerHtml]="description"></div>
        </div>
        <div class="category-wrapper" routerLink="/page/blogs" *ngIf="categories$ | async as categories">
            <lib-ui-template-category-second-card *ngFor="let category of categories" [category]="category"></lib-ui-template-category-second-card>
            <br/>
            <a class="link" [routerLink]="'/ps/c/' + frame.slug" [queryParams]="{frameId: frame.id}" *ngIf="categories && categories.length > 0">
                <div class="button">Pogledaj kompletnu ponudu</div>
            </a>
        </div>
        <br/>
    </div>
</section>
<section class="container" *ngIf="!frame">
    <div class="wrapper">
        Blog nije pronađen
    </div>
</section>
