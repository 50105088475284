export class Constants {
    public static PopularCategoriesCode = "Home-PopularCategories";
    public static PopularCategoryCode = "Home-PopularCategory";
    public static OneCategoryCode = "Home-OneCategory";
    public static ProductsOnSaleCode = "Home-ProductsOnSale";
    public static AboutUsCode = "about-us";
    public static ComplaintsCode = "complaints";
    public static ConditionsForOnlineShoppingCode = "conditions-for-online-shopping";
    public static CookiePolicyCode = "cookie-policy";
    public static PaymentMethodCode = "payment-method";
    public static PrivacyPolicyCode = "privacy-policy";
    public static RightOfWithdrawalCode = "right-of-withdrawal";
    public static ShopingInstructionsCode = "shopping-instructions";
    public static TermsAndConditionsCode = "terms-and-conditions";
    public static WayOfDeliveryCode = "way-of-delivery";
    public static DefaultLanguageKey = 'b2c-default-language';
}