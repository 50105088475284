import { createMap, forMember, mapFrom, MappingProfile } from "@automapper/core";
import {
  BasketItemResponse,
  BasketResponse,
  BrandResponse,
  CategoryListResponse,
  CategoryProductResponse,
  CategorySearchedListResponse,
  CategoryGroupSelectResponse,
  CompanyResponse,
  FrameResponse,
  ImageUrlResponse,
  OrderListResponse,
  PlacesResponse,
  ProductDetailsResponse,
  ProductListResponse,
  SpecificationListResponse,
  UserResponse,
  WishItemResponse,
  WishResponse,
  SpecificationValueListResponse
} from '../http-models';
import {
  BasketItemViewModel,
  BasketViewModel,
  BrandViewModel,
  CategoryListViewModel,
  CategoryProductViewModel,
  CategorySearchedListViewModel,
  CategoryGroupSelectViewModel,
  CompanyViewModel,
  FrameViewModel,
  ImageUrlViewModel,
  OrderListViewModel,
  PlacesViewModel,
  ProductDetailsViewModel,
  ProductListViewModel,
  SpecificationListViewModel,
  UserViewModel,
  WishItemViewModel,
  WishViewModel,
  SpecificationValueListViewModel
} from "../view-models";

export const basketViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, BasketResponse, BasketViewModel,
    forMember((d) => d.basketId, mapFrom((s) => s.basketId)),
    forMember((d) => d.total, mapFrom((s) => s.total)),
    forMember((d) => d.itemsCount, mapFrom((s) => s.itemsCount)),
    forMember((d) => d.transportCost, mapFrom((s) => s.transportCost)),
    forMember((d) => d.totalSaving, mapFrom((s) => s.totalSaving)),
    forMember((d) => d.items, mapFrom((s) => s.items))
  );
};

export const basketItemViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, BasketItemResponse, BasketItemViewModel,
    forMember((d) => d.price, mapFrom((s) => s.price)),
    forMember((d) => d.productId, mapFrom((s) => s.productId)),
    forMember((d) => d.productUniqueId, mapFrom((s) => s.productUniqueId)),
    forMember((d) => d.linkedProductId, mapFrom((s) => s.linkedProductId)),
    forMember((d) => d.productName, mapFrom((s) => s.productName)),
    forMember((d) => d.productPictureUrl, mapFrom((s) => s.productPictureUrl)),
    forMember((d) => d.isPromoPriceActive, mapFrom((s) => s.isPromoPriceActive)),
    forMember((d) => d.promoPrice, mapFrom((s) => s.promoPrice)),
    forMember((d) => d.quantity, mapFrom((s) => s.quantity)),
    forMember((d) => d.sum, mapFrom((s) => s.sum))
  );
};

export const categoryListViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, CategoryListResponse, CategoryListViewModel,
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.parentId, mapFrom((s) => s.parentId)),
    forMember((d) => d.parentName, mapFrom((s) => s.parentName)),
    forMember((d) => d.parentSlug, mapFrom((s) => s.parentSlug)),
    forMember((d) => d.imageUrl, mapFrom((s) => s.imageUrl)),
    forMember((d) => d.shortDescription, mapFrom((s) => s.shortDescription)),
    forMember((d) => d.urlQueryParameters, mapFrom((s) => s.urlQueryParameters)),
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.slug, mapFrom((s) => s.slug)),
    forMember((d) => d.hasCategories, mapFrom((s) => s.hasCategories)),
    forMember((d) => d.hasProducts, mapFrom((s) => s.hasProducts)),
    forMember((d) => d.children, mapFrom((s) => s.children)),
    forMember((d) => d.priority, mapFrom((s) => s.priority))
  );
};

export const companyViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, CompanyResponse, CompanyViewModel,
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.defaultCurrency, mapFrom((s) => s.defaultCurrency)),
    forMember((d) => d.taxNumber, mapFrom((s) => s.taxNumber)),
    forMember((d) => d.identificationNumber, mapFrom((s) => s.identificationNumber)),
    forMember((d) => d.transportCostLimit, mapFrom((s) => s.transportCostLimit)),
    forMember((d) => d.fullAddress, mapFrom((s) => s.fullAddress)),
    forMember((d) => d.email, mapFrom((s) => s.email)),
    forMember((d) => d.phoneNumber, mapFrom((s) => s.phoneNumber)),
    forMember((d) => d.mobilePhoneNumber, mapFrom((s) => s.mobilePhoneNumber)),
    forMember((d) => d.logo, mapFrom((s) => s.logo)),
    forMember((d) => d.webSiteUrl, mapFrom((s) => s.webSiteUrl)),
    forMember((d) => d.facebookUrl, mapFrom((s) => s.facebookUrl)),
    forMember((d) => d.instagramUrl, mapFrom((s) => s.instagramUrl)),
    forMember((d) => d.currentBankAccounts, mapFrom((s) => s.currentBankAccounts)),
    forMember((d) => d.onlinePaymentUrl, mapFrom((s) => s.onlinePaymentUrl)),
    forMember((d) => d.onlinePaymentShopId, mapFrom((s) => s.onlinePaymentShopId)),
    forMember((d) => d.enableOnlinePaymentApi, mapFrom((s) => s.enableOnlinePaymentApi)),
    forMember((d) => d.enablePayPalApi, mapFrom((s) => s.enablePayPalApi)),
    forMember((d) => d.payPalClientId, mapFrom((s) => s.payPalClientId)),
    forMember((d) => d.payPalCurrency, mapFrom((s) => s.payPalCurrency))
  );
};

export const frameViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, FrameResponse, FrameViewModel,
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.imageUrl, mapFrom((s) => s.imageUrl)),
    forMember((d) => d.code, mapFrom((s) => s.code)),
    forMember((d) => d.shortDescription, mapFrom((s) => s.shortDescription)),
    forMember((d) => d.description, mapFrom((s) => s.description)),
    forMember((d) => d.frameType, mapFrom((s) => s.frameType)),
    forMember((d) => d.title, mapFrom((s) => s.title)),
    forMember((d) => d.slug, mapFrom((s) => s.slug))
  );
};

export const orderListViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, OrderListResponse, OrderListViewModel,
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.companyName, mapFrom((s) => s.companyName)),
    forMember((d) => d.paymentMethod, mapFrom((s) => s.paymentMethod)),
    forMember((d) => d.fullName, mapFrom((s) => s.fullName)),
    forMember((d) => d.number, mapFrom((s) => s.number)),
    forMember((d) => d.status, mapFrom((s) => s.status)),
    forMember((d) => d.totalPrice, mapFrom((s) => s.totalPrice))
  );
};

export const productListViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, ProductListResponse, ProductListViewModel,
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.uniqueId, mapFrom((s) => s.uniqueId)),
    forMember((d) => d.isNew, mapFrom((s) => s.isNew)),
    forMember((d) => d.isPromoPriceActive, mapFrom((s) => s.isPromoPriceActive)),
    forMember((d) => d.isInWishList, mapFrom((s) => s.isInWishList)),
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.slug, mapFrom((s) => s.slug)),
    forMember((d) => d.pictureUrls, mapFrom((s) => s.pictureUrls)),
    forMember((d) => d.mainPictureUrl, mapFrom((s) => s.mainPictureUrl)),
    forMember((d) => d.stickerFrames, mapFrom((s) => s.stickerFrames)),
    forMember((d) => d.price, mapFrom((s) => s.price)),
    forMember((d) => d.transportCost, mapFrom((s) => s.transportCost)),
    forMember((d) => d.availability, mapFrom((s) => s.availability)),
    forMember((d) => d.discountInPercentage, mapFrom((s) => s.discountInPercentage)),
    forMember((d) => d.categories, mapFrom((s) => s.categories?.map(c => ({
      categoryId: c.id,
      name: c.name,
      slug: c.slug,
      imageUrl: c.imageUrl,
      title: c.title,
      shortDescription: c.shortDescription,
      description: c.description,
      priority: c.priority,
      selected: false,
      hasChildren: c.hasChildren,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt
    })))),
    forMember((d) => d.promoPrice, mapFrom((s) => s.promoPrice)),
    forMember((d) => d.recommendedPrice, mapFrom((s) => s.recommendedPrice)),
    forMember((d) => d.insignificantSpecification, mapFrom((s) => s.insignificantSpecification)),
  );
};

export const productViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, ProductDetailsResponse, ProductDetailsViewModel,
    forMember((d) => d.availability, mapFrom((s) => s.availability)),
    forMember((d) => d.barCode, mapFrom((s) => s.barCode)),
    forMember((d) => d.slug, mapFrom((s) => s.slug)),
    forMember((d) => d.supplierName, mapFrom((s) => s.supplierName)),
    forMember((d) => d.code, mapFrom((s) => s.code)),
    forMember((d) => d.currency, mapFrom((s) => s.currency)),
    forMember((d) => d.description, mapFrom((s) => s.description)),
    forMember((d) => d.specificationsAsString, mapFrom((s) => s.specificationsAsString)),
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.promoPrice, mapFrom((s) => s.promoPrice)),
    forMember((d) => d.saving, mapFrom((s) => s.saving)),
    forMember((d) => d.price, mapFrom((s) => s.price)),
    forMember((d) => d.installmentsPrice, mapFrom((s) => s.installmentsPrice)),
    forMember((d) => d.transportCost, mapFrom((s) => s.transportCost)),
    forMember((d) => d.productId, mapFrom((s) => s.productId)),
    forMember((d) => d.url, mapFrom((s) => s.url)),
    forMember((d) => d.isNew, mapFrom((s) => s.isNew)),
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.isPromoPriceActive, mapFrom((s) => s.isPromoPriceActive)),
    forMember((d) => d.discountInPercentage, mapFrom((s) => s.discountInPercentage)),
    forMember((d) => d.promoPriceStartDate, mapFrom((s) => s.promoPriceStartDate)),
    forMember((d) => d.promoPriceEndDate, mapFrom((s) => s.promoPriceEndDate)),
    forMember((d) => d.mainPictureUrl, mapFrom((s) => s.mainPictureUrl)),
    forMember((d) => d.specifications, mapFrom((s) => s.specifications)),
    forMember((d) => d.groupedSpecifications, mapFrom((s) => s.groupedSpecifications)),
    forMember((d) => d.stickerFrames, mapFrom((s) => s.stickerFrames)),
    forMember((d) => d.imageUrls, mapFrom((s) => s.imageUrls)),
    forMember((d) => d.categories, mapFrom((s) => s.categories?.map(c => ({
      categoryId: c.id,
      name: c.name,
      slug: c.slug,
      imageUrl: c.imageUrl,
      title: c.title,
      shortDescription: c.shortDescription,
      description: c.description,
      priority: c.priority,
      selected: false,
      hasChildren: c.hasChildren,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt
    }))))
  );
};

export const imageUrlViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, ImageUrlResponse, ImageUrlViewModel,
    forMember((d) => d.is3D, mapFrom((s) => s.is3D)),
    forMember((d) => d.isSelected, mapFrom((s) => s.isSelected)),
    forMember((d) => d.isVideo, mapFrom((s) => s.isVideo)),
    forMember((d) => d.url, mapFrom((s) => s.url)),
  );
}

export const wishViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, WishResponse, WishViewModel,
    forMember((d) => d.items, mapFrom((s) => s.items)),
    forMember((d) => d.userId, mapFrom((s) => s.userId)),
    forMember((d) => d.wishId, mapFrom((s) => s.wishId))
  );
};

export const categoryProductViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, CategoryProductResponse, CategoryProductViewModel,
    forMember((d) => d.categoryId, mapFrom((s) => s.id)),
    forMember((d) => d.imageUrl, mapFrom((s) => s.imageUrl)),
    forMember((d) => d.title, mapFrom((s) => s.title)),
    forMember((d) => d.shortDescription, mapFrom((s) => s.shortDescription)),
    forMember((d) => d.description, mapFrom((s) => s.description)),
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.slug, mapFrom((s) => s.slug)),
    forMember((d) => d.createdAt, mapFrom((s) => s.createdAt)),
    forMember((d) => d.updatedAt, mapFrom((s) => s.updatedAt)),
    forMember((d) => d.hasChildren, mapFrom((s) => s.hasChildren)),
    forMember((d) => d.priority, mapFrom((s) => s.priority))
  );
};

export const productListViewModelFromWishResponseMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, WishItemViewModel, ProductListViewModel,
    forMember((d) => d.id, mapFrom((s) => s.productId)),
    forMember((d) => d.name, mapFrom((s) => s.productName)),
    forMember((d) => d.price, mapFrom((s) => s.price)),
    forMember((d) => d.mainPictureUrl, mapFrom((s) => s.productPictureUrl)),
    forMember((d) => d.promoPrice, mapFrom((s) => s.promoPrice)),
    forMember((d) => d.isInWishList, mapFrom((s) => true)),
  );
};

export const wishItemViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, WishItemResponse, WishItemViewModel,
    forMember((d) => d.price, mapFrom((s) => s.price)),
    forMember((d) => d.productId, mapFrom((s) => s.productId)),
    forMember((d) => d.productName, mapFrom((s) => s.productName)),
    forMember((d) => d.productPictureUrl, mapFrom((s) => s.productPictureUrl)),
    forMember((d) => d.promoPrice, mapFrom((s) => s.promoPrice))
  );
};

export const userViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, UserResponse, UserViewModel,
    forMember((d) => d.apartmentNumber, mapFrom((s) => s.apartmentNumber)),
    forMember((d) => d.city, mapFrom((s) => s.city)),
    forMember((d) => d.companyName, mapFrom((s) => s.companyName)),
    forMember((d) => d.companyTaxNumber, mapFrom((s) => s.companyTaxNumber)),
    forMember((d) => d.email, mapFrom((s) => s.email)),
    forMember((d) => d.floor, mapFrom((s) => s.floor)),
    forMember((d) => d.firstName, mapFrom((s) => s.firstName)),
    forMember((d) => d.lastName, mapFrom((s) => s.lastName)),
    forMember((d) => d.fullName, mapFrom((s) => s.fullName)),
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.mobilePhoneNumber, mapFrom((s) => s.mobilePhoneNumber)),
    forMember((d) => d.number, mapFrom((s) => s.number)),
    forMember((d) => d.phoneNumber, mapFrom((s) => s.phoneNumber)),
    forMember((d) => d.postalCode, mapFrom((s) => s.postalCode)),
    forMember((d) => d.state, mapFrom((s) => s.state)),
    forMember((d) => d.street, mapFrom((s) => s.street)),
    forMember((d) => d.username, mapFrom((s) => s.username))
  );
};

export const specificationListViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, SpecificationListResponse, SpecificationListViewModel,
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.priority, mapFrom((s) => s.priority)),
    forMember((d) => d.originalNames, mapFrom((s) => s.originalNames)),
    forMember((d) => d.areValuesLoaded, mapFrom((s) => s.areValuesLoaded)),
    forMember((d) => d.values, mapFrom((s) => s.values))
  );
};

export const specificationValueListViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, SpecificationValueListResponse, SpecificationValueListViewModel,
    forMember((d) => d.mappingName, mapFrom((s) => s.mappingName)),
    forMember((d) => d.originalNames, mapFrom((s) => s.originalNames))
  );
};

export const categorySearchedListViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, CategorySearchedListResponse, CategorySearchedListViewModel,
    forMember((d) => d.categoryId, mapFrom((s) => s.categoryId)),
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.slug, mapFrom((s) => s.slug)),
    forMember((d) => d.priority, mapFrom((s) => s.priority)),
    forMember((d) => d.products, mapFrom((s) => s.products.map(p => ({
      id: p.id,
      uniqueId: p.uniqueId,
      name: p.name,
      price: p.price,
      slug: p.slug,
      transportCost: p.transportCost,
      isNew: p.isNew,
      isPromoPriceActive: p.isPromoPriceActive,
      isInWishList: p.isInWishList,
      promoPrice: p.promoPrice,
      recommendedPrice: p.recommendedPrice,
      pictureUrls: p.pictureUrls,
      mainPictureUrl: p.mainPictureUrl,
      saving: undefined,
      discountInPercentage: p.discountInPercentage,
      selected: false,
      availability: p.availability,
      stickerFrames: p.stickerFrames?.map(f => ({
        id: f.id,
        title: f.title,
        slug: f.slug,
        imageUrl: f.imageUrl,
        frameType: f.frameType,
        shortDescription: f.shortDescription,
        code: f.code,
        description: f.description
      })),
      insignificantSpecification: p.insignificantSpecification,
      categories: p.categories?.map(c => ({
        categoryId: c.id,
        name: c.name,
        slug: c.slug,
        imageUrl: c.imageUrl,
        title: c.title,
        shortDescription: c.shortDescription,
        description: c.description,
        priority: c.priority,
        selected: false,
        hasChildren: c.hasChildren,
        createdAt: c.createdAt,
        updatedAt: c.updatedAt
      }))
    }))))
  );
};

export const placesViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, PlacesResponse, PlacesViewModel,
    forMember((d) => d.cargoAvailable, mapFrom((s) => s.cargoAvailable)),
    forMember((d) => d.city, mapFrom((s) => s.city)),
    forMember((d) => d.postalCode, mapFrom((s) => s.postalCode)),
    forMember((d) => d.standardAvailable, mapFrom((s) => s.standardAvailable)),
    forMember((d) => d.street, mapFrom((s) => s.street))
  );
};

export const brandViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, BrandResponse, BrandViewModel,
    forMember((d) => d.brand, mapFrom((s) => s.brand)),
    forMember((d) => d.lastModifiedAt, mapFrom((s) => s.lastModifiedAt))
  );
};

export const categorySelectViewModelMappingProfile: MappingProfile = (mapper) => {
  createMap(mapper, CategoryGroupSelectResponse, CategoryGroupSelectViewModel,
    forMember((d) => d.id, mapFrom((s) => s.id)),
    forMember((d) => d.name, mapFrom((s) => s.name)),
    forMember((d) => d.priceMargin, mapFrom((s) => s.priceMargin))
  );
};