<ng-container *ngIf="basket && basket.items && basket.items.length > 0">
  <div class="table-responsive">
    <table class="cart-table">
      <thead>
        <tr>
          <th class="text-center">Slika</th>
          <th>Naziv proizvoda</th>
          <th class="text-right">Količina</th>
          <th class="text-right">Cena</th>
          <th class="text-right">Ukupno</th>
          <th *ngIf="canDelete"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of basket.items">
          <td class="text-center"><img [src]="product.productPictureUrl" alt="Slika proizvoda"  width="100"></td>
          <td class="text-limit">{{ product.productName }}</td>
          <td class="text-center">
            <div class="align-right">
              <a (click)="decreaseQuantity(product)">-</a>
              <div>{{ product.quantity }}</div>
              <a (click)="increaseQuantity(product)">+</a>
            </div>
          </td>
          <td class="text-right" *ngIf="!product.promoPrice">{{ product.price }}</td>
          <td class="text-right" *ngIf="product.promoPrice">{{ product.promoPrice }}</td>
          <td class="text-right">{{ product.sum }}</td>
          <td class="text-center" *ngIf="canDelete"><i class="fa fa-trash" style="font-size: 20px;" (click)="removeRow(product)"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>