import { Component, OnInit } from '@angular/core';
import { ProductsService, BrandViewModel } from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MetaService, MetaTagComponent } from '@b2c-frontend/seo';
import { WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'lib-ui-template-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BaseBrandsComponent extends MetaTagComponent implements OnInit {
    brands$!: Observable<BrandViewModel[]>;

    constructor(
        private router: Router,
        private productsService: ProductsService,
        windowService: WindowService,
        metaService: MetaService) {
        super(metaService, windowService);
    }

    ngOnInit() {
        this.metaService.setRobotsMetaTag("noindex, follow");
        this.brands$ = this.productsService.getBrands();
    }

    navigateToBrand(brand: BrandViewModel) {
        this.router.navigate([`/ps/b/${brand.brand.trim()}`]);
    }

    getHref(brand: BrandViewModel) {
        return `/ps/b/${brand.brand.trim()}`;
    }
}
