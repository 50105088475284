import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersService } from './services/users.service';
import { BasketService } from './services/basket.service';
import { AuthService } from './services/auth.service';
import { FramesService } from './services/frames.service';
import { OrdersService } from './services/orders.service';
import { ProductsService } from './services/products.service';
import { WishesService } from './services/wishes.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoadingInterceptor } from './interceptors/loading.interceptors';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BasketViewService } from './services/basket-view.service';
import { AuthGuardService } from './services/auth-guard.service';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { WishesViewService } from './services/wishes-view.service';
import { CategoriesService } from './services/categories.service';
import { SpecificationsService } from './services/specifications.service';
import { CompanyService } from './services/company.service';
import { UserViewService } from './services/user-view.service';
import { CompanyViewService } from './services';
import { EmailService } from './services/email-view.service';
import { ImageService } from './services/image.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthService,
    AuthGuardService,
    BasketService,
    BasketViewService,
    CategoriesService,
    CompanyService,
    FramesService,
    OrdersService,
    ProductsService,
    UsersService,
    WishesService,
    WishesViewService,
    JwtHelperService,
    SpecificationsService,
    UserViewService,
    CompanyViewService,
    EmailService,
    ImageService,
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  ]
})
export class HttpModule {}
