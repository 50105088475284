<div class="dropdown"
     (click)="closeDropdown()"
     [style.visibility]="dropdownMenuOpened ? 'visible' : 'hidden'"
     [style.left]="positionLeft">
  <ul class="dropdown-ul">
    <ng-container *ngFor="let category of categories">
      <li class="dropdown-li" (mouseenter)="onMainCategoryMouseEnter(category)" (click)="onMainCategoryClicked(category)">
        <ng-template #linkWrapperTemplate>
          <div class="link-wrapper">
            <span class="dropdown-a">{{ category.name }}</span>
            <svg class="dropdown-svg" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6.21053L1 12" stroke="black" />
            </svg>
          </div>
        </ng-template>
        <b2c-frontend-a [relativeHref]="getHref(category)" [content]="linkWrapperTemplate">
        </b2c-frontend-a>
      </li>
      <ul class="nested-ul" [style.display]="category.open ? 'block' : 'none'">
        <li class="dropdown-li" (click)="seeAllMainCategories(category)">
          <ng-template #dropdownAllTemplate>
            <div class="dropdown-all"><span>Vidi sve iz {{ category.name }}</span></div>
          </ng-template>
          <b2c-frontend-a [relativeHref]="getHref(category)" [content]="dropdownAllTemplate">
          </b2c-frontend-a>
        </li>
        <ng-container *ngIf="category.children && category.children.length">
          <li class="nested-li" *ngFor="let subCategory of category.children; let ii = index">
            <ng-template #nestedATemplate>
              <span class="nested-a" (click)="onSubCategoryClicked(subCategory)">
                {{ subCategory.name }}
              </span>
            </ng-template>
            <b2c-frontend-a [relativeHref]="getHref(subCategory)" [content]="nestedATemplate">
            </b2c-frontend-a>
            <div class="nested-u" *ngIf="subCategory.children && subCategory.children.length">
              <li class="nested-l" *ngFor="let child of subCategory.children">
                <ng-template #nestedBTemplate>
                  <span class="nested-b" (click)="onSubCategoryClicked(child)">
                    {{ child.name }}
                  </span>
                </ng-template>
                <b2c-frontend-a [relativeHref]="getHref(child)" [content]="nestedBTemplate">
                </b2c-frontend-a>
              </li>
            </div>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </ul>
  <div class="header-mobile-bottom">
    <ul>
      <li>
        <a routerLink="/ps" [queryParams]="{withPromoPrice: true}" routerLinkActive="active-header-li">Akcije</a>
      </li>
      <li>
        <a routerLink="/ps" [queryParams]="{showNewProducts: true}" routerLinkActive="active-header-li">Novo u ponudi</a>
      </li>
      <li>
        <a routerLink="/page/blogs" routerLinkActive="active-header-li">Blogovi</a>
      </li>
      <li>
        <a routerLink="/page/contact" routerLinkActive="active-header-li">Kontakt</a>
      </li>
      <div class="social-media" *ngIf="companyViewService.company">
          <a target="_blank" [href]="companyViewService.company.facebookUrl">
              <img src="../../assets/images/footer/facebook.svg" [alt]="companyViewService.company.facebookUrl">
          </a>
          <a class="ms-4" target="_blank" [href]="companyViewService.company.instagramUrl">
              <img src="../../assets/images/footer/instagram.svg" [alt]="companyViewService.company.instagramUrl">
          </a>
          <a class="ms-4" target="_blank" href="https://www.tiktok.com/&#64;ipsilon.rs">
              <img src="../../assets/images/footer/tiktok.svg" alt="Tiktok" />
          </a>
      </div>
    </ul>
    <div class="align-items-center col-md-3 col-xs-12 col-sm-12 first-section-footer-item" *ngIf="companyViewService.company">
      <div class="img-wrapper">
          <a href="tel:{{companyViewService.company.mobilePhoneNumber}}">
              <img src="../../assets/images/footer/phones.svg" alt="Phones" />
          </a>
      </div>
      <span class="first-section-footer-text">Pozovi naše prodajne savetnike: <br>{{companyViewService.company.mobilePhoneNumber}}</span>
    </div>
  </div>
</div>