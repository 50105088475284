import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { IPayPalConfig } from 'ngx-paypal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'b2c-frontend-paypal',
  templateUrl: './paypal.component.html',
})
export class PayPalComponent implements OnInit {
  payPalConfig?: IPayPalConfig;

  @Input() basketId?: string | null;
  @Input() payPalCurrency?: string;
  @Input() payPalClientId?: string;
  @Input() isPayPalEnabled!: boolean;

  @Output() payPalAuthCanceled = new EventEmitter<any>();
  @Output() payPalAuthError = new EventEmitter<any>();
  @Output() payPalAuthClicked = new EventEmitter<any>();
  @Output() payPalPaymentCompleted = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(@Inject('BASE_API_URL') private baseApiUrl: string) {}

  ngOnInit(): void {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      clientId: this.payPalClientId ?? '',
      currency: this.payPalCurrency,
      createOrderOnServer: (): Promise<string> =>
        fetch(`${this.baseApiUrl}/company/create-paypal-payment?basketId=${this.basketId}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((res) => res.json())
          .then((order) => order.token),
      authorizeOnServer: (approveData: any): Promise<any> => {
        return fetch(`${this.baseApiUrl}/company/execute-paypal-payment`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            payerId: approveData.payerID,
            paymentId: approveData.paymentID,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((details) => {
            this.payPalPaymentCompleted.emit(details);
          });
      },
      onCancel: (data, actions) => {
        this.payPalAuthCanceled.emit({data, actions});
      },
      onError: (err) => {
        this.payPalAuthError.emit(err);
      },
      onClick: (data, actions) => {
        this.payPalAuthClicked.emit({data, actions});
      },
    };
  }
}
