import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseClient } from '../base-client';
import { CompanyResponse, PlacesResponse } from '../http-models';
import { CompanyViewModel, PlacesViewModel } from '../view-models';

@Injectable()
export class CompanyService extends BaseClient {
    constructor(http: HttpClient, @Inject('BASE_API_URL') baseApiUrl: string) {
        super(http, baseApiUrl);
    }

    getPlaces(postCode = "", city = "", street = ""): Observable<PlacesViewModel[]> {
        const queryParams = `?postCode=${postCode}&city=${city}&street=${street}`;
        return this.get<PlacesResponse[]>(`company/get-places${queryParams}`)
                   .pipe(map(places => {
                        if (!places || !places.length) {
                            return [];
                        }

                        const placesVm: PlacesViewModel[] = [];

                        for (let i = 0; i < places.length; i++) {
                            const place = places[i];
                            placesVm.push(this.mapPlacesResponseToPlacesViewModel(place));
                        }

                        return placesVm;
                    }));
    }

    sendEmail(request: any): Observable<boolean> {
        return this.post<boolean>(`company/send-email`, request);
    }

    getCompany(): Observable<CompanyViewModel> {
        return this.get<CompanyResponse>(`company/get`)
                   .pipe(map(company => {
                        if (company) {
                            return this.mapCompanyResponseToCompanyViewModel(company);
                        }
                        return company;
                    }));
    }

    getOnlinePaymentSignature(shopId: string, shoppingCartId: string, totalAmount: number): Observable<string> {
        return this.get<string>(`company/get-signature?shopId=${shopId}&shoppingCartId=${shoppingCartId}&totalAmount=${totalAmount}`);
    }

    private mapCompanyResponseToCompanyViewModel(company: CompanyResponse): CompanyViewModel {
        const companyVm = new CompanyViewModel();
        companyVm.currentBankAccounts = company.currentBankAccounts;
        companyVm.defaultCurrency = company.defaultCurrency;
        companyVm.email = company.email;
        companyVm.enableOnlinePaymentApi = company.enableOnlinePaymentApi;
        companyVm.enablePayPalApi = company.enablePayPalApi;
        companyVm.facebookUrl = company.facebookUrl;
        companyVm.fullAddress = company.fullAddress;
        companyVm.identificationNumber = company.identificationNumber;
        companyVm.instagramUrl = company.instagramUrl;
        companyVm.logo = company.logo;
        companyVm.mobilePhoneNumber = company.mobilePhoneNumber;
        companyVm.name = company.name;
        companyVm.onlinePaymentShopId = company.onlinePaymentShopId;
        companyVm.onlinePaymentUrl = company.onlinePaymentUrl;
        companyVm.payPalClientId = company.payPalClientId;
        companyVm.payPalCurrency = company.payPalCurrency;
        companyVm.phoneNumber = company.phoneNumber;
        companyVm.taxNumber = company.taxNumber;
        companyVm.transportCostLimit = company.transportCostLimit;
        companyVm.webSiteUrl = company.webSiteUrl;
        return companyVm;
    }

    private mapPlacesResponseToPlacesViewModel(places: PlacesResponse): PlacesViewModel {
        const placesVm = new PlacesViewModel();
        placesVm.cargoAvailable = places.cargoAvailable;
        placesVm.city = places.city;
        placesVm.postalCode = places.postalCode;
        placesVm.standardAvailable = places.standardAvailable;
        placesVm.street = places.street;

        return placesVm;
    }
}
