<section class="container">
    <div class="about-wrapper">
        <div class="row">
            <div class="col-md-5">
                <h1 class="title">Hvala na poseti</h1>
                <p class="description">
                    Ako imaš pitanja, slobodno nas kontaktiraj.
                </p>
                <div class="form-right">
                    <p class="form-right-title">Pozovi nas <span class="form-number">064 853 87 60</span></p>
                    <span class="form-time">Radno vreme kontakt centra: 09:00 - 17:00</span>
                </div>
                <h3 class="form-title">Kontakt forma</h3>
                <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">

                    <div class="form-group mb-4">
                        <input class="main_input" type="email" placeholder="Email adresa" 
                            formControlName="email" maxlength="50">
                        <small style="float: left;" class="text-danger" *ngIf="emailForm.controls['email'].invalid &&
                                        (emailForm.controls['email'].dirty ||
                                        emailForm.controls['email'].touched) &&
                                        emailForm.controls['email'].errors?.['required']">
                            Email adresa je obavezna.
                        </small>
                        <small style="float: left;" class="text-danger" *ngIf="emailForm.controls['email'].invalid &&
                                        (emailForm.controls['email'].dirty ||
                                        emailForm.controls['email'].touched) &&
                                        emailForm.controls['email'].errors?.['email']">
                            Email adresa nije validna.
                        </small>
                    </div>
                    <div class="form-group mb-4">
                        <input class="main_input" type="text" placeholder="Ime"  formControlName="name"
                            maxlength="50">
                        <small style="float: left;" class="text-danger" *ngIf="emailForm.controls['name'].invalid &&
                                        (emailForm.controls['name'].dirty ||
                                        emailForm.controls['name'].touched) &&
                                        emailForm.controls['name'].errors?.['required']">
                            Ime je obavezno.
                        </small>
                    </div>
                    <div class="form-group mb-4">
                        <input class="main_input" type="text" placeholder="Tema" 
                            formControlName="subject" maxlength="150">
                        <small style="float: left;" class="text-danger" *ngIf="emailForm.controls['subject'].invalid &&
                                        (emailForm.controls['subject'].dirty ||
                                        emailForm.controls['subject'].touched) &&
                                        emailForm.controls['subject'].errors?.['required']">
                            Tema je obavezna.
                        </small>
                    </div>
                    <div class="form-group mb-4">
                        <small style="float: left;">Preostali broj karaktera: {{restLength}}</small>
                        <textarea placeholder="Tvoja poruka"  class="main_textarea" cols="30" rows="10"
                            formControlName="message" [maxlength]="messageMaxLength"
                            (keyup)="onMessageKeyUp($event)"></textarea>
                        <small style="float: left;" class="text-danger" *ngIf="emailForm.controls['message'].invalid &&
                                        (emailForm.controls['message'].dirty ||
                                        emailForm.controls['message'].touched) &&
                                        emailForm.controls['message'].errors?.['required']">
                            Poruka je obavezna.
                        </small>
                    </div>
                    <button type="submit" class="main_button">Pošalji poruku</button>
                </form>
                <p class="if-not-form">Pošalji nam email: shop&#64;ipsilon.rs</p>
            </div>
            <div class="col-md-7 padding-left-add">
                <div class="map-left">
                    <p class="form-right-title">Naša prodajna mesta</p>
                    <div class="contact-card d-flex">
                        <div class="contact-card-text">
                            <p class="town">Prijepolje, prodavnica</p>
                            <p class="address">Adresa: Veljka Vlahovića br. 8, Prijepolje Tel: 0648538773</p>
                        </div>
                    </div>
                    <div class="contact-card second-card d-flex">
                        <div class="contact-card-text">
                            <p class="town">Nova Varoš, prodavnica</p>
                            <p class="address">Adresa: Karađorđeva br. 96, Nova Varoš Tel: 0648538772</p>
                        </div>
                    </div>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92778.2265119631!2d19.55020161302208!3d43.3912493843808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4757f9112578ae01%3A0xfd6980f47fa663fc!2sZenit%20Tehnika%20-%20Prijepolje!5e0!3m2!1sen!2srs!4v1685223924112!5m2!1sen!2srs"
                    width="100%" height="480" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</section>