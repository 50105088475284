import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-ui-template-product-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BaseProductBrandComponent{

  @Input() brand?: string;
  @Input() isSelected?: boolean;
  @Output() brandChanged = new EventEmitter<string>();

  constructor(private location: Location) {}

  onBrandChanged() {
    this.brandChanged.emit(this.brand);
  }

  getHref() {
    let path = this.location.path();
    path = path.substring(0, path.indexOf('?'));
    if (path.includes('/ps')) {
      path += `/b/${this.brand}`;
      return path;
    }

    return path;
  }
}
