import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseContactComponent } from './contact/contact.component';
import { BaseBrandsComponent } from './brands/brands.component';
import { BaseStaticPageComponent } from './static-page/static-page.component';
import { BaseBlogComponent } from './blog/blog.component';
import { BaseBannerBlogComponent } from './banner-blog/banner-blog.component';
import { BaseRecommendationBannerComponent } from './recommendation-banner/recommendation-banner.component';
import { BaseEmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { BaseNotFoundComponent } from './not-found/not-found.component';
import { BaseServerErrorComponent } from './server-error/server-error.component';

const routes: Routes = [
  {
    path: 'page/contact',
    component: BaseContactComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Kontakt' }, noMeta: true, path: '/contact' },
    title: "Kontakt | Ipsilon"
  },
  {
    path: 'page/brands',
    component: BaseBrandsComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Brendovi' }, noMeta: true, path: '/brands' },
    title: "Brendovi | Ipsilon"
  },
  {
    path: 'page/static-page/:code',
    data: { breadcrumb: { label: 'Statična stranica', alias: 'staticPage' }, noMeta: true, path: '/static-page' },
    component: BaseStaticPageComponent,
    title: "Statična stranica | Ipsilon"
  },
  {
    path: 'page/blogs',
    data: { breadcrumb: { data: 'Second-Level', label: 'Blogovi' } },
    children: [
      {
        path: '',
        component: BaseBlogComponent,
        title: "Blogovi | Ipsilon",
      },
      {
        path: 'blog/:blogId',
        data: { breadcrumb: { data: 'Third-Level', label: 'Blog', alias: 'blog' } },
        component: BaseBannerBlogComponent
      }
    ]
  },
  {
    path: 'page/recommendation/:id',
    data: { breadcrumb: { data: 'Second-Level', label: 'Preporuka', alias: 'recommendation' } },
    component: BaseRecommendationBannerComponent
  },
  {
    path: 'page/unsubscribe',
    component: BaseEmailUnsubscribeComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Odjaviti se sa newlettera' }, noMeta: true, path: '/unsubscribe' },
    title: "Newsletter odjava | Ipsilon"
  },
  {
    path: 'page/server-error',
    component: BaseServerErrorComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Greška na serveru' }, noMeta: true, path: '/server-error' },
    title: "Greška na serveru | Ipsilon"
  },
  {
    path: 'page/not-found',
    component: BaseNotFoundComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Stranica nije pronađena' }, noMeta: true, path: '/not-found' },
    title: "Stranica nije pronađena | Ipsilon"
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
