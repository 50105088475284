import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseBasketComponent } from './basket.component';

const routes: Routes = [
  {
    path: 'basket',
    component: BaseBasketComponent,
    data: { breadcrumb: { data: 'Second-Level', label: 'Korpa' } },
    title: "Korpa | Ipsilon"
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class BasketRoutingModule { }
